import React, { useState, useEffect, useRef } from 'react';
// import cssStyles from './MagicFormPage.css';
// import mockFormConfig from './mockFormConfig';

const MagicFormPage = () => {
    // const [formConfig, setFormConfig] = useState(null);
    const [formData, setFormData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const iframeRef = useRef(null);

    // Initialize form configuration on component mount
    const formConfig = {
        pages: [
            {
                page: 1,
                title: 'Personal Information',
                svgUrl: '/assets/forms/i140-page1.svg',
                fields: [
                    {
                        "Input Number": 1,
                        "Style": "position: absolute; left: 817.049px; top: 687.902px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 2,
                        "Style": "position: absolute; left: 817.049px; top: 619.279px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 3,
                        "Style": "position: absolute; left: 560.405px; top: 1001.55px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 4,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 5,
                        "Style": "position: absolute; left: 265.526px; top: 413.359px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 6,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 7,
                        "Style": "position: absolute; left: 560.405px; top: 1158.4px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 8,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 9,
                        "Style": "position: absolute; left: 196.082px; top: 725.423px; width: 284.312px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 10,
                        "Style": "position: absolute; left: 137.258px; top: 1048.93px; width: 58.8235px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 11,
                        "Style": "position: absolute; left: 656.864px; top: 872.478px; width: 284.33px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 12,
                        "Style": "position: absolute; left: 313.724px; top: 833.259px; width: 166.67px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 13,
                        "Style": "position: absolute; left: 313.729px; top: 1048.93px; width: 166.665px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 14,
                        "Style": "position: absolute; left: 730.306px; top: 813.655px; width: 210.801px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 15,
                        "Style": "position: absolute; left: 560.405px; top: 1089.78px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 16,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 17,
                        "Style": "position: absolute; left: 560.405px; top: 1060.37px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 18,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 19,
                        "Style": "position: absolute; left: 196.082px; top: 686.209px; width: 284.312px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 20,
                        "Style": "position: absolute; left: 303.923px; top: 970.505px; width: 176.471px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 21,
                        "Style": "position: absolute; left: 885.623px; top: 687.902px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 22,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 23,
                        "Style": "position: absolute; left: 885.623px; top: 619.279px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input2", "svg-option-input2"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 24,
                        "Style": "position: absolute; left: 186.276px; top: 1088.14px; width: 294.118px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 25,
                        "Style": "position: absolute; left: 186.276px; top: 1127.35px; width: 294.118px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 26,
                        "Style": "position: absolute; left: 431.371px; top: 450.936px; width: 196.082px; height: 29.4042px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 27,
                        "Style": "position: absolute; left: 235.294px; top: 977.04px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 28,
                        "Style": "display: none;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 29,
                        "Style": "position: absolute; left: 196.082px; top: 1009.71px; width: 284.312px; height: 29.4091px; background: transparent;",
                        "Classes": ["svg-input"],
                        "Autocomplete": "off"
                    },
                    {
                        "Input Number": 30,
                        "Style": "position: absolute; left: 166.67px; top: 977.04px; width: 16.3399px; height: 16.3384px; background: transparent;",
                        "Classes": ["svg-input", "svg-option-input"],
                        "Autocomplete": "off"
                    }
                ]
            }]
    };

    const formConfig3 = {
        pages: [
            {
                page: 1,
                title: 'Personal Information',
                svgUrl: '/assets/forms/i140-page1.svg',
                fields: [
                    { id: 'P1_Option1', label: 'Option 1', type: 'checkbox', position: { left: '817.049px', top: '687.902px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option2', label: 'Option 2', type: 'checkbox', position: { left: '817.049px', top: '619.279px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option3', label: 'Option 3', type: 'checkbox', position: { left: '560.405px', top: '1001.55px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option4', label: 'Option 4', type: 'checkbox', position: { left: '560.405px', top: '1089.78px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option5', label: 'Option 5', type: 'checkbox', position: { left: '560.405px', top: '1060.37px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option6', label: 'Option 6', type: 'checkbox', position: { left: '885.623px', top: '687.902px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Option7', label: 'Option 7', type: 'checkbox', position: { left: '885.623px', top: '619.279px', width: '16.3399px', height: '16.3384px' }, value: '', editable: true },
                    { id: 'P1_Text1', label: 'SSN Field', type: 'text', position: { left: '196.082px', top: '725.423px', width: '284.312px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text2', label: 'Other Field', type: 'text', position: { left: '656.864px', top: '872.478px', width: '284.33px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text3', label: 'Disabled Text Field', type: 'text', position: { left: '313.724px', top: '833.259px', width: '166.67px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text4', label: 'Disabled Text Field', type: 'text', position: { left: '313.729px', top: '1048.93px', width: '166.665px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text5', label: 'Disabled Text Field', type: 'text', position: { left: '730.306px', top: '813.655px', width: '210.801px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text6', label: 'Disabled Text Field', type: 'text', position: { left: '196.082px', top: '686.209px', width: '284.312px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text7', label: 'Disabled Text Field', type: 'text', position: { left: '303.923px', top: '970.505px', width: '176.471px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text8', label: 'Input with Icon', type: 'text', position: { left: '196.082px', top: '647px', width: '284.312px', height: '29.4091px' }, editable: true, value: '' },
                    { id: 'P1_Text9', label: 'Input with Icon', type: 'text', position: { left: '196.082px', top: '931.291px', width: '284.312px', height: '29.4091px' }, editable: true, value: '' },
                    {
                        id: 'P1_Dropdown',
                        label: 'Country of Birth',
                        type: 'select',
                        options: [
                            { value: '236', label: 'United States' },
                            { value: '1', label: 'Afghanistan' },
                            { value: '2', label: 'Aland Islands' },
                            { value: '32', label: 'Brazil' },
                            // Additional countries go here
                        ],
                        position: { left: '98.0408px', top: '1186.17px', width: '382.353px', height: '29.4091px' },
                        editable: true,
                        value: '',
                    },
                ],
            },
            {
                page: 2,
                title: 'Contact Information',
                svgUrl: '/assets/forms/i140-page2.svg',
                fields: [
                    {
                        id: 'Line14b_Passport',
                        label: 'Passport Number',
                        type: 'text',
                        position: { left: '342px', top: '360px', width: '234px', height: '18px' },
                        attr_path: "applicant.computed.most_recent_entry.data.passport_number",
                        editable: false,
                        value: null,
                    },
                    {
                        id: 'prt2PetitionType',
                        label: 'Petition Type - Skilled Worker',
                        type: 'radio',
                        position: { left: '61px', top: '703px', width: '10px', height: '10px' },
                        attr_path: "form_attributes.petition_type",
                        option_value: "A skilled worker",
                        editable: true,
                        value: 'f',
                    },
                    {
                        id: 'Line6_CityTownOfBirth',
                        label: 'City of Birth',
                        type: 'text',
                        position: { left: '60px', top: '78px', width: '233px', height: '18px' },
                        attr_path: "applicant.data.city_of_birth",
                        editable: true,
                        value: null,
                    },
                ],
            },
        ]
    };

    const formConfig2 = {
        pages: [
            {
                page: 1,
                title: 'Page 1 Title',
                svgUrl: '/assets/forms/i140-page1.svg',
                fields: [
                    { id: '151829', label: 'P1_Line6_Checkbox_1', type: 'radio', position: { left: '500.034px', top: '360.966px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151825', label: 'P1_Line5_Checkbox_1', type: 'radio', position: { left: '500.034px', top: '402.967px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '152021', label: 'prt2PetitionType_1', type: 'radio', position: { left: '342.968px', top: '168.998px', width: '10.0px', height: '10.0px' }, value: 'a', editable: true },
                    { id: '152020', label: 'prt2PetitionType_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '152004', label: 'G28CheckBox_0', type: 'radio', position: { left: '162.502px', top: '529.001px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '152003', label: 'G28CheckBox_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151987', label: 'prt2PetitionType_0', type: 'radio', position: { left: '342.968px', top: '72.994px', width: '10.0px', height: '10.0px' }, value: 'e', editable: true },
                    { id: '151986', label: 'prt2PetitionType_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151982', label: 'Pt1Line1c_MiddleName_0', type: 'text', position: { left: '120.002px', top: '330.001px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151978', label: 'Line6e_State_0', type: 'text', position: { left: '84.002px', top: '131.998px', width: '36.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151943', label: '#area_1.Pt1Line8_USCISOnlineActNumber_0', type: 'text', position: { left: '402.001px', top: '239.995px', width: '174.00998px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151911', label: 'Button1_0', type: 'text', position: { left: '191.999px', top: '263.999px', width: '102.002014px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151899', label: 'Line6f_ZipCode_0', type: 'text', position: { left: '192.002px', top: '131.998px', width: '101.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151891', label: 'Line7_SSN_0', type: 'text', position: { left: '446.947px', top: '275.998px', width: '129.00998px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151890', label: 'prt2PetitionType_4', type: 'radio', position: { left: '342.968px', top: '114.995px', width: '10.0px', height: '10.0px' }, value: 'd', editable: true },
                    { id: '151889', label: 'prt2PetitionType_4', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151838', label: 'prt2PetitionType_3', type: 'radio', position: { left: '342.968px', top: '132.995px', width: '10.0px', height: '10.0px' }, value: 'c', editable: true },
                    { id: '151837', label: 'prt2PetitionType_3', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151831', label: 'Pt1Line1b_GivenName_0', type: 'text', position: { left: '120.002px', top: '354.002px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151828', label: 'P1_Line6_Checkbox_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151824', label: 'P1_Line5_Checkbox_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151820', label: 'prt2PetitionType_2', type: 'radio', position: { left: '342.968px', top: '150.998px', width: '10.0px', height: '10.0px' }, value: 'b', editable: true },
                    { id: '151819', label: 'prt2PetitionType_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151812', label: 'Line6c_AptSteFlrNumber_0', type: 'text', position: { left: '186.001px', top: '179.997px', width: '108.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151807', label: 'P1_Line6_Checkbox_0', type: 'radio', position: { left: '542.001px', top: '360.966px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151806', label: 'P1_Line6_Checkbox_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151797', label: 'P1_Line5_Checkbox_0', type: 'radio', position: { left: '542.001px', top: '402.967px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151796', label: 'P1_Line5_Checkbox_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151783', label: 'Line6h_Province_0', type: 'text', position: { left: '114.001px', top: '107.997px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151772', label: 'Line6g_PostalCode_0', type: 'text', position: { left: '114.001px', top: '83.999px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151767', label: 'attyStateBarNumber_0', type: 'text', position: { left: '263.999px', top: '498.005px', width: '120.002014px', height: '17.99701px' }, value: 'None', editable: false },
                    { id: '151764', label: 'Line6c_Unit_1', type: 'radio', position: { left: '144.0px', top: '183.997px', width: '10.0px', height: '10.0px' }, value: ' FLR ', editable: false },
                    { id: '151763', label: 'Line6c_Unit_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151762', label: 'Line6d_CityOrTown_0', type: 'text', position: { left: '120.002px', top: '155.999px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151756', label: 'Line6c_Unit_0', type: 'radio', position: { left: '102.002px', top: '183.997px', width: '10.0px', height: '10.0px' }, value: ' STE ', editable: false },
                    { id: '151755', label: 'Line6c_Unit_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151746', label: 'Pt1Line1a_FamilyName_0', type: 'text', position: { left: '120.002px', top: '378.0px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151744', label: 'Line6c_Unit_2', type: 'radio', position: { left: '60.001px', top: '183.997px', width: '10.0px', height: '10.0px' }, value: ' APT ', editable: false },
                    { id: '151743', label: 'Line6c_Unit_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151737', label: 'Line6b_StreetNumberName_0', type: 'text', position: { left: '120.002px', top: '203.998px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151734', label: 'Line6i_Country_0', type: 'country', position: { left: '60.001px', top: '47.999px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151720', label: 'Pt1Line3_TaxNumber_0', type: 'text', position: { left: '446.947px', top: '420.001px', width: '129.00998px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151719', label: 'Line6a_InCareofName_0', type: 'text', position: { left: '60.001px', top: '227.996px', width: '234.10498px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151700', label: 'attyUSCISOnlineNum_0', type: 'text', position: { left: '396.003px', top: '498.002px', width: '174.01001px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151692', label: 'Line2_CompanyName_0', type: 'text', position: { left: '60.001px', top: '293.998px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                ]
            },
            {
                page: 2,
                title: 'Page 2 Title',
                svgUrl: '/assets/forms/i140-page2.svg',
                fields: [
                    { id: '151969', label: 'Line2c_Unit_0', type: 'radio', position: { left: '101.999px', top: '303.999px', width: '10.0px', height: '10.0px' }, value: ' STE ', editable: false },
                    { id: '151951', label: 'Line14e_ExpDate_1', type: 'text', position: { left: '492.001px', top: '443.999px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151994', label: 'Line14b_Passport_0', type: 'text', position: { left: '342.0px', top: '360.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151991', label: 'prt2PetitionType_7', type: 'radio', position: { left: '61.0px', top: '703.0px', width: '10.0px', height: '10.0px' }, value: 'f', editable: true },
                    { id: '151990', label: 'prt2PetitionType_7', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151989', label: 'Line14e_ExpDate_0', type: 'date', position: { left: '492.001px', top: '252.0px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151988', label: 'Line6_CityTownOfBirth_0', type: 'text', position: { left: '60.001px', top: '78.007px', width: '233.81598px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151968', label: 'Line2c_Unit_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151961', label: 'prt2PetitionType_6', type: 'radio', position: { left: '61.0px', top: '643.002px', width: '10.0px', height: '10.0px' }, value: 'h', editable: true },
                    { id: '151960', label: 'prt2PetitionType_6', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151948', label: 'Pt3Line1a_FamilyName_0', type: 'text', position: { left: '120.002px', top: '461.999px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151947', label: 'Line2c_Unit_1', type: 'radio', position: { left: '143.997px', top: '303.999px', width: '10.0px', height: '10.0px' }, value: ' FLR ', editable: false },
                    { id: '151946', label: 'Line2c_Unit_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151934', label: 'Line2_Petition_1', type: 'radio', position: { left: '61.0px', top: '529.001px', width: '10.0px', height: '10.0px' }, value: 'S', editable: true },
                    { id: '151933', label: 'Line2_Petition_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151919', label: 'Line2c_Unit_2', type: 'radio', position: { left: '59.998px', top: '303.999px', width: '10.0px', height: '10.0px' }, value: ' APT ', editable: false },
                    { id: '151918', label: 'Line2c_Unit_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151917', label: 'Line2b_StreetNumberName_0', type: 'text', position: { left: '119.999px', top: '324.0px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151912', label: 'Line15_CurrentNon_0', type: 'text', position: { left: '342.0px', top: '396.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151910', label: 'prt2PetitionType_5', type: 'radio', position: { left: '61.0px', top: '673.001px', width: '10.0px', height: '10.0px' }, value: 'g', editable: true },
                    { id: '151909', label: 'prt2PetitionType_5', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151908', label: 'Line2f_ZipCode_0', type: 'text', position: { left: '192.002px', top: '252.0px', width: '101.99901px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151907', label: 'Line1b_Status_0', type: 'radio', position: { left: '342.999px', top: '73.003px', width: '10.0px', height: '10.0px' }, value: '1b', editable: true },
                    { id: '151906', label: 'Line1b_Status_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151872', label: 'Line9_Country_0', type: 'country', position: { left: '342.0px', top: '671.998px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151866', label: 'Line1a_Country_0', type: 'country', position: { left: '342.0px', top: '90.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151858', label: 'Pt3Line1c_MiddleName_0', type: 'text', position: { left: '120.002px', top: '414.0px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151852', label: 'Line2_Petition_0', type: 'radio', position: { left: '61.0px', top: '583.001px', width: '10.0px', height: '10.0px' }, value: 'A', editable: true },
                    { id: '151851', label: 'Line2_Petition_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151805', label: 'Line2e_State_0', type: 'text', position: { left: '84.002px', top: '252.0px', width: '36.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151778', label: 'Line5_DateOfBirth_0', type: 'date', position: { left: '209.999px', top: '114.004px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151766', label: 'Line1a_Visa_0', type: 'radio', position: { left: '342.999px', top: '175.002px', width: '10.0px', height: '10.0px' }, value: '1a', editable: true },
                    { id: '151765', label: 'Line1a_Visa_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151761', label: 'Line2a_InCareofName_0', type: 'text', position: { left: '60.001px', top: '347.998px', width: '234.10498px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151760', label: 'Line2h_Province_0', type: 'text', position: { left: '114.001px', top: '227.999px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151749', label: 'Line14_I94Number_0.Line14a_ArrivalDeparture_0', type: 'text', position: { left: '415.483px', top: '479.999px', width: '160.517px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151731', label: 'Line7_StateProvinceOfBirth_0', type: 'text', position: { left: '60.185px', top: '42.001px', width: '233.81601px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151730', label: 'Pt3Line1b_GivenName_0', type: 'text', position: { left: '119.999px', top: '437.998px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151714', label: 'Line2d_CityOrTown_0', type: 'text', position: { left: '119.999px', top: '276.001px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151713', label: 'Line8_Country_0', type: 'country', position: { left: '342.0px', top: '708.001px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151712', label: 'Line2g_PostalCode_0', type: 'text', position: { left: '114.001px', top: '204.001px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151708', label: 'Line1a_CityorTown_0', type: 'text', position: { left: '342.0px', top: '126.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151703', label: 'Line14c_TravelDoc_0', type: 'text', position: { left: '342.0px', top: '324.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151701', label: 'Line11_Alien_0.Pt3Line8_AlienNumber_0', type: 'text', position: { left: '447.103px', top: '636.001px', width: '129.00998px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151670', label: 'Line12_SSN_0', type: 'text', position: { left: '446.947px', top: '612.0px', width: '129.00998px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151666', label: 'Line2aReceipt_0.Line2a_ReceiptNumber_0', type: 'text', position: { left: '96.001px', top: '545.998px', width: '188.40201px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151660', label: 'Line14d_CountryOfIssuance_0', type: 'country', position: { left: '342.456px', top: '288.0px', width: '233.67102px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151657', label: 'Line2i_Country_0', type: 'country', position: { left: '60.001px', top: '168.001px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151649', label: 'Line13_DateOArrival_0', type: 'date', position: { left: '492.001px', top: '515.999px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151643', label: 'Line2c_AptSteFlrNumber_0', type: 'text', position: { left: '186.001px', top: '299.999px', width: '108.0px', height: '18.0px' }, value: 'None', editable: false },
                ]
            },
            {
                page: 3,
                title: 'Page 3 Title',
                svgUrl: '/assets/forms/i140-page3.svg',
                fields: [
                    { id: '151974', label: 'Line7_No_0', type: 'radio', position: { left: '542.188px', top: '517.036px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151972', label: 'Line6_Yes_0', type: 'radio', position: { left: '500.221px', top: '571.037px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151963', label: 'Line2e_Province_0', type: 'text', position: { left: '114.001px', top: '546.001px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151826', label: 'Line3i_Country_0', type: 'country', position: { left: '60.001px', top: '150.001px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151818', label: 'Line5_No_0', type: 'radio', position: { left: '542.188px', top: '601.032px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151815', label: 'Line1c_Explain_0', type: 'text', position: { left: '360.346px', top: '293.998px', width: '215.65399px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151814', label: 'Line2g_LaborCertification_0', type: 'text', position: { left: '342.187px', top: '71.997px', width: '233.81299px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152014', label: 'Line2f_Country_0', type: 'country', position: { left: '60.001px', top: '486.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152012', label: 'Line6_No_0', type: 'radio', position: { left: '542.188px', top: '571.037px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '152011', label: 'Line6_No_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151999', label: 'Line2b_DateEstablished_0', type: 'date', position: { left: '492.137px', top: '204.001px', width: '83.86301px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151983', label: 'Line2c_CityOrTown_0', type: 'text', position: { left: '119.999px', top: '570.002px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151973', label: 'Line7_No_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151971', label: 'Line6_Yes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151967', label: 'Line2a_StreetNumberName_0', type: 'text', position: { left: '119.999px', top: '618.001px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151958', label: 'Line4_Form485_0', type: 'radio', position: { left: '343.175px', top: '697.002px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151957', label: 'Line4_Form485_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151942', label: 'Line4_No_0', type: 'radio', position: { left: '260.161px', top: '66.937px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151941', label: 'Line4_No_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151937', label: 'Line2a_InCareofName_1', type: 'text', position: { left: '60.001px', top: '305.997px', width: '234.10498px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151929', label: 'Line3d_StreetNumberName_0', type: 'text', position: { left: '119.999px', top: '281.999px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151928', label: 'Line2a_TypeofBusiness_0', type: 'text', position: { left: '342.374px', top: '227.999px', width: '233.626px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151914', label: 'Line2d_PostalCode_0', type: 'text', position: { left: '114.001px', top: '522.0px', width: '180.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151905', label: 'Line4_Form131_0', type: 'radio', position: { left: '343.175px', top: '679.002px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151904', label: 'Line4_Form131_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151902', label: 'Line2b_Unit_0', type: 'radio', position: { left: '102.002px', top: '598.0px', width: '10.0px', height: '10.0px' }, value: ' STE ', editable: false },
                    { id: '151901', label: 'Line2b_Unit_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151879', label: 'Line7_Yes_0', type: 'radio', position: { left: '500.221px', top: '517.036px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151878', label: 'Line7_Yes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151875', label: 'Line3b_GivenName2_0', type: 'text', position: { left: '120.002px', top: '396.0px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151846', label: 'Line4_OtherAttach_0', type: 'radio', position: { left: '343.175px', top: '643.002px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151845', label: 'Line4_OtherAttach_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151843', label: 'Line1b_Country_0', type: 'country', position: { left: '60.001px', top: '671.998px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151835', label: 'Line2b_Unit_2', type: 'radio', position: { left: '60.001px', top: '598.0px', width: '10.0px', height: '10.0px' }, value: ' APT ', editable: false },
                    { id: '151834', label: 'Line2b_Unit_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151832', label: 'Line3e_AptSteFlrNumber_0', type: 'text', position: { left: '186.001px', top: '258.001px', width: '108.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151817', label: 'Line5_No_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151811', label: 'Line2b_Unit_1', type: 'radio', position: { left: '144.003px', top: '598.0px', width: '10.0px', height: '10.0px' }, value: ' FLR ', editable: false },
                    { id: '151810', label: 'Line2b_Unit_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151804', label: 'Line3g_PostalCode_0', type: 'text', position: { left: '114.001px', top: '186.001px', width: '180.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151799', label: 'Line2f_0.Line2f_NAICSCode_0', type: 'text', position: { left: '487.553px', top: '108.0px', width: '88.44699px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151780', label: 'Line3e_Unit_2', type: 'radio', position: { left: '60.001px', top: '262.001px', width: '10.0px', height: '10.0px' }, value: ' APT ', editable: true },
                    { id: '151779', label: 'Line3e_Unit_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151777', label: 'Line2c_NumberofEmployees_0', type: 'text', position: { left: '498.061px', top: '180.0px', width: '77.938995px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151759', label: 'Line5_Yes_0', type: 'radio', position: { left: '500.221px', top: '601.032px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151758', label: 'Line5_Yes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151745', label: 'Line3h_Province_0', type: 'text', position: { left: '114.001px', top: '209.999px', width: '180.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151739', label: 'Line3a_FamilyName2_0', type: 'text', position: { left: '120.002px', top: '420.001px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151728', label: 'Line2e_NetAnnualIncome_0', type: 'text', position: { left: '450.102px', top: '131.998px', width: '125.89801px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151726', label: 'Line1c_Other_0', type: 'radio', position: { left: '343.353px', top: '336.999px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151725', label: 'Line1c_Other_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151715', label: 'Line2d_GrossAnnualIncome_0', type: 'text', position: { left: '450.102px', top: '155.999px', width: '125.89801px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151699', label: 'Line4_Form765_0', type: 'radio', position: { left: '343.175px', top: '661.002px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151698', label: 'Line4_Form765_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151695', label: 'Line3f_CityOrTown_0', type: 'text', position: { left: '119.999px', top: '234.0px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151690', label: 'Line4_Yes_0', type: 'radio', position: { left: '218.16px', top: '66.937px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151689', label: 'Line4_Yes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151688', label: 'Line8_Yes_0', type: 'radio', position: { left: '500.224px', top: '451.037px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151687', label: 'Line8_Yes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151683', label: 'Line2b_AptSteFlrNumber_0', type: 'text', position: { left: '186.001px', top: '594.0px', width: '108.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151674', label: 'Line1a_Employer_0', type: 'radio', position: { left: '343.353px', top: '372.999px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151673', label: 'Line1a_Employer_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151672', label: 'Line1b_Self_0', type: 'radio', position: { left: '343.353px', top: '354.999px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151671', label: 'Line1b_Self_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151663', label: 'Line3e_Unit_0', type: 'radio', position: { left: '101.999px', top: '262.001px', width: '10.0px', height: '10.0px' }, value: ' STE ', editable: true },
                    { id: '151662', label: 'Line3e_Unit_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151651', label: 'Line8_No_0', type: 'radio', position: { left: '542.188px', top: '451.037px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151650', label: 'Line8_No_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151646', label: 'Line3e_Unit_1', type: 'radio', position: { left: '144.0px', top: '262.001px', width: '10.0px', height: '10.0px' }, value: ' FLR ', editable: true },
                    { id: '151645', label: 'Line3e_Unit_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151642', label: 'Line3c_MiddleName2_0', type: 'text', position: { left: '120.002px', top: '371.999px', width: '173.99901px', height: '18.0px' }, value: 'None', editable: true },
                ]
            },
            {
                page: 4,
                title: 'Page 4 Title',
                svgUrl: '/assets/forms/i140-page4.svg',
                fields: [
                    { id: '151998', label: 'Line9a_StreetNumberName_0', type: 'text', position: { left: '119.934px', top: '132.001px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151980', label: 'Line4_No1_0', type: 'radio', position: { left: '260.161px', top: '337.761px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151903', label: 'Line9b_AptSteFlrNumber_0', type: 'text', position: { left: '185.882px', top: '108.003px', width: '107.912px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151900', label: 'Line9e_ZipCode_0', type: 'text', position: { left: '191.877px', top: '60.001px', width: '101.91701px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151669', label: 'Line1b_Person1GivenName_0', type: 'text', position: { left: '402.137px', top: '552.002px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151665', label: 'Line5_Hours_0', type: 'text', position: { left: '60.001px', top: '288.003px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152019', label: 'Line9b_Unit_0', type: 'radio', position: { left: '101.948px', top: '112.003px', width: '10.0px', height: '10.0px' }, value: ' STE ', editable: true },
                    { id: '152018', label: 'Line9b_Unit_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '152010', label: 'Line1f_Relationship_0', type: 'text', position: { left: '402.137px', top: '443.999px', width: '173.863px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152009', label: 'Line1_JobTitle_0', type: 'text', position: { left: '60.001px', top: '468.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152008', label: 'Line2i_LaborCertificationDate_0', type: 'date', position: { left: '209.999px', top: '636.001px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152005', label: 'Line2_SOCCode1_0', type: 'text', position: { left: '192.002px', top: '444.002px', width: '29.999008px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152002', label: 'Pt7ItemNumber12_CheckboxYes_0', type: 'radio', position: { left: '500.159px', top: '144.932px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '152001', label: 'Pt7ItemNumber12_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151997', label: 'Pt7ItemNumber11_CheckboxNo_0', type: 'radio', position: { left: '542.126px', top: '174.934px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151996', label: 'Pt7ItemNumber11_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151984', label: 'Line3b_AnnualIncome_0', type: 'text', position: { left: '168.001px', top: '546.004px', width: '126.00299px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151979', label: 'Line4_No1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151977', label: 'Pt7ItemNumber12_CheckboxNo_0', type: 'radio', position: { left: '542.126px', top: '144.932px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151976', label: 'Pt7ItemNumber12_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151965', label: 'Line7_No1_0', type: 'radio', position: { left: '260.161px', top: '252.936px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151964', label: 'Line7_No1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151953', label: 'Line4_Yes1_0', type: 'radio', position: { left: '218.16px', top: '337.761px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151952', label: 'Line4_Yes1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151949', label: 'Line8_Wages_0', type: 'text', position: { left: '72.0px', top: '216.003px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151944', label: 'Line2h_LaborCertification_0', type: 'date', position: { left: '209.999px', top: '672.001px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151931', label: 'Line1e_CountryOfBirth_0', type: 'country', position: { left: '342.187px', top: '468.0px', width: '233.81299px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151930', label: 'Line2c_Person2MiddleName_0', type: 'text', position: { left: '402.137px', top: '288.0px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151923', label: 'Line7_Yes1_0', type: 'radio', position: { left: '218.16px', top: '252.936px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151922', label: 'Line7_Yes1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151916', label: 'Pt7ItemNumber6_CheckboxYes_0', type: 'radio', position: { left: '500.159px', top: '384.933px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151915', label: 'Pt7ItemNumber6_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151913', label: 'Line2d_DateOfBirth_0', type: 'date', position: { left: '492.066px', top: '263.999px', width: '83.931px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151898', label: 'Pt7ItemNumber5_CheckboxNo_0', type: 'radio', position: { left: '542.188px', top: '414.929px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151897', label: 'Pt7ItemNumber5_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151895', label: 'Line1a_Person1FamilyName_0', type: 'text', position: { left: '402.137px', top: '576.0px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151894', label: 'Line6_No1_0', type: 'radio', position: { left: '260.277px', top: '270.933px', width: '10.0px', height: '10.0px' }, value: 'N', editable: true },
                    { id: '151893', label: 'Line6_No1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151887', label: 'Line3_JobDescription_0.3', type: 'multiline', position: { left: '60.2184px', top: '353.216px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: false },
                    { id: '151885', label: 'Line3_JobDescription_0.2', type: 'multiline', position: { left: '60.2184px', top: '370.853px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: false },
                    { id: '151883', label: 'Line2_SOCCode2_0', type: 'text', position: { left: '234.0px', top: '444.002px', width: '59.997986px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151862', label: 'Line3_JobDescription_0.1', type: 'multiline', position: { left: '60.2184px', top: '388.489px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: false },
                    { id: '151860', label: 'Line3_JobDescription_0.0', type: 'multiline', position: { left: '60.2184px', top: '407.126px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: false },
                    { id: '151855', label: 'Line2f_Relationship_0', type: 'text', position: { left: '402.137px', top: '204.001px', width: '173.863px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151854', label: 'Pt7ItemNumber5_CheckboxYes_0', type: 'radio', position: { left: '500.221px', top: '414.929px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151853', label: 'Pt7ItemNumber5_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151850', label: 'Pt7ItemNumber6_CheckboxNo_0', type: 'radio', position: { left: '542.126px', top: '384.933px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151849', label: 'Pt7ItemNumber6_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151757', label: 'Line2b_Person2GivenName_0', type: 'text', position: { left: '402.137px', top: '312.001px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151753', label: 'Line6_Yes1_0', type: 'radio', position: { left: '218.276px', top: '270.933px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: true },
                    { id: '151752', label: 'Line6_Yes1_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151738', label: 'Line1c_Person1MiddleName_0', type: 'text', position: { left: '402.137px', top: '527.998px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151709', label: 'Line2a_Person2FamilyName_0', type: 'text', position: { left: '402.137px', top: '335.999px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151702', label: 'Line9c_CityOrTown_0', type: 'text', position: { left: '119.934px', top: '84.002px', width: '173.86002px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151697', label: 'Pt7ItemNumber11_CheckboxYes_0', type: 'radio', position: { left: '500.159px', top: '174.934px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151696', label: 'Pt7ItemNumber11_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151686', label: 'Line2e_CountryOfBirth_0', type: 'country', position: { left: '342.187px', top: '227.999px', width: '233.81299px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151682', label: 'Line9d_State_0', type: 'text', position: { left: '85.962px', top: '60.001px', width: '33.972px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151679', label: 'Line9b_Unit_2', type: 'radio', position: { left: '59.981px', top: '112.003px', width: '10.000004px', height: '10.0px' }, value: ' APT ', editable: true },
                    { id: '151678', label: 'Line9b_Unit_2', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151664', label: 'Line8_Per_0', type: 'text', position: { left: '174.002px', top: '216.003px', width: '119.99901px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151661', label: 'Line1d_Person1DateOfBirth_0', type: 'date', position: { left: '492.069px', top: '504.0px', width: '83.931px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151659', label: 'Line3a_Occupation_0', type: 'text', position: { left: '60.001px', top: '570.002px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151655', label: 'Line9b_Unit_1', type: 'radio', position: { left: '143.912px', top: '112.003px', width: '10.0px', height: '10.0px' }, value: ' FLR ', editable: true },
                    { id: '151654', label: 'Line9b_Unit_1', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                ]
            },
            {
                page: 5,
                title: 'Page 5 Title',
                svgUrl: '/assets/forms/i140-page5.svg',
                fields: [
                    { id: '151975', label: 'Line2f_Relationship_2', type: 'text', position: { left: '402.276px', top: '569.999px', width: '173.724px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151966', label: 'Line2e_CountryOfBirth_4', type: 'country', position: { left: '59.981px', top: '300.002px', width: '233.813px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151775', label: 'Line2d_DateOfBirth_4', type: 'date', position: { left: '492.134px', top: '389.999px', width: '83.86301px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151771', label: 'Line2b_Person2GivenName_3', type: 'text', position: { left: '402.276px', top: '678.002px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151770', label: 'Line2a_Person2FamilyName_2', type: 'text', position: { left: '119.999px', top: '407.999px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151751', label: 'Pt7ItemNumber17_CheckboxYes_0', type: 'radio', position: { left: '218.16px', top: '487.034px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151733', label: 'Line2d_DateOfBirth_2', type: 'date', position: { left: '209.999px', top: '335.999px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151723', label: 'Line2d_DateOfBirth_1', type: 'date', position: { left: '209.999px', top: '576.0px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151706', label: 'Pt7ItemNumber36_CheckboxNo_0', type: 'radio', position: { left: '542.217px', top: '270.933px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151685', label: 'Pt7ItemNumber30_CheckboxYes_0', type: 'radio', position: { left: '500.284px', top: '510.927px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151680', label: 'Line2b_Person2GivenName_4', type: 'text', position: { left: '402.276px', top: '438.001px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151652', label: 'Line2c_Person2MiddleName_4', type: 'text', position: { left: '402.276px', top: '414.0px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151641', label: 'Line2e_CountryOfBirth_2', type: 'country', position: { left: '342.374px', top: '594.0px', width: '233.626px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152013', label: 'Line2c_Person2MiddleName_1', type: 'text', position: { left: '119.999px', top: '600.001px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152006', label: 'Line2f_Relationship_1', type: 'text', position: { left: '119.999px', top: '515.999px', width: '174.00201px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151995', label: 'Line2e_CountryOfBirth_3', type: 'country', position: { left: '342.374px', top: '353.999px', width: '233.626px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151993', label: 'Pt7ItemNumber23_CheckboxYes_0', type: 'radio', position: { left: '218.015px', top: '247.036px', width: '10.0px', height: '10.000015px' }, value: 'Y', editable: false },
                    { id: '151992', label: 'Pt7ItemNumber23_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151981', label: 'Line2c_Person2MiddleName_2', type: 'text', position: { left: '119.999px', top: '360.0px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151950', label: 'Line2f_Relationship_3', type: 'text', position: { left: '402.276px', top: '329.998px', width: '173.724px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151927', label: 'Pt7ItemNumber24_CheckboxNo_0', type: 'radio', position: { left: '259.982px', top: '217.038px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151926', label: 'Pt7ItemNumber24_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151925', label: 'Line2f_Relationship_4', type: 'text', position: { left: '119.931px', top: '276.001px', width: '173.863px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151921', label: 'Pt7ItemNumber29_CheckboxNo_0', type: 'radio', position: { left: '542.217px', top: '540.932px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151920', label: 'Pt7ItemNumber29_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151870', label: 'Pt7ItemNumber35_CheckboxNo_0', type: 'radio', position: { left: '542.217px', top: '300.937px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151869', label: 'Pt7ItemNumber35_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151833', label: 'Line2a_Person2FamilyName_3', type: 'text', position: { left: '402.276px', top: '702.0px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151813', label: 'Line2a_Person2FamilyName_4', type: 'text', position: { left: '402.276px', top: '461.999px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151791', label: 'Pt7ItemNumber36_CheckboxYes_0', type: 'radio', position: { left: '500.284px', top: '270.933px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151790', label: 'Pt7ItemNumber36_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151789', label: 'Line2b_Person2GivenName_2', type: 'text', position: { left: '119.999px', top: '384.001px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151788', label: 'Line2a_Person2FamilyName_1', type: 'text', position: { left: '119.999px', top: '648.0px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151774', label: 'Pt7ItemNumber30_CheckboxNo_0', type: 'radio', position: { left: '542.217px', top: '510.927px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151773', label: 'Pt7ItemNumber30_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151769', label: 'Pt7ItemNumber18_CheckboxNo_0', type: 'radio', position: { left: '260.161px', top: '456.974px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151768', label: 'Pt7ItemNumber18_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151754', label: 'Line2d_DateOfBirth_3', type: 'date', position: { left: '492.134px', top: '630.0px', width: '83.86301px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151750', label: 'Pt7ItemNumber17_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151742', label: 'Pt7ItemNumber17_CheckboxNo_0', type: 'radio', position: { left: '260.161px', top: '487.034px', width: '10.0px', height: '10.0px' }, value: 'N', editable: false },
                    { id: '151741', label: 'Pt7ItemNumber17_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151732', label: 'Line2b_Person2GivenName_1', type: 'text', position: { left: '119.999px', top: '624.002px', width: '173.999px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151718', label: 'Pt7ItemNumber24_CheckboxYes_0', type: 'radio', position: { left: '218.015px', top: '217.038px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151717', label: 'Pt7ItemNumber24_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151711', label: 'Pt7ItemNumber35_CheckboxYes_0', type: 'radio', position: { left: '500.284px', top: '300.937px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151710', label: 'Pt7ItemNumber35_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151705', label: 'Pt7ItemNumber36_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151694', label: 'Pt7ItemNumber29_CheckboxYes_0', type: 'radio', position: { left: '500.284px', top: '540.932px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151693', label: 'Pt7ItemNumber29_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151684', label: 'Pt7ItemNumber30_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151677', label: 'Pt7ItemNumber23_CheckboxNo_0', type: 'radio', position: { left: '259.982px', top: '247.036px', width: '10.0px', height: '10.000015px' }, value: 'N', editable: false },
                    { id: '151676', label: 'Pt7ItemNumber23_CheckboxNo_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                    { id: '151675', label: 'Line2c_Person2MiddleName_3', type: 'text', position: { left: '402.276px', top: '654.001px', width: '173.72101px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151656', label: 'Line2e_CountryOfBirth_1', type: 'country', position: { left: '60.001px', top: '540.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151648', label: 'Pt7ItemNumber18_CheckboxYes_0', type: 'radio', position: { left: '218.16px', top: '456.974px', width: '10.0px', height: '10.0px' }, value: 'Y', editable: false },
                    { id: '151647', label: 'Pt7ItemNumber18_CheckboxYes_0', type: 'checkbox', position: { left: '0.0px', top: '0.0px', width: '0.0px', height: '0.0px' }, value: 'None', editable: false },
                ]
            },
            {
                page: 6,
                title: 'Page 6 Title',
                svgUrl: '/assets/forms/i140-page6.svg',
                fields: [
                    { id: '152017', label: 'Part8_Item6_Email_0', type: 'text', position: { left: '342.0px', top: '432.003px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152016', label: 'Part6_Line6a_InterpretersSignature_0', type: 'text', position: { left: '342.298px', top: '252.0px', width: '233.99997px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '152007', label: 'Part7_Item7_Email_0', type: 'text', position: { left: '60.001px', top: '371.999px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151747', label: 'Part7_Item3b_GivenName_0', type: 'text', position: { left: '60.001px', top: '551.999px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151735', label: 'Part8_Item1b_GivenName_0', type: 'text', position: { left: '342.0px', top: '606.3px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151727', label: 'Part7_Item3a_FamilyName_0', type: 'text', position: { left: '60.004px', top: '600.001px', width: '233.99701px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151667', label: 'Part7_Item6_MobilePhone_0', type: 'text', position: { left: '60.001px', top: '419.998px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151924', label: 'Part7_Item5_DayPhone_0', type: 'text', position: { left: '60.001px', top: '468.003px', width: '234.0px', height: '17.99701px' }, value: 'None', editable: false },
                    { id: '151830', label: 'Part7_Item4_Title_0', type: 'text', position: { left: '60.001px', top: '516.005px', width: '234.0px', height: '17.99701px' }, value: 'None', editable: false },
                    { id: '151808', label: 'Part8_Item1a_FamilyName_0', type: 'text', position: { left: '342.003px', top: '642.3px', width: '233.99701px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151801', label: 'Part8_Item2_OrgName_0', type: 'text', position: { left: '342.0px', top: '570.305px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151787', label: 'Part8_Item7b_Date_0', type: 'text', position: { left: '492.299px', top: '227.999px', width: '83.99896px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151785', label: 'Part7_Item8b_Date_0', type: 'text', position: { left: '209.999px', top: '71.7px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151736', label: 'Part8_Item4_DayPhone_0', type: 'text', position: { left: '342.0px', top: '504.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151716', label: 'Line1a_SignatureofPetitioner_0', type: 'text', position: { left: '60.001px', top: '95.698px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151658', label: 'Part8_Item6_Language_0', type: 'text', position: { left: '335.999px', top: '360.003px', width: '234.298px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151644', label: 'Part8_Item5_MobilePhone_0', type: 'text', position: { left: '342.0px', top: '468.0px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                ]
            }, {
                page: 7,
                title: 'Page 7 Title',
                svgUrl: '/assets/forms/i140-page7.svg',
                fields: [
                    { id: '151786', label: 'Part10_Item6_SignatureofPetitioner_0', type: 'text', position: { left: '60.001px', top: '215.697px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151781', label: 'Part10_Item5_Email_0', type: 'text', position: { left: '60.001px', top: '395.7px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151784', label: 'Part10_Item4_MobilePhone_0', type: 'text', position: { left: '60.001px', top: '431.697px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151740', label: 'Part10_Item2_OrgName_0', type: 'text', position: { left: '60.001px', top: '534.002px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151802', label: 'Part10_Item1_FamilyName_0', type: 'text', position: { left: '60.004px', top: '605.996px', width: '233.99701px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151955', label: 'Part10_Item1_GivenName_0', type: 'text', position: { left: '60.001px', top: '569.996px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                    { id: '151856', label: 'Part10_Item6__Date_0', type: 'text', position: { left: '209.999px', top: '191.699px', width: '83.99899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151704', label: 'Part10_Item3_DayPhone_0', type: 'text', position: { left: '60.001px', top: '467.697px', width: '234.0px', height: '18.0px' }, value: 'None', editable: false },
                ]
            },
            {
                page: 8,
                title: 'Page 8 Title',
                svgUrl: '/assets/forms/i140-page8.svg',
                fields: [
                    { id: '151939', label: 'Pt9Line6d_AdditionalInfo_1.9', type: 'multiline', position: { left: '342.329px', top: '66.0512px', width: '234.32797px', height: '18.3274px' }, value: 'None', editable: true },
                    { id: '151940', label: 'Pt9Line6d_AdditionalInfo_1.8', type: 'multiline', position: { left: '342.329px', top: '83.6877px', width: '234.32797px', height: '18.327301px' }, value: 'None', editable: true },
                    { id: '151936', label: 'Pt9Line6d_AdditionalInfo_1.7', type: 'multiline', position: { left: '342.329px', top: '102.324px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151938', label: 'Pt9Line6d_AdditionalInfo_1.6', type: 'multiline', position: { left: '342.329px', top: '119.961px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151932', label: 'Pt9Line6d_AdditionalInfo_1.5', type: 'multiline', position: { left: '342.329px', top: '137.943px', width: '234.32797px', height: '18.981003px' }, value: 'None', editable: true },
                    { id: '151959', label: 'Pt9Line6d_AdditionalInfo_1.3', type: 'multiline', position: { left: '342.329px', top: '173.87px', width: '234.32797px', height: '18.327011px' }, value: 'None', editable: true },
                    { id: '151962', label: 'Pt9Line6d_AdditionalInfo_1.2', type: 'multiline', position: { left: '342.329px', top: '192.506px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151798', label: 'Pt9Line6d_AdditionalInfo_0.8', type: 'multiline', position: { left: '342.329px', top: '311.471px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151795', label: 'Pt9Line6d_AdditionalInfo_0.5', type: 'multiline', position: { left: '342.329px', top: '366.38px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151792', label: 'Pt9Line6d_AdditionalInfo_0.4', type: 'multiline', position: { left: '342.329px', top: '384.016px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151821', label: 'Pt9Line6d_AdditionalInfo_0.2', type: 'multiline', position: { left: '342.329px', top: '420.289px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151822', label: 'Pt9Line6d_AdditionalInfo_0.1', type: 'multiline', position: { left: '342.329px', top: '437.935px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151816', label: 'Pt9Line6d_AdditionalInfo_0.0', type: 'multiline', position: { left: '342.329px', top: '455.562px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151691', label: 'Pt9Line6c_ItemNumber_1', type: 'text', position: { left: '497.999px', top: '479.999px', width: '72.00003px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151653', label: 'Pt9Line6b_PartNumber_1', type: 'text', position: { left: '420.001px', top: '479.999px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151721', label: 'Pt9Line6a_PageNumber_1', type: 'text', position: { left: '342.221px', top: '479.999px', width: '71.77899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151836', label: 'Pt9Line5d_AdditionalInfo_0.5', type: 'multiline', position: { left: '342.329px', top: '593.435px', width: '234.32797px', height: '18.981995px' }, value: 'None', editable: true },
                    { id: '151839', label: 'Pt9Line5d_AdditionalInfo_0.4', type: 'multiline', position: { left: '342.329px', top: '612.726px', width: '234.32797px', height: '17.672974px' }, value: 'None', editable: true },
                    { id: '151840', label: 'Pt9Line5d_AdditionalInfo_0.3', type: 'multiline', position: { left: '342.329px', top: '630.054px', width: '234.32797px', height: '18.326965px' }, value: 'None', editable: true },
                    { id: '151823', label: 'Pt9Line5d_AdditionalInfo_0.1', type: 'multiline', position: { left: '342.329px', top: '665.363px', width: '234.32797px', height: '18.981995px' }, value: 'None', editable: true },
                    { id: '151827', label: 'Pt9Line5d_AdditionalInfo_0.0', type: 'multiline', position: { left: '342.329px', top: '684.654px', width: '234.32797px', height: '17.673035px' }, value: 'None', editable: true },
                    { id: '151724', label: 'Pt9Line5b_PartNumber_0', type: 'text', position: { left: '420.001px', top: '708.001px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151896', label: 'Pt9Line4d_AdditionalInfo_0.9', type: 'multiline', position: { left: '60.2184px', top: '66.0512px', width: '233.6736px', height: '18.3274px' }, value: 'None', editable: true },
                    { id: '151880', label: 'Pt9Line4d_AdditionalInfo_0.8', type: 'multiline', position: { left: '60.2184px', top: '83.6877px', width: '233.6736px', height: '18.327301px' }, value: 'None', editable: true },
                    { id: '151881', label: 'Pt9Line4d_AdditionalInfo_0.7', type: 'multiline', position: { left: '60.2184px', top: '101.324px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151876', label: 'Pt9Line4d_AdditionalInfo_0.6', type: 'multiline', position: { left: '60.2184px', top: '119.961px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151877', label: 'Pt9Line4d_AdditionalInfo_0.5', type: 'multiline', position: { left: '60.2184px', top: '137.943px', width: '233.6736px', height: '18.981003px' }, value: 'None', editable: true },
                    { id: '151882', label: 'Pt9Line4d_AdditionalInfo_0.2', type: 'multiline', position: { left: '60.2184px', top: '191.506px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151729', label: 'Pt9Line4c_ItemNumber_0', type: 'text', position: { left: '216.0px', top: '251.997px', width: '72.0px', height: '18.000015px' }, value: 'None', editable: true },
                    { id: '151857', label: 'Pt9Line3d_AdditionalInfo_0.9', type: 'multiline', position: { left: '60.2184px', top: '294.834px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151859', label: 'Pt9Line3d_AdditionalInfo_0.8', type: 'multiline', position: { left: '60.2184px', top: '312.471px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151861', label: 'Pt9Line3d_AdditionalInfo_0.7', type: 'multiline', position: { left: '60.2184px', top: '330.107px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151863', label: 'Pt9Line3d_AdditionalInfo_0.6', type: 'multiline', position: { left: '60.2184px', top: '348.089px', width: '233.6736px', height: '18.982025px' }, value: 'None', editable: true },
                    { id: '151868', label: 'Pt9Line3d_AdditionalInfo_0.3', type: 'multiline', position: { left: '60.2184px', top: '401.653px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151871', label: 'Pt9Line3d_AdditionalInfo_0.2', type: 'multiline', position: { left: '60.2184px', top: '419.289px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151985', label: 'Pt9Line3c_ItemNumber_0', type: 'text', position: { left: '216.0px', top: '479.999px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151945', label: '#area_6.Pt1Line3_TaxNumber_1', type: 'text', position: { left: '164.974px', top: '522.0px', width: '129.01001px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151970', label: 'Pt1Line1a_FamilyName_1', type: 'text', position: { left: '120.22px', top: '594.0px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '152015', label: 'Pt9Line4a_PageNumber_0', type: 'text', position: { left: '60.001px', top: '251.997px', width: '72.00001px', height: '18.000015px' }, value: 'None', editable: true },
                    { id: '152000', label: 'Pt9Line3b_PartNumber_0', type: 'text', position: { left: '137.999px', top: '479.999px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151956', label: 'Pt9Line6d_AdditionalInfo_1.0', type: 'multiline', position: { left: '342.329px', top: '227.779px', width: '234.32797px', height: '18.327988px' }, value: 'None', editable: true },
                    { id: '151954', label: 'Pt9Line6d_AdditionalInfo_1.1', type: 'multiline', position: { left: '342.329px', top: '209.498px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151935', label: 'Pt9Line6d_AdditionalInfo_1.4', type: 'multiline', position: { left: '342.329px', top: '156.233px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151892', label: 'Pt9Line4b_PartNumber_0', type: 'text', position: { left: '137.999px', top: '252.0px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151888', label: 'Pt9Line4d_AdditionalInfo_0.3', type: 'multiline', position: { left: '60.2184px', top: '173.87px', width: '233.6736px', height: '18.327011px' }, value: 'None', editable: true },
                    { id: '151886', label: 'Pt9Line4d_AdditionalInfo_0.4', type: 'multiline', position: { left: '60.2184px', top: '156.233px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151884', label: 'Pt9Line4d_AdditionalInfo_0.1', type: 'multiline', position: { left: '60.2184px', top: '209.143px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151874', label: 'Pt9Line3d_AdditionalInfo_0.0', type: 'multiline', position: { left: '60.2184px', top: '455.562px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151873', label: 'Pt9Line3d_AdditionalInfo_0.1', type: 'multiline', position: { left: '60.2184px', top: '436.926px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151867', label: 'Pt9Line3d_AdditionalInfo_0.4', type: 'multiline', position: { left: '60.2184px', top: '384.016px', width: '233.6736px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151865', label: 'Pt9Line4d_AdditionalInfo_0.0', type: 'multiline', position: { left: '60.2184px', top: '227.779px', width: '233.6736px', height: '18.327988px' }, value: 'None', editable: true },
                    { id: '151864', label: 'Pt9Line3d_AdditionalInfo_0.5', type: 'multiline', position: { left: '60.2184px', top: '366.38px', width: '233.6736px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151848', label: 'Pt9Line5d_AdditionalInfo_0.6', type: 'multiline', position: { left: '342.329px', top: '576.763px', width: '234.32797px', height: '17.672974px' }, value: 'None', editable: true },
                    { id: '151847', label: 'Pt9Line5d_AdditionalInfo_0.7', type: 'multiline', position: { left: '342.329px', top: '558.126px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151844', label: 'Pt9Line5d_AdditionalInfo_0.8', type: 'multiline', position: { left: '342.329px', top: '540.799px', width: '234.32797px', height: '17.672974px' }, value: 'None', editable: true },
                    { id: '151842', label: 'Pt9Line5d_AdditionalInfo_0.9', type: 'multiline', position: { left: '342.329px', top: '522.162px', width: '234.32797px', height: '18.328003px' }, value: 'None', editable: true },
                    { id: '151841', label: 'Pt9Line5d_AdditionalInfo_0.2', type: 'multiline', position: { left: '342.329px', top: '647.69px', width: '234.32797px', height: '17.672974px' }, value: 'None', editable: true },
                    { id: '151809', label: 'Pt1Line1b_GivenName_1', type: 'text', position: { left: '119.999px', top: '569.999px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151803', label: 'Pt9Line6d_AdditionalInfo_0.9', type: 'multiline', position: { left: '342.329px', top: '293.834px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151800', label: 'Pt9Line6d_AdditionalInfo_0.7', type: 'multiline', position: { left: '342.329px', top: '330.107px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151794', label: 'Pt9Line6d_AdditionalInfo_0.6', type: 'multiline', position: { left: '342.329px', top: '348.089px', width: '234.32797px', height: '18.982025px' }, value: 'None', editable: true },
                    { id: '151793', label: 'Pt9Line6d_AdditionalInfo_0.3', type: 'multiline', position: { left: '342.329px', top: '401.653px', width: '234.32797px', height: '18.326996px' }, value: 'None', editable: true },
                    { id: '151782', label: 'Pt1Line1c_MiddleName_1', type: 'text', position: { left: '120.22px', top: '546.001px', width: '173.999px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151776', label: 'Pt9Line6a_PageNumber_0', type: 'text', position: { left: '342.221px', top: '252.0px', width: '71.77899px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151748', label: 'Pt9Line5c_ItemNumber_0', type: 'text', position: { left: '497.999px', top: '707.998px', width: '72.00003px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151722', label: 'Pt9Line3a_PageNumber_0', type: 'text', position: { left: '60.001px', top: '479.999px', width: '72.00001px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151707', label: 'Pt9Line5a_PageNumber_0', type: 'text', position: { left: '342.0px', top: '708.001px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151681', label: 'Pt9Line6b_PartNumber_0', type: 'text', position: { left: '420.001px', top: '252.0px', width: '72.0px', height: '18.0px' }, value: 'None', editable: true },
                    { id: '151668', label: 'Pt9Line6c_ItemNumber_0', type: 'text', position: { left: '497.999px', top: '252.0px', width: '72.00003px', height: '18.0px' }, value: 'None', editable: true },
                ]
            }
        ]
    };


    const handleInputChange = (pageId, fieldId, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [pageId]: { ...prevData[pageId], [fieldId]: value },
        }));
    };

    const saveForm = () => {
        console.log('Form data:', formData);
        alert('Form saved!');
    };

    const goToNextPage = () => setCurrentPage((prevPage) => Math.min(prevPage + 1, formConfig.pages.length));
    const goToPreviousPage = () => setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));

    const renderFormInIframe = () => {
        const iframeDocument = iframeRef.current.contentDocument;
        if (iframeDocument) {
            // Inject the CSS styles into the iframe's head
            const styleElement = iframeDocument.createElement('style');
            styleElement.textContent = `@font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJRgVThLs8Y7ETJzDCYFCSLE.woff2) format("woff2");
                    unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJZiMaisvaUVUsYyVzOmndek.woff2) format("woff2");
                    unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJbBAWGjcah5Ky0jbCgIwDB8.woff2) format("woff2");
                    unicode-range:U+1F00-1FFF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJf14vlcfyPYlAcQy2UfDRm4.woff2) format("woff2");
                    unicode-range:U+0370-03FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJafJul7RR1X4poJgi27uS4w.woff2) format("woff2");
                    unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJavyPXdneeGd26m9EmFSSWg.woff2) format("woff2");
                    unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:300;
                    src:local("Roboto Slab Light"),local("RobotoSlab-Light"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJUo2lTMeWA_kmIyWrkNCwPc.woff2) format("woff2");
                    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37ZjTOQ_MqJVwkKsUn0wKzc2I.woff2) format("woff2");
                    unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37ZjUj_cnvWIuuBMVgbX098Mw.woff2) format("woff2");
                    unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37ZkbcKLIaa1LC45dFaAfauRA.woff2) format("woff2");
                    unicode-range:U+1F00-1FFF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37Zmo_sUJ8uO4YLWRInS22T3Y.woff2) format("woff2");
                    unicode-range:U+0370-03FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37Zr6up8jxqWt8HVA3mDhkV_0.woff2) format("woff2");
                    unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37ZiYE0-AqJ3nfInTTiDXDjU4.woff2) format("woff2");
                    unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:400;
                    src:local("Roboto Slab Regular"),local("RobotoSlab-Regular"),url(https://fonts.gstatic.com/s/robotoslab/v6/y7lebkjgREBJK96VQi37Zo4P5ICox8Kq3LLUNMylGO4.woff2) format("woff2");
                    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJZ6iIh_FvlUHQwED9Yt5Kbw.woff2) format("woff2");
                    unicode-range:U+0460-052F, U+20B4, U+2DE0-2DFF, U+A640-A69F
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJS_vZmeiCMnoWNN9rHBYaTc.woff2) format("woff2");
                    unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJSFaMxiho_5XQnyRZzQsrZs.woff2) format("woff2");
                    unicode-range:U+1F00-1FFF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJQalQocB-__pDVGhF3uS2Ks.woff2) format("woff2");
                    unicode-range:U+0370-03FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJWhQUTDJGru-0vvUpABgH8I.woff2) format("woff2");
                    unicode-range:U+0102-0103, U+1EA0-1EF9, U+20AB
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJejkDdvhIIFj_YMdgqpnSB0.woff2) format("woff2");
                    unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF
                }
                @font-face{
                    font-family:'Roboto Slab';
                    font-style:normal;
                    font-weight:700;
                    src:local("Roboto Slab Bold"),local("RobotoSlab-Bold"),url(https://fonts.gstatic.com/s/robotoslab/v6/dazS1PrQQuCxC3iOAJFEJYlIZu-HDpmDIZMigmsroc4.woff2) format("woff2");
                    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000
                }
                @font-face{
                    font-family:'Lato';
                    font-style:normal;
                    font-weight:400;
                    src:local("Lato Regular"),local("Lato-Regular"),url(https://fonts.gstatic.com/s/lato/v11/UyBMtLsHKBKXelqf4x7VRQ.woff2) format("woff2");
                    unicode-range:U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF
                }
                @font-face{
                    font-family:'Lato';
                    font-style:normal;
                    font-weight:400;
                    src:local("Lato Regular"),local("Lato-Regular"),url(https://fonts.gstatic.com/s/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2) format("woff2");
                    unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000
                }
                .toast-title{
                    font-weight:700
                }
                .toast-message{
                    -ms-word-wrap:break-word;
                    word-wrap:break-word
                }
                .toast-message a,.toast-message label{
                    color:#FFF
                }
                .toast-message a:hover{
                    color:#CCC;
                    text-decoration:none
                }
                .toast-close-button{
                    position:relative;
                    right:-.3em;
                    top:-.3em;
                    float:right;
                    font-size:20px;
                    font-weight:700;
                    color:#FFF;
                    -webkit-text-shadow:0 1px 0 #fff;
                    text-shadow:0 1px 0 #fff;
                    opacity:.8;
                    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
                    filter:alpha(opacity=80);
                    line-height:1
                }
                .toast-close-button:focus,.toast-close-button:hover{
                    color:#000;
                    text-decoration:none;
                    cursor:pointer;
                    opacity:.4;
                    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
                    filter:alpha(opacity=40)
                }
                .rtl .toast-close-button{
                    left:-.3em;
                    float:left;
                    right:.3em
                }
                button.toast-close-button{
                    padding:0;
                    cursor:pointer;
                    background:0 0;
                    border:0;
                    -webkit-appearance:none
                }
                .toast-top-center{
                    top:0;
                    right:0;
                    width:100%
                }
                .toast-bottom-center{
                    bottom:0;
                    right:0;
                    width:100%
                }
                .toast-top-full-width{
                    top:0;
                    right:0;
                    width:100%
                }
                .toast-bottom-full-width{
                    bottom:0;
                    right:0;
                    width:100%
                }
                .toast-top-left{
                    top:12px;
                    left:12px
                }
                .toast-top-right{
                    top:12px;
                    right:12px
                }
                .toast-bottom-right{
                    right:12px;
                    bottom:12px
                }
                .toast-bottom-left{
                    bottom:12px;
                    left:12px
                }
                #toast-container{
                    position:fixed;
                    z-index:999999;
                    pointer-events:none
                }
                #toast-container *{
                    box-sizing:border-box
                }
                #toast-container>div{
                    position:relative;
                    pointer-events:auto;
                    overflow:hidden;
                    margin:0 0 6px;
                    padding:15px 15px 15px 50px;
                    width:300px;
                    border-radius:3px;
                    background-position:15px center;
                    background-repeat:no-repeat;
                    box-shadow:0 0 12px #999;
                    color:#FFF;
                    opacity:.8;
                    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
                    filter:alpha(opacity=80)
                }
                #toast-container>div.rtl{
                    direction:rtl;
                    padding:15px 50px 15px 15px;
                    background-position:right 15px center
                }
                #toast-container>div:hover{
                    box-shadow:0 0 12px #000;
                    opacity:1;
                    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
                    filter:alpha(opacity=100);
                    cursor:pointer
                }
                #toast-container>.toast-info{
                    background-image:url(data:image/png;
                    base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important
                }
                #toast-container>.toast-error{
                    background-image:url(data:image/png;
                    base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important
                }
                #toast-container>.toast-success{
                    background-image:url(data:image/png;
                    base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important
                }
                #toast-container>.toast-warning{
                    background-image:url(data:image/png;
                    base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important
                }
                #toast-container.toast-bottom-center>div,#toast-container.toast-top-center>div{
                    width:300px;
                    margin-left:auto;
                    margin-right:auto
                }
                #toast-container.toast-bottom-full-width>div,#toast-container.toast-top-full-width>div{
                    width:96%;
                    margin-left:auto;
                    margin-right:auto
                }
                .toast{
                    background-color:#030303
                }
                .toast-success{
                    background-color:#51A351
                }
                .toast-error{
                    background-color:#BD362F
                }
                .toast-info{
                    background-color:#2F96B4
                }
                .toast-warning{
                    background-color:#F89406
                }
                .toast-progress{
                    position:absolute;
                    left:0;
                    bottom:0;
                    height:4px;
                    background-color:#000;
                    opacity:.4;
                    -ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
                    filter:alpha(opacity=40)
                }
                @media all and (max-width: 240px){
                    #toast-container>div{
                        padding:8px 8px 8px 50px;
                        width:11em
                    }
                    #toast-container>div.rtl{
                        padding:8px 50px 8px 8px
                    }
                    #toast-container .toast-close-button{
                        right:-.2em;
                        top:-.2em
                    }
                    #toast-container .rtl .toast-close-button{
                        left:-.2em;
                        right:.2em
                    }
                }
                @media all and (min-width: 241px) and (max-width: 480px){
                    #toast-container>div{
                        padding:8px 8px 8px 50px;
                        width:18em
                    }
                    #toast-container>div.rtl{
                        padding:8px 50px 8px 8px
                    }
                    #toast-container .toast-close-button{
                        right:-.2em;
                        top:-.2em
                    }
                    #toast-container .rtl .toast-close-button{
                        left:-.2em;
                        right:.2em
                    }
                }
                @media all and (min-width: 481px) and (max-width: 768px){
                    #toast-container>div{
                        padding:15px 15px 15px 50px;
                        width:25em
                    }
                    #toast-container>div.rtl{
                        padding:15px 50px 15px 15px
                    }
                }
                #wrapper{
                    position:relative;
                    width:100%;
                    padding:0px;
                    font-family:'Source Sans Pro', sans-serif
                }
                #template-menu{
                    background-color:black;
                    color:white;
                    left:0px;
                    margin:0px;
                    opacity:0.8;
                    padding:10px;
                    position:fixed;
                    text-align:center;
                    top:0px;
                    width:100%;
                    z-index:9999
                }
                .mt-10{
                    margin-top:10px
                }
                .mt-30{
                    margin-top:30px
                }
                .template-menu-action{
                    border:solid 2px white;
                    border-radius:5px;
                    cursor:pointer;
                    margin:5px 10px 5px 10px;
                    padding:5px
                }
                button.template-menu-action{
                    background-color:black;
                    color:white
                }
                .form-svg{
                    width:1000px
                }
                .page-container{
                    position:relative;
                    width:1000px;
                    height:1294px;
                    box-shadow:0 0 10px rgba(0,0,0,0.5);
                    margin:0 auto;
                    margin-bottom:40px
                }
                #table-of-contents>.edit_table_of_content>.page-container{
                    width:100% !important
                }
                .editable-input{
                    cursor:pointer
                }
                .svg-input{
                    border:none;
                    background:transparent;
                    font-size:15px;
                    color:black;
                    width:100%;
                    height:100%
                }
                .svg-option-input{
                    color:transparent;
                    cursor:pointer;
                    font-weight:bold;
                    text-align:center;
                    text-shadow:0 0 0 black
                }
                .svg-option-input:focus{
                    outline:none
                }
                .svg-input-with-icon i{
                    font-size:18px !important;
                    position:absolute !important;
                    right:0px !important;
                    top:-4px !important;
                    padding:9px 8px !important;
                    color:#65D3C2 !important;
                    transition:.3s !important
                }
                .option-input{
                    text-align:center;
                    cursor:pointer;
                    color:transparent;
                    text-shadow:0 0 0 black
                }
                .edit-popover{
                    margin:20px;
                    background-color:#fff;
                    border:1px solid rgba(0,0,0,0.2);
                    border-radius:6px;
                    box-shadow:0 5px 10px rgba(0,0,0,0.2);
                    box-sizing:border-box;
                    width:350px;
                    background-color:'white';
                    z-index:1;
                    overflow:auto
                }
                .edit-popover-header{
                    padding:8px 14px;
                    margin:0;
                    font-size:18px;
                    background-color:#f7f7f7;
                    border-bottom:1px solid #ebebeb;
                    border-radius:5px 5px 0 0;
                    font-family:'Roboto Slab'
                }
                .edit-popover-header h3{
                    display:inline
                }
                .edit-popover-header span{
                    float:right;
                    cursor:pointer
                }
                .edit-popover-body{
                    padding:5px
                }
                .edit-popover-input-container{
                    margin-top:10px
                }
                .edit-popover-input{
                    display:block;
                    width:92%;
                    height:22px;
                    padding:6px 12px;
                    font-size:14px;
                    font-family:'Source Sans Pro', sans-serif
                }
                input.edit-popover-input[type="checkbox"]{
                    height:13px;
                    width:13px
                }
                select.edit-popover-input{
                    height:38px;
                    width:100%
                }
                .edit-popover-advanced{
                    cursor:pointer
                }
                .admin-back-button{
                    float:left;
                    position:relative;
                    z-index:9999
                }
                .move-cursor{
                    cursor:move
                }
                .addendum-title{
                    font-size:18px !important
                }
                `
            iframeDocument.head.appendChild(styleElement);

            // Set up the HTML structure in the iframe's body
            // style="position: absolute; left: ${field.position.left}; top: ${field.position.top}; width: ${field.position.width}; height: ${field.position.height}; background: ${field.editable ? 'rgb(236, 255, 252)' : 'transparent'};" 

            iframeDocument.body.innerHTML = `
                <div id="svg-form-init">
                    <div id="wrapper" style="margin-top: 100px;">
                        <div id="template-menu">
                            <a class="template-menu-action" onclick="window.goToPreviousPage()"><<</a>
                            <a class="template-menu-action" onclick="window.goToPreviousPage()"><</a>
                            <label>${currentPage}</label>
                            <label>/</label>
                            <label>${formConfig.pages.length}</label>
                            <a class="template-menu-action" onclick="window.goToNextPage()">></a>
                            <a class="template-menu-action" onclick="window.goToNextPage()">>></a>
                            <button class="template-menu-action" onclick="window.saveForm()">Print</button>
                        </div>
                        <div class="page-container svg-page-1">
                            <img src="${formConfig.pages[currentPage - 1].svgUrl}" type="image/svg+xml" width="1000" class="form-svg">
                            ${formConfig.pages[currentPage - 1].fields.map(field => `
                                <div>
                                    <input class="svg-input svg-option-input" autocomplete="off" 
                                        style="${field.Style} background: #94f1ff" 
                                        type="text" 
                                    />
                                </div>`).join('')}
                        </div>
                    </div>
                </div>
            `;

            // Expose functions to iframe window for navigation
            iframeRef.current.contentWindow.goToNextPage = goToNextPage;
            iframeRef.current.contentWindow.goToPreviousPage = goToPreviousPage;
            iframeRef.current.contentWindow.saveForm = saveForm;
        }
    };

    useEffect(() => {
        renderFormInIframe();
    }, [currentPage, formConfig]);

    // Expose the input change handler to the iframe
    window.handleInputChange = handleInputChange;

    return (
        <iframe
            ref={iframeRef}
            title="MagicFormIframe"
            style={{ width: '100%', height: '100vh', border: 'none' }}
        />
    );
};

export default MagicFormPage;