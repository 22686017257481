import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
    // authDomain: "eagle-dd2b6.firebaseapp.com",
    // authDomain: "ebgenius.app",
    apiKey: "AIzaSyDRSeWSF9Q9cuFdPqwQkBuPfGsxmoaZYRc",
    authDomain: "my.visageni.us",
    projectId: "eagle-dd2b6",
    storageBucket: "eagle-dd2b6.appspot.com",
    messagingSenderId: "1027553324419",
    appId: "1:1027553324419:web:663796ba371239a1545ab6",
    measurementId: "G-MCRTJ9L9Z1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);  // Get the Auth service for the default app
const analytics = getAnalytics(app);  // Optionally: Get Firebase Analytics

// Initialize Google Auth Provider
const provider = new GoogleAuthProvider();
// provider.addScope('https://www.googleapis.com/auth/drive.file');

// Exports
export { auth, app, analytics, provider };
