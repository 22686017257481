// DocumentsManager.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { formatDate, formatFileSize } from '../../utils/Utils.js';
import { fetchAllFiles } from '../../services/ApiService';  // This is the function that combines files from Exhibits, Forms, and Requests

const DocumentsManager = ({ petition, type }) => {
    const [petitionId, setPetitionId] = useState(petition.id);
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadFiles = async () => {
            setLoading(true);
            try {
                const fetchedFiles = await fetchAllFiles(petitionId, type);
                setFiles(fetchedFiles);
            } catch (error) {
                console.error('Failed to load files:', error);
            } finally {
                setLoading(false);
            }
        };

        loadFiles();
    }, [petitionId, type]);

    return (
        <>
            <Loading isLoading={loading} />

            {/* <!--begin::Documents Card--> */}
            <div className="card card-flush h-xl-100">
                {/* <!--begin::Card header--> */}
                <div className="card-header pt-7">
                    {/* <!--begin::Title--> */}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fs-3 fw-bold text-primary">Documents</span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">Access and manage all petition files, including exhibits, forms, and requests, in one place</span>
                    </h3>
                    {/* <!--end::Title--> */}
                </div>
                {/* <!--end::Card header--> */}

                {/* <!--begin::Card body--> */}
                <div className="card-body">
                    {files.length > 0 ? (
                        <table className="table table-row-dashed align-middle gs-0 gy-6 my-0">
                            <thead>
                                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                                    <th className="min-w-150px">File Name</th>
                                    <th className="text-start pe-3">Type</th>
                                    <th className="text-end min-w-100px">Size</th>
                                    <th className="text-end d-none d-lg-table-cell min-w-150px">Uploaded Date</th>
                                </tr>
                            </thead>
                            <tbody className="fw-bold text-gray-600">
                                {files
                                    .sort((a, b) => {
                                        // Ensure both a.createdAt and b.createdAt are Firestore Timestamps, and exist
                                        const aTime = a.createdAt?.toMillis ? a.createdAt.toMillis() : 0;
                                        const bTime = b.createdAt?.toMillis ? b.createdAt.toMillis() : 0;

                                        // Sort by timestamp, showing the newest files on top (bTime > aTime for newest first)
                                        return bTime - aTime;
                                    })
                                    .map((file, index) => (
                                        <tr key={index}>
                                            <td>
                                                <a
                                                    className={`d-flex align-items-center overlay ${file.isHidden ? 'text-danger' : ''}`}
                                                    href={file.isDocs
                                                        ? `https://docs.google.com/document/d/${file.googleDocId}/edit`
                                                        : `https://drive.google.com/file/d/${file.googleDocId}/view`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    data-bs-toggle="tooltip"
                                                    title={`${file.isHidden ? 'Hidden files will not be included in the print version.' : ''}`}
                                                >
                                                    {/* Thumbnail image */}
                                                    <div className="symbol symbol-35px me-3">
                                                        {file.thumbnailLink ? (
                                                            // Display the thumbnail if available
                                                            <img src={file.thumbnailLink} alt="File Thumbnail" />
                                                        ) : file.isDocs ? (
                                                            // Display Google Docs icon if the file is a Google Docs file
                                                            <img src="/assets/images/GoogleDriveLogo_old.png" alt="Google Docs Icon" />
                                                        ) : (
                                                            // Fallback to the generic file icon
                                                            <i className="ki-outline ki-file fs-1 text-primary ms-2"></i>
                                                        )}
                                                    </div>

                                                    {/* File name */}
                                                    <span className="fw-bold fs-6">{file.fileName || file.id}</span>
                                                </a>
                                            </td>

                                            <td className="text-start">
                                                <span className={`badge py-3 px-4 fs-7 ${file.type === 'Exhibit' ? 'badge-light-primary' : file.type === 'Form' ? 'badge-light-success' : 'badge-light-danger'}`}>
                                                    {file.type}
                                                </span>
                                            </td>
                                            <td className="text-end">
                                                {formatFileSize(file.size)}
                                            </td>
                                            <td className="text-end d-none d-lg-table-cell">
                                                {file.createdAt ? formatDate(file.createdAt, true) : "-"}
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    ) : (
                        <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                            <img height="300px" src="/assets/media/illustrations/21.png" alt="No files found"></img>
                        </div>
                    )}
                </div>
                {/* <!--end::Card body--> */}
            </div>
            {/* <!--end::Documents Card--> */}
        </>
    );
};

export default DocumentsManager;
