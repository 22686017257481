import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap';
import Loading from '../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { isPetitionOwner } from '../../utils/Utils.js'
import { fetchPetitions, fetchPetitionsSharedWithMe, deletePetition, addPetition, setUserProfileType } from '../../services/ApiService';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';


function Home() {
    const navigate = useNavigate();
    const { currentUser, setCurrentUser, logout } = useAuth();

    const [loading, setLoading] = useState(false);
    const [petitions, setPetitions] = useState([]);
    const [petitionsSharedWithMe, setPetitionsSharedWithMe] = useState([]);
    const [loadingPetitions, setLoadingPetitions] = useState(false);
    const [loadingPetitionsSharedWithMe, setLoadingPetitionsSharedWithMe] = useState(false);
    const [error, setError] = useState(null);
    const [selectedOption, setSelectedOption] = useState('AD');
    const [activeStep, setActiveStep] = useState(1);
    const totalSteps = 5;
    // Hook que demonstra se a modal está aberta ou não
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalNewIsOpen, setNewIsOpen] = React.useState(false);
    const [modalProfileIsOpen, setProfileIsOpen] = React.useState(false);
    const [profileType, setProfileType] = useState('Beginner');

    const [formState, setFormState] = useState({
        visaType: '',
        petitionerName: '',
        // profileType: 'Beginner',
        educationalLevel: 'NoBachelors',
        has5YearsExperience: false,   // For Advanced Degree (5+ years)
        subCategory: '',

        has10YearsExperience: false,  // For Exceptional Ability (10+ years)
        hasAcademicRecord: false,
        hasLicense: false,
        hasHighSalary: false,
        hasMembership: false,
        hasRecognition: false
    });

    useEffect(() => {
        if (!currentUser) {
            navigate('/login');
            return;
        }
        else {
            // Check if BaseFolderId is present, then proceed to check profileType
            if (currentUser.BaseFolderId && !currentUser.ProfileType) {
                openModalProfile(); // Open the modal if profileType is missing
            }
        }

        const fetchAndSetPetitions = async () => {
            setLoadingPetitions(true);
            try {
                // console.log(currentUser);
                const petitionsData = await fetchPetitions();
                setPetitions(petitionsData);

            } catch (error) {
                setError(error.message);
                console.error("Failed to fetch petitions:", error);
            } finally {
                setLoadingPetitions(false);
            }
        };

        const fetchAndSetPetitionsSharedWithMe = async () => {
            setLoadingPetitionsSharedWithMe(true);
            try {
                // console.log(currentUser);
                const petitionsData = await fetchPetitionsSharedWithMe();
                setPetitionsSharedWithMe(petitionsData);

            } catch (error) {
                setError(error.message);
                console.error("Failed to fetch petitions:", error);
            } finally {
                setLoadingPetitionsSharedWithMe(false);
            }
        };

        fetchAndSetPetitions();
        fetchAndSetPetitionsSharedWithMe();

    }, [currentUser, navigate]);

    const handleNextStep = () => {
        // Get the current form inputs based on the active step
        const currentFormInputs = document.querySelectorAll(`.step-${activeStep} .form-control, .step-${activeStep} .form-select`);

        let isValid = true;
        currentFormInputs.forEach(input => {
            if (!input.checkValidity()) {
                isValid = false;
                input.classList.add('is-invalid'); // Optional: add styling or indication of error
            } else {
                input.classList.remove('is-invalid'); // Optional: clear any previous error indication
            }
        });

        if (!isValid) {
            Swal.fire("Warning", 'Please fill out all required fields.', "warning");
            return;
        }

        // New logic for redirecting based on user's input
        if (activeStep === 1 && formState.visaType !== "EB2NIW") {
            setActiveStep(5);
        }
        else if (activeStep === 2) {
            if (formState.educationalLevel === 'Masters' ||
                (formState.educationalLevel === 'Bachelors' && formState.has5YearsExperience)) {
                // If user has a Master's/PhD or Bachelor's with 5+ years of experience, go directly to step 3
                setFormState(prevState => ({
                    ...prevState,
                    subCategory: 'AD'
                }));

                setActiveStep(4);
            }
            else {
                setFormState({
                    ...formState,
                    hasAcademicRecord: true,
                    subCategory: 'EA'
                });

                setActiveStep(3);
            }
        }
        else if (activeStep === 3) {
            // Count the number of criteria met for Exceptional Ability
            const criteriaMet = [
                formState.hasAcademicRecord,
                formState.has10YearsExperience,
                formState.hasLicense,
                formState.hasHighSalary,
                formState.hasMembership,
                formState.hasRecognition
            ].filter(Boolean).length;

            // If the user has fewer than 3 criteria, show the Swal warning and prevent proceeding
            if (criteriaMet < 3) {
                Swal.fire("Sorry", 'You must meet at least 3 of the Exceptional Ability criteria to be eligible for EB-2 NIW.', "error");
                return;  // Stop progression
            } else {
                // Proceed to the next step
                setActiveStep(4);
            }
        }
        else {
            // Continue with the next step if no special condition is met
            if (activeStep < totalSteps) {
                setActiveStep(activeStep + 1);
            }
        }
    };

    // Define grouped options for visa types
    const visaOptions = [
        {
            label: "Employment Based",
            options: [
                { value: "EB1", label: "EB-1: Employment-Based, First Preference" },
                { value: "EB2", label: "EB-2: Employment-Based, Second Preference" },
                { value: "EB2NIW", label: "EB-2 NIW: National Interest Waiver" },
                { value: "EB3", label: "EB-3: Employment-Based, Third Preference" },
                { value: "EB4", label: "EB-4: Special Immigrants" },
                { value: "EB5", label: "EB-5: Investor Visa" },
            ]
        },
        {
            label: "Temporary Work Visas",
            options: [
                { value: "H1B", label: "H-1B: Specialty Occupations" },
                { value: "H2A", label: "H-2A: Agricultural Workers" },
                { value: "H2B", label: "H-2B: Non-Agricultural Workers" },
                { value: "L1", label: "L-1: Intracompany Transferee" },
                { value: "O1", label: "O-1: Individuals with Extraordinary Ability or Achievement" },
                { value: "P1", label: "P-1: Internationally Recognized Athletes or Entertainers" },
                { value: "P2", label: "P-2: Artist or Entertainer under Reciprocal Exchange Program" },
                { value: "TN", label: "TN: NAFTA Professionals" },
            ]
        },
        {
            label: "Family-Based and Other Visas",
            options: [
                { value: "K1", label: "K-1: Fiancé(e) of U.S. Citizen" },
                { value: "K3", label: "K-3: Spouse of U.S. Citizen Awaiting Approval of I-130" },
                { value: "CR1", label: "CR-1: Spouse of U.S. Citizen" },
                { value: "F1", label: "F-1: Academic Student" },
                { value: "J1", label: "J-1: Exchange Visitor" },
            ]
        },
        {
            label: "Specialized or Investor Visas",
            options: [
                { value: "E2", label: "E-2: Treaty Investor" },
                { value: "E1", label: "E-1: Treaty Trader" },
                { value: "R1", label: "R-1: Religious Worker" },
                { value: "Q1", label: "Q-1: Cultural Exchange Program" },
            ]
        }
    ];


    const customStyles = {
        control: (provided) => ({
            ...provided,
            // display: 'block',
            // width: '100%',
            // // minHeight: 'calc(1.5em + 1.65rem + 2px)',  // Match Bootstrap's large form control height
            padding: '0.825rem 1rem',  // Match padding from form-control-lg
            // fontSize: '1.15rem',  // Match font size
            // fontWeight: 500,
            // // lineHeight: '1.5',
            color: 'var(--bs-gray-700)',
            backgroundColor: 'var(--bs-gray-100)',
            border: '0px',
            borderRadius: '1.25rem',  // Match border radius from form-control-lg
            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
            boxShadow: 'none',
            appearance: 'none',  // Remove default browser styling
            backgroundImage: `url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3e%3cpath fill="none" stroke="%2378829D" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m2 5 6 6 6-6"/%3e%3c/svg%3e')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 1rem center',
            backgroundSize: '16px 12px',
        }),
        // option: (provided, state) => ({
        //     ...provided,
        //     fontSize: '1.1rem',
        //     fontWeight: 500,
        //     color: state.isSelected ? '#fff' : 'var(--bs-gray-700)',
        //     backgroundColor: state.isSelected ? '#007bff' : '#fff',
        //     '&:hover': {
        //         backgroundColor: state.isSelected ? '#0056b3' : '#f8f9fa',
        //     },
        // }),
        singleValue: (provided) => ({
            ...provided,
            color: 'var(--bs-gray-700)',
            // color: 'var(--bs-gray-700)',
        }),
        // menu: (provided) => ({
        //     ...provided,
        //     borderRadius: '0.92rem',
        //     boxShadow: '0 0.25rem 0.75rem rgba(0, 0, 0, 0.1)',  // Bootstrap-like shadow
        // }),
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none',  // Hide the default dropdown indicator, since we use a custom background image
        }),
    };

    // const handlePrevStep = () => {
    //     if (activeStep > 1) {
    //         setActiveStep((prev) => prev - 1);
    //     }
    // };
    const handlePrevStep = () => {
        // If user is on step 3 and they have a Master's/PhD or Bachelor's with 5+ years of experience
        if (activeStep === 4 && (formState.educationalLevel === 'Masters' ||
            (formState.educationalLevel === 'Bachelors' && formState.has5YearsExperience))) {
            // Skip the 2nd tab and go directly to the 1st tab
            setActiveStep(2);
        } else if (activeStep === 5 && formState.visaType !== "EB2NIW") {
            setActiveStep(1);
        }
        else if (activeStep > 1) {
            // Default behavior for going back one step
            setActiveStep((prev) => prev - 1);
        }
    };

    // const isStepActive = (stepNumber) => {

    //     if (activeStep === stepNumber) return "current";
    //     if (activeStep <= stepNumber) return "pending";
    //     if (activeStep >= stepNumber) return "completed";

    // };

    const isStepActive = (stepNumber) => {
        if (formState.educationalLevel === 'Masters' ||
            (formState.educationalLevel === 'Bachelors' && formState.has5YearsExperience)) {
            // If the user has a Master's/PhD or a Bachelor's with 5+ years of experience, disable step 2
            if (stepNumber === 3) return "disabled";
        }

        if (activeStep === stepNumber) return "current";
        if (activeStep <= stepNumber) return "pending";
        if (activeStep >= stepNumber) return "completed";
    };


    // Função que abre a modal
    function openModal() {
        setIsOpen(true);
    }

    // Função que fecha a modal
    function closeModal() {
        setIsOpen(false);
    }

    // Função que abre a modal
    function openCreatePetitionNewModal() {
        setNewIsOpen(true);
    }

    // Função que fecha a modal
    function closeCreatePetitionNewModal() {
        setNewIsOpen(false);
    }

    // Função que abre a modal
    function openModalProfile() {
        setProfileIsOpen(true);
    }

    // Função que fecha a modal
    function closeModalProfile() {
        setProfileIsOpen(false);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // const handleOptionChange = (event) => {
    //     const value = event.target.value;
    //     // console.log(value);
    //     setSelectedOption(value);
    //     // Update formState as well when the option changes
    //     setFormState(prevState => ({
    //         ...prevState,
    //         subCategory: value
    //     }));
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            var docId = '';
            console.log(formState);

            await addPetition(formState)
                .then((result) => {
                    docId = result.docId;

                    navigate(`/petition/${docId}?showTour=1`);
                })
                .catch((error) => {
                    setLoading(false);
                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", 'An error occurred while creating petition. Please try again.', "error");
                    }
                });

        } catch (error) {
            setLoading(false);
            console.error(error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", 'An error occurred while creating petition. Please try again.', "error");
            }
        }
    };

    const deletePetitionConfirmation = async (petitionId) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deletePetitionCall(petitionId);
            }
        });
    }

    const deletePetitionCall = async (petitionId) => {
        setLoadingPetitions(true);

        try {
            await deletePetition(petitionId);

            setPetitions(petitions.filter(petition => petition.id !== petitionId));
        } catch (error) {
            console.error("Error deleting petition:", error);
            Swal.fire("Error", 'Failed to delete petition. Please try again.', "error");
        } finally {
            setLoadingPetitions(false);
        }
    };

    // if (loading) return (
    //     <div className="d-flex justify-content-center mt-4">
    //         <div className="spinner-border text-primary" role="status">
    //             <span className="sr-only">Loading...</span>
    //         </div>

    //     </div>
    // )

    const handleSubmitProfile = async (event) => {
        event.preventDefault();

        try {
            await setUserProfileType(profileType)
                .then(() => {
                    // Update the currentUser with the new profileType
                    currentUser.ProfileType = profileType;
                    setCurrentUser({ ...currentUser }); // Trigger a re-render and avoid asking for profile update again.

                    // Optionally, close the profile modal
                    closeModalProfile();

                    Swal.fire("Success", "Your profile has been successfully updated! You're all set to continue.", "success");
                })
                .catch((error) => {
                    console.error('handleSubmitProfile - Inside catch block', error);
                });
        } catch (error) {
            console.error('handleSubmitProfile - Error block', error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", 'An error occurred while updating profile. Please try again.', "error");
            }
        }
    };

    if (error) return <div>Error loading petitions: {error}</div>;

    return (
        <>
            <Loading isLoading={loading} />

            {/* Create petition NEW modal */}
            <Modal show={modalNewIsOpen} onHide={closeCreatePetitionNewModal} className="modal-xl">
                <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Petition</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*  {/* <!--begin::Modal body--> */}
                        <div className="scroll-y">
                            {/* <!--begin::Stepper--> */}
                            <div className="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                {/* <!--begin::Nav--> */}
                                <div className="stepper-nav py-5">
                                    {/* <!--begin::Step 1--> */}
                                    <div className={`stepper-item ${isStepActive(1)}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Petitioner's Details</h3>
                                    </div>
                                    {/* <!--end::Step 1--> */}
                                    {/* Conditionally render tabs for EB-2 NIW only */}
                                    {formState.visaType === "EB2NIW" && (
                                        <>

                                            {/* <!--begin::Step 2--> */}
                                            <div className={`stepper-item ${isStepActive(2)}`} data-kt-stepper-element="nav">
                                                <h3 className="stepper-title">EB-2 NIW Elegibility</h3>
                                            </div>
                                            {/* <!--end::Step 2--> */}
                                            {/* <!--begin::Step 3--> */}
                                            {/* Step 2: Exceptional Ability */}
                                            <div className={`stepper-item ${isStepActive(3)}`} data-kt-stepper-element="nav">
                                                <h3 className={`stepper-title ${formState.educationalLevel === 'Masters' || (formState.educationalLevel === 'Bachelors' && formState.has5YearsExperience) ? 'disabled' : ''}`}>
                                                    Exceptional Ability
                                                </h3>
                                            </div>
                                            {/* <!--end::Step 3--> */}
                                            {/* <!--begin::Step 4--> */}
                                            <div className={`stepper-item ${isStepActive(4)}`} data-kt-stepper-element="nav">
                                                <h3 className="stepper-title">Proposed Endeavor</h3>
                                            </div>
                                            {/* <!--end::Step 4--> */}
                                        </>
                                    )}
                                    {/* <!--begin::Step 4--> */}
                                    <div className={`stepper-item ${isStepActive(5)}`} data-kt-stepper-element="nav">
                                        <h3 className="stepper-title">Summary</h3>
                                    </div>
                                    {/* <!--end::Step 4--> */}
                                </div>
                                {/* <!--end::Nav--> */}
                                {/* <!--begin::Form--> */}
                                {/* <form className="mx-auto mw-600px w-100 py-10" noValidate="novalidate" id="kt_create_account_form"> */}
                                {/* <!--begin::Step 1--> */}
                                <div className={`${isStepActive(1)} step-1`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="mx-auto mw-600px w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">Visa Type</label>
                                            {/* <!--end::Label--> */}
                                            {/* <!--begin::Input--> */}
                                            <Select
                                                name="visaType"
                                                value={visaOptions
                                                    .flatMap(group => group.options)
                                                    .find(option => option.value === formState.visaType)}
                                                onChange={(selectedOption) => handleInputChange({ target: { name: 'visaType', value: selectedOption.value } })}
                                                options={visaOptions}
                                                placeholder="Select visa..."
                                                classNamePrefix="react-select"
                                                styles={customStyles}  // Apply custom styles here

                                            />
                                            {/* <!--end::Input--> */}
                                        </div>
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3 required">Petitioner's name</label>
                                            {/* <!--end::Label--> */}
                                            {/* <!--begin::Input--> */}
                                            <input type="text" className="form-control form-control-lg form-control-solid" name="petitionerName" value={formState.petitionerName} onChange={handleInputChange} required />

                                            {/* <input type="text" className="form-control form-control-lg form-control-solid" name="account_name" placeholder="" value="" /> */}
                                            {/* <!--end::Input--> */}
                                        </div>
                                        {/* <!--end::Input group--> */}
                                        <div className="row mb-10">
                                            {/* <!--begin::Col--> */}
                                            <div className="col-md-4 fv-row">
                                                {/* <!--begin::Label--> */}
                                                <label className="required fs-6 fw-semibold form-label mb-2">Age</label>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Row--> */}
                                                <div className="row fv-row">
                                                    {/* <!--begin::Col--> */}
                                                    <div className="col-12">
                                                        <select
                                                            name="age"
                                                            className="form-select form-select-lg form-select-solid"
                                                            value={formState.age}
                                                            onChange={handleInputChange}
                                                            required
                                                        >
                                                            <option value="">Select age...</option>
                                                            {Array.from({ length: 69 - 18 + 1 }, (_, index) => (
                                                                <option key={index} value={18 + index}>{18 + index}</option>
                                                            ))}
                                                            <option value="70">70+</option>
                                                        </select>
                                                    </div>
                                                    {/* <!--end::Col--> */}
                                                </div>
                                                {/* <!--end::Row--> */}
                                            </div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div className="col-md-4 fv-row">
                                                {/* <!--begin::Label--> */}
                                                <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                                    <span className="required">Gender</span>
                                                </label>
                                                {/* <!--end::Label--> */}
                                                {/* <!--begin::Input wrapper--> */}
                                                <div className="position-relative">
                                                    {/* <!--begin::Input--> */}
                                                    <select
                                                        name="gender"
                                                        className="form-select form-select-lg form-select-solid"
                                                        value={formState.gender}
                                                        onChange={handleInputChange}
                                                        required
                                                    >
                                                        <option value="">Select gender...</option>
                                                        <option value="Man">Man</option>
                                                        <option value="Woman">Woman</option>
                                                    </select>
                                                    {/* <!--end::Input--> */}
                                                </div>
                                                {/* <!--end::Input wrapper--> */}
                                            </div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div className="col-md-4 fv-row">
                                                <label className="form-label mb-3 required">Employment Status</label>
                                                <select
                                                    name="employmentStatus"
                                                    className="form-select form-select-lg form-select-solid"
                                                    value={formState.employmentStatus}
                                                    onChange={handleInputChange}
                                                    required
                                                >
                                                    <option value="">Select...</option>
                                                    <option value="Employed">Employed</option>
                                                    <option value="SelfEmployed">Self-employed</option>
                                                    <option value="Unemployed">Unemployed</option>
                                                </select>
                                            </div>
                                            {/* <!--end::Col--> */}
                                        </div>
                                        {/* <!--end::Input group--> */}

                                        <div className="row mb-10">
                                            {/* <!--begin::Input group--> */}
                                            {/* <!--begin::Col--> */}
                                            <div className="col-md-6 fv-row">

                                                <label className="d-flex align-items-center form-label mb-5 required">Place of Birth
                                                </label>
                                                <select
                                                    name="placeOfBirth"
                                                    className="form-select form-select-lg form-select-solid"
                                                    value={formState.placeOfBirth}
                                                    onChange={handleInputChange}
                                                    data-control="select2"
                                                    data-placeholder="Select..."
                                                    data-allow-clear="true"
                                                    data-hide-search="true"
                                                    required
                                                >
                                                    <option value="">Select country...</option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="CzechRepublic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="NewZealand">New Zealand</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="SaudiArabia">Saudi Arabia</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="SouthAfrica">South Africa</option>
                                                    <option value="SouthKorea">South Korea</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="SriLanka">Sri Lanka</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="UnitedArabEmirates">United Arab Emirates</option>
                                                    <option value="UnitedKingdom">United Kingdom</option>
                                                    <option value="UnitedStates">United States</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </div>
                                            {/* <!--begin::Col--> */}
                                            {/* <!--begin::Input group--> */}
                                            <div className="col-md-6 fv-row">
                                                <label className="d-flex align-items-center form-label mb-5 required">Country of Residence
                                                </label>
                                                <select
                                                    name="countryOfResidence"
                                                    className="form-select form-select-lg form-select-solid"
                                                    value={formState.countryOfResidence}
                                                    onChange={handleInputChange}
                                                    data-control="select2"
                                                    data-placeholder="Select..."
                                                    data-allow-clear="true"
                                                    data-hide-search="true"
                                                    required
                                                >
                                                    <option value="">Select country...</option>
                                                    <option value="Afghanistan">Afghanistan</option>
                                                    <option value="Albania">Albania</option>
                                                    <option value="Algeria">Algeria</option>
                                                    <option value="Angola">Angola</option>
                                                    <option value="Argentina">Argentina</option>
                                                    <option value="Australia">Australia</option>
                                                    <option value="Austria">Austria</option>
                                                    <option value="Bangladesh">Bangladesh</option>
                                                    <option value="Belarus">Belarus</option>
                                                    <option value="Belgium">Belgium</option>
                                                    <option value="Brazil">Brazil</option>
                                                    <option value="Bulgaria">Bulgaria</option>
                                                    <option value="Canada">Canada</option>
                                                    <option value="Chile">Chile</option>
                                                    <option value="China">China</option>
                                                    <option value="Colombia">Colombia</option>
                                                    <option value="Croatia">Croatia</option>
                                                    <option value="Cuba">Cuba</option>
                                                    <option value="CzechRepublic">Czech Republic</option>
                                                    <option value="Denmark">Denmark</option>
                                                    <option value="Egypt">Egypt</option>
                                                    <option value="Estonia">Estonia</option>
                                                    <option value="Ethiopia">Ethiopia</option>
                                                    <option value="Finland">Finland</option>
                                                    <option value="France">France</option>
                                                    <option value="Germany">Germany</option>
                                                    <option value="Ghana">Ghana</option>
                                                    <option value="Greece">Greece</option>
                                                    <option value="Hungary">Hungary</option>
                                                    <option value="Iceland">Iceland</option>
                                                    <option value="India">India</option>
                                                    <option value="Indonesia">Indonesia</option>
                                                    <option value="Iran">Iran</option>
                                                    <option value="Iraq">Iraq</option>
                                                    <option value="Ireland">Ireland</option>
                                                    <option value="Israel">Israel</option>
                                                    <option value="Italy">Italy</option>
                                                    <option value="Jamaica">Jamaica</option>
                                                    <option value="Japan">Japan</option>
                                                    <option value="Jordan">Jordan</option>
                                                    <option value="Kenya">Kenya</option>
                                                    <option value="Latvia">Latvia</option>
                                                    <option value="Lebanon">Lebanon</option>
                                                    <option value="Lithuania">Lithuania</option>
                                                    <option value="Malaysia">Malaysia</option>
                                                    <option value="Mexico">Mexico</option>
                                                    <option value="Morocco">Morocco</option>
                                                    <option value="Nepal">Nepal</option>
                                                    <option value="Netherlands">Netherlands</option>
                                                    <option value="NewZealand">New Zealand</option>
                                                    <option value="Nigeria">Nigeria</option>
                                                    <option value="Norway">Norway</option>
                                                    <option value="Pakistan">Pakistan</option>
                                                    <option value="Peru">Peru</option>
                                                    <option value="Philippines">Philippines</option>
                                                    <option value="Poland">Poland</option>
                                                    <option value="Portugal">Portugal</option>
                                                    <option value="Romania">Romania</option>
                                                    <option value="Russia">Russia</option>
                                                    <option value="SaudiArabia">Saudi Arabia</option>
                                                    <option value="Serbia">Serbia</option>
                                                    <option value="Singapore">Singapore</option>
                                                    <option value="Slovakia">Slovakia</option>
                                                    <option value="Slovenia">Slovenia</option>
                                                    <option value="SouthAfrica">South Africa</option>
                                                    <option value="SouthKorea">South Korea</option>
                                                    <option value="Spain">Spain</option>
                                                    <option value="SriLanka">Sri Lanka</option>
                                                    <option value="Sweden">Sweden</option>
                                                    <option value="Switzerland">Switzerland</option>
                                                    <option value="Syria">Syria</option>
                                                    <option value="Taiwan">Taiwan</option>
                                                    <option value="Thailand">Thailand</option>
                                                    <option value="Tunisia">Tunisia</option>
                                                    <option value="Turkey">Turkey</option>
                                                    <option value="Ukraine">Ukraine</option>
                                                    <option value="UnitedArabEmirates">United Arab Emirates</option>
                                                    <option value="UnitedKingdom">United Kingdom</option>
                                                    <option value="UnitedStates">United States</option>
                                                    <option value="Venezuela">Venezuela</option>
                                                    <option value="Vietnam">Vietnam</option>
                                                    <option value="Yemen">Yemen</option>
                                                    <option value="Zimbabwe">Zimbabwe</option>
                                                </select>
                                            </div>
                                            {/* <!--end::Input group--> */}

                                        </div>
                                        {/* <!--end::Input group--> */}
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            <label className="d-flex align-items-center form-label mb-5 required">Industry
                                            </label>
                                            <select
                                                name="industry"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.industry}
                                                onChange={handleInputChange}
                                                data-control="select2"
                                                data-placeholder="Select..."
                                                data-allow-clear="true"
                                                data-hide-search="true"
                                                required
                                            >
                                                <option value="">Select industry...</option>
                                                <option value="Agriculture">Agriculture</option>
                                                <option value="Automotive">Automotive</option>
                                                <option value="Aviation">Aviation</option>
                                                <option value="Biotechnology">Biotechnology</option>
                                                <option value="Chemical">Chemical</option>
                                                <option value="Communications">Communications</option>
                                                <option value="Construction">Construction</option>
                                                <option value="Consulting">Consulting</option>
                                                <option value="ConsumerGoods">Consumer Goods</option>
                                                <option value="Education">Education</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Energy">Energy</option>
                                                <option value="Engineering">Engineering</option>
                                                <option value="Entertainment">Entertainment</option>
                                                <option value="Environmental">Environmental</option>
                                                <option value="Finance">Finance</option>
                                                <option value="FoodAndBeverage">Food & Beverage</option>
                                                <option value="Government">Government</option>
                                                <option value="Healthcare">Healthcare</option>
                                                <option value="Hospitality">Hospitality</option>
                                                <option value="Insurance">Insurance</option>
                                                <option value="Legal">Legal</option>
                                                <option value="Manufacturing">Manufacturing</option>
                                                <option value="Marketing">Marketing</option>
                                                <option value="Media">Media</option>
                                                <option value="Mining">Mining</option>
                                                <option value="NonProfit">Non-Profit</option>
                                                <option value="Pharmaceutical">Pharmaceutical</option>
                                                <option value="ProfessionalServices">Professional Services</option>
                                                <option value="RealEstate">Real Estate</option>
                                                <option value="Retail">Retail</option>
                                                <option value="Technology">Technology</option>
                                                <option value="Telecommunications">Telecommunications</option>
                                                <option value="Textile">Textile</option>
                                                <option value="Transportation">Transportation</option>
                                                <option value="Utilities">Utilities</option>
                                                <option value="Veterinary">Veterinary</option>
                                                <option value="Wholesale">Wholesale</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 1--> */}
                                {/* <!--begin::Step 2--> */}
                                <div className={`${isStepActive(2)} step-2`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="w-100">
                                        {/* <!--begin::Input group--> */}
                                        <div className="fv-row">
                                            {/* <!--begin::Row--> */}
                                            <div className="row">
                                                {/* <!--begin::Col--> */}
                                                <div className="col-lg-4">
                                                    {/* <!--begin::Option--> */}
                                                    <input
                                                        type="radio"
                                                        className="btn-check"
                                                        name="educationalLevel"
                                                        value="NoBachelors"
                                                        id="educational_level_nobachelors"
                                                        checked={formState.educationalLevel === 'NoBachelors'}
                                                        onChange={handleInputChange}
                                                        required />
                                                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="educational_level_nobachelors">
                                                        <i className="ki-outline ki-wrench fs-3x me-5"></i>
                                                        {/* <!--begin::Info--> */}
                                                        <span className="d-block fw-semibold text-start">
                                                            <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Petitioner doesn't have a Bachelor's Degree</span>
                                                            <span className="text-muted fw-semibold fs-6">Petitioner did not complete a Bachelor's degree, but may have other qualifications.</span>
                                                        </span>
                                                        {/* <!--end::Info--> */}
                                                    </label>
                                                    {/* <!--end::Option--> */}
                                                </div>
                                                {/* <!--end::Col--> */}
                                                {/* <!--begin::Col--> */}
                                                <div className="col-lg-4">
                                                    {/* <!--begin::Option--> */}
                                                    <input
                                                        type="radio"
                                                        className="btn-check"
                                                        name="educationalLevel"
                                                        value="Bachelors"
                                                        id="educational_level_bachelors"
                                                        checked={formState.educationalLevel === 'Bachelors'}
                                                        onChange={handleInputChange}
                                                        required />

                                                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="educational_level_bachelors">
                                                        <i className="ki-outline ki-teacher fs-3x me-5"></i>
                                                        {/* <!--begin::Info--> */}
                                                        <span className="d-block fw-semibold text-start">
                                                            <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Petitioner has a Bachelor's Degree</span>
                                                            <span className="text-muted fw-semibold fs-6">Petitioner completed a Bachelor's degree program in his/her field.</span>
                                                        </span>
                                                        {/* <!--end::Info--> */}
                                                    </label>
                                                    {/* <!--end::Option--> */}
                                                </div>
                                                {/* <!--end::Col--> */}
                                                {/* <!--begin::Col--> */}
                                                <div className="col-lg-4">
                                                    {/* <!--begin::Option--> */}
                                                    <input
                                                        type="radio"
                                                        className="btn-check"
                                                        name="educationalLevel"
                                                        value="Masters"
                                                        id="educational_level_masters"
                                                        checked={formState.educationalLevel === 'Masters'}
                                                        onChange={handleInputChange}
                                                        required />
                                                    {/* <input type="radio" className="btn-check" name="profile_type" value="expert" id="profile_type_expert" /> */}
                                                    <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="educational_level_masters">
                                                        <i className="ki-outline ki-award fs-3x me-5"></i>
                                                        {/* <!--begin::Info--> */}
                                                        <span className="d-block fw-semibold text-start">
                                                            <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Petitioner has a Master's or PhD</span>
                                                            <span className="text-muted fw-semibold fs-6">Petitioner holds an advanced degree in his/her field of expertise.</span>
                                                        </span>
                                                        {/* <!--end::Info--> */}
                                                    </label>
                                                    {/* <!--end::Option--> */}
                                                </div>
                                                {/* <!--end::Col--> */}
                                            </div>
                                            {/* <!--end::Row--> */}
                                        </div>

                                        <div className="mx-auto mw-600px w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                            {/* 5+ Years Experience (Advanced Degree) */}
                                            {formState.educationalLevel === 'Bachelors' && (
                                                <div className='row mw-600px'>
                                                    <div className="d-flex flex-stack">
                                                        <div className="me-5">
                                                            <label className="fs-6 fw-semibold form-label">
                                                                Do you have 5+ years of experience after your Bachelor's degree?
                                                            </label>
                                                            <div className="fs-7 fw-semibold text-muted">
                                                                This experience should demonstrate progression and growth in your career.
                                                            </div>
                                                        </div>
                                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="experienceCheckbox"
                                                                checked={formState.has5YearsExperience || false}
                                                                onChange={(e) => setFormState({
                                                                    ...formState,
                                                                    has5YearsExperience: e.target.checked,  // Use the specific state variable for 5 years
                                                                })}
                                                            />
                                                            <span className="form-check-label fw-semibold text-muted">Yes</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 2--> */}
                                {/* <!--begin::Step 3--> */}
                                <div className={`${isStepActive(3)} step-3`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="mx-auto mw-600px w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                        {/* Question 1: Academic record */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">Do you have an official <b>academic record</b> showing a degree, diploma, certificate, or similar award relating to your area of exceptional ability?</label>
                                                <div className="fs-7 fw-semibold text-muted">This could be from a college, university, or other institution of learning in your field.</div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="academicRecordCheckbox"
                                                    checked={formState.hasAcademicRecord}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        hasAcademicRecord: e.target.checked,
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>

                                        {/* Question 2: Work experience */}
                                        {/* 10+ Years Experience (Exceptional Ability) */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">
                                                    Do you have letters from current or former employers documenting at least 10 years of full-time experience in your occupation?
                                                </label>
                                                <div className="fs-7 fw-semibold text-muted">
                                                    The letters should clearly state the duration and nature of your work experience.
                                                </div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="10YearsExperienceCheckbox"
                                                    checked={formState.has10YearsExperience || false}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        has10YearsExperience: e.target.checked,  // Use the specific state variable for 10 years
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>

                                        {/* Question 3: License or certification */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">Do you have a <b>license to practice</b> your profession or certification for your profession or occupation?</label>
                                                <div className="fs-7 fw-semibold text-muted">This could include any official certifications required to work in your field.</div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="licenseCheckbox"
                                                    checked={formState.hasLicense}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        hasLicense: e.target.checked,
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>

                                        {/* Question 4: Salary */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">Do you have evidence that you have commanded a <b>salary</b> or other remuneration for services that demonstrates your exceptional ability?</label>
                                                <div className="fs-7 fw-semibold text-muted">This should show that your earnings reflect your exceptional ability in your field.</div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="salaryCheckbox"
                                                    checked={formState.hasHighSalary}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        hasHighSalary: e.target.checked,
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>

                                        {/* Question 5: Membership in a professional association */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">Are you a member of a <b>professional association(s)</b> related to your field?</label>
                                                <div className="fs-7 fw-semibold text-muted">Membership should demonstrate your professional standing in your area of expertise.</div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="membershipCheckbox"
                                                    checked={formState.hasMembership}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        hasMembership: e.target.checked,
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>

                                        {/* Question 6: Recognition for achievements */}
                                        <div className="d-flex flex-stack mb-5">
                                            <div className="me-5">
                                                <label className="fs-6 fw-semibold form-label">Do you have recognition for your <b>achievements and significant contributions</b> from peers, government entities, or professional organizations?</label>
                                                <div className="fs-7 fw-semibold text-muted">This recognition should relate to your contributions to your field.</div>
                                            </div>
                                            <label className="form-check form-switch form-check-custom form-check-solid">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="recognitionCheckbox"
                                                    checked={formState.hasRecognition}
                                                    onChange={(e) => setFormState({
                                                        ...formState,
                                                        hasRecognition: e.target.checked,
                                                    })}
                                                />
                                                <span className="form-check-label fw-semibold text-muted">Yes</span>
                                            </label>
                                        </div>
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 3--> */}
                                {/* <!--begin::Step 4--> */}
                                <div className={`${isStepActive(4)} step-4`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="mx-auto mw-600px w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                        {/* <!--begin::Heading--> */}
                                        <div className="pb-8 pb-lg-10 text-center">
                                            {/* <!--begin::Title--> */}
                                            <h2 className="fw-bold text-gray-900">Proposed Endeavor (Prong 1)</h2>
                                            {/* <!--end::Title--> */}
                                            {/* <!--begin::Subtitle--> */}
                                            <div className="text-muted fw-semibold fs-6">Don't worry! You can skip these questions for now and come back to them later.</div>
                                            <div className="text-muted fw-semibold fs-6">You can fill out these details about your proposed endeavor when you're ready.</div>
                                            {/* <!--end::Subtitle--> */}
                                        </div>
                                        {/* <!--end::Heading--> */}

                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-10 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3">State of Proposed Endeavor (optional)
                                                <span className="ms-1" data-bs-toggle="tooltip" title="Please select the U.S. state where you intend to execute your proposed endeavor. You may update this information at any time should your plans change.">
                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span></label>
                                            {/* <!--end::Label--> */}
                                            <select
                                                name="stateOfEndeavor"
                                                className="form-select form-select-lg form-select-solid"
                                                value={formState.stateOfEndeavor}
                                                onChange={handleInputChange}
                                            >
                                                <option value="">Select state...</option>
                                                <option value="Alabama">Alabama</option>
                                                <option value="Alaska">Alaska</option>
                                                <option value="Arizona">Arizona</option>
                                                <option value="Arkansas">Arkansas</option>
                                                <option value="California">California</option>
                                                <option value="Colorado">Colorado</option>
                                                <option value="Connecticut">Connecticut</option>
                                                <option value="Delaware">Delaware</option>
                                                <option value="DistrictOfColumbia">District of Columbia</option>
                                                <option value="Florida">Florida</option>
                                                <option value="Georgia">Georgia</option>
                                                <option value="Hawaii">Hawaii</option>
                                                <option value="Idaho">Idaho</option>
                                                <option value="Illinois">Illinois</option>
                                                <option value="Indiana">Indiana</option>
                                                <option value="Iowa">Iowa</option>
                                                <option value="Kansas">Kansas</option>
                                                <option value="Kentucky">Kentucky</option>
                                                <option value="Louisiana">Louisiana</option>
                                                <option value="Maine">Maine</option>
                                                <option value="Maryland">Maryland</option>
                                                <option value="Massachusetts">Massachusetts</option>
                                                <option value="Michigan">Michigan</option>
                                                <option value="Minnesota">Minnesota</option>
                                                <option value="Mississippi">Mississippi</option>
                                                <option value="Missouri">Missouri</option>
                                                <option value="Montana">Montana</option>
                                                <option value="Nebraska">Nebraska</option>
                                                <option value="Nevada">Nevada</option>
                                                <option value="NewHampshire">New Hampshire</option>
                                                <option value="NewJersey">New Jersey</option>
                                                <option value="NewMexico">New Mexico</option>
                                                <option value="NewYork">New York</option>
                                                <option value="NorthCarolina">North Carolina</option>
                                                <option value="NorthDakota">North Dakota</option>
                                                <option value="Ohio">Ohio</option>
                                                <option value="Oklahoma">Oklahoma</option>
                                                <option value="Oregon">Oregon</option>
                                                <option value="Pennsylvania">Pennsylvania</option>
                                                <option value="PuertoRico">Puerto Rico</option>
                                                <option value="RhodeIsland">Rhode Island</option>
                                                <option value="SouthCarolina">South Carolina</option>
                                                <option value="SouthDakota">South Dakota</option>
                                                <option value="Tennessee">Tennessee</option>
                                                <option value="Texas">Texas</option>
                                                <option value="Utah">Utah</option>
                                                <option value="Vermont">Vermont</option>
                                                <option value="Virginia">Virginia</option>
                                                <option value="Washington">Washington</option>
                                                <option value="WestVirginia">West Virginia</option>
                                                <option value="Wisconsin">Wisconsin</option>
                                                <option value="Wyoming">Wyoming</option>
                                            </select>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                        {/* <!--begin::Input group--> */}
                                        <div className="mb-0 fv-row">
                                            {/* <!--begin::Label--> */}
                                            <label className="form-label mb-3">Brief overview of your Proposed Endeavor (optional)
                                                <span className="ms-1" data-bs-toggle="tooltip" title="Provide a concise summary of your proposed endeavor. This should capture the essence of your project or activity.">
                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                        <span className="path3"></span>
                                                    </i>
                                                </span></label>
                                            {/* <!--end::Label--> */}
                                            <textarea className="form-control form-control form-control-solid" data-kt-autosize="true" name="endeavorSummary" value={formState.endeavorSummary} onChange={handleInputChange}></textarea>
                                        </div>
                                        {/* <!--end::Input group--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 4--> */}
                                {/* <!--begin::Step 5--> */}
                                <div className={`${isStepActive(5)} step-5`} data-kt-stepper-element="content">
                                    {/* <!--begin::Wrapper--> */}
                                    <div className="mx-auto mw-600px w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                                        {/* <!--begin::Heading--> */}
                                        {formState.visaType === "EB2NIW" && (
                                            <div className="pb-4 pb-lg-3 text-center">
                                                {/* <!--begin::Title--> */}
                                                <h2 className="fw-bold text-gray-900">Congratulations!</h2>
                                                {/* <!--end::Title--> */}
                                                {/* <!--begin::Notice--> */}
                                                <div className="text-muted fw-semibold fs-6">
                                                    You have successfully fulfilled all the eligibility requirements for the EB-2 NIW petition!
                                                </div>
                                                {/* <!--end::Notice--> */}
                                            </div>
                                        )}
                                        {/* <!--end::Heading--> */}

                                        {/* <!--begin::Body--> */}
                                        <div className="mb-0">

                                            {/* <!--begin::Text--> */}
                                            {formState.visaType === "EB2NIW" && (
                                                <>
                                                    <div className="fs-6 text-gray-600 mb-5 text-center">
                                                        Based on your answers, you are eligible under the
                                                        <span className="fw-bold">
                                                            {formState.educationalLevel === 'Masters' || (formState.educationalLevel === 'Bachelors' && formState.has5YearsExperience)
                                                                ? ' Advanced Degree '
                                                                : ' Exceptional Ability '}
                                                        </span>
                                                        category.
                                                    </div>
                                                    {/* <!--end::Text--> */}

                                                    {/* <!--begin::Section: Advanced Degree or Exceptional Ability--> */}
                                                    {formState.educationalLevel === 'Masters' || formState.has5YearsExperience ? (
                                                        <div className="d-flex align-items-center bg-light-primary rounded p-6 mb-5">
                                                            <i className="ki-outline ki-teacher fs-2tx text-primary me-4"></i>
                                                            <div>
                                                                <h4 className="text-gray-900 fw-bold mb-1">Advanced Degree</h4>
                                                                <p>{formState.educationalLevel === 'Masters' ? "Master's or PhD" : "Bachelor's Degree with 5+ Years of Experience"}</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="#" onClick={() => setActiveStep(1)} className="fw-bold">Edit</a>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="d-flex align-items-center bg-light-success rounded p-6 mb-5">
                                                            <i className="ki-outline ki-badge fs-2tx text-success me-4"></i>
                                                            <div>
                                                                <h4 className="text-gray-900 fw-bold mb-2">Exceptional Ability</h4>
                                                                <ol className="mb-0" style={{ marginLeft: -10 }}>
                                                                    {formState.hasAcademicRecord && (
                                                                        <li>Academic Record</li>
                                                                    )}
                                                                    {formState.has10YearsExperience && (
                                                                        <li>10+ Years Experience</li>
                                                                    )}
                                                                    {formState.hasLicense && (
                                                                        <li>License</li>
                                                                    )}
                                                                    {formState.hasHighSalary && (
                                                                        <li>High Salary</li>
                                                                    )}
                                                                    {formState.hasMembership && (
                                                                        <li>Membership</li>
                                                                    )}
                                                                    {formState.hasRecognition && (
                                                                        <li>Recognition</li>
                                                                    )}
                                                                </ol>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="#" onClick={() => setActiveStep(2)} className="fw-bold">Edit</a>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* <!--end::Section: Advanced Degree or Exceptional Ability--> */}
                                                </>
                                            )}

                                            {/* <!--begin::Section: Petitioner's Details--> */}
                                            <div className="d-flex align-items-center bg-light-info rounded p-6 mb-5">
                                                <i className="ki-outline ki-user fs-2tx text-info me-4"></i>
                                                <div>
                                                    <h4 className="text-gray-900 fw-bold mb-2">Petitioner's Details</h4>
                                                    <div>Name: {formState.petitionerName}</div>
                                                    <div>Age: {formState.age}</div>
                                                    <div>Gender: {formState.gender}</div>

                                                    {formState.employmentStatus && (
                                                        <div>Employment Status: {formState.employmentStatus}</div>
                                                    )}

                                                    {formState.placeOfBirth && (
                                                        <div>Place of Birth: {formState.placeOfBirth}</div>
                                                    )}

                                                    {formState.countryOfResidence && (
                                                        <div>Country of Residence: {formState.countryOfResidence}</div>
                                                    )}

                                                    {formState.industry && (
                                                        <div>Industry: {formState.industry}</div>
                                                    )}
                                                </div>

                                                {/* Edit button in the top right corner */}
                                                <div className="ms-auto">
                                                    <a href="#" onClick={() => setActiveStep(3)} className="fw-bold">Edit</a>
                                                </div>
                                            </div>
                                            {/* <!--end::Section: Petitioner's Details--> */}

                                            {formState.visaType === "EB2NIW" && (
                                                <>
                                                    {/* <!--begin::Section: Proposed Endeavor--> */}
                                                    <div className="d-flex align-items-center bg-light-warning rounded p-6 mb-5">
                                                        <i className="ki-outline ki-flag fs-2tx text-warning me-4"></i>
                                                        <div>
                                                            <h4 className="text-gray-900 fw-bold mb-2">Proposed Endeavor</h4>
                                                            <div>State: {formState.stateOfEndeavor}</div>
                                                            <div>Overview: {formState.endeavorSummary}</div>
                                                        </div>
                                                        <div className="ms-auto">
                                                            <a href="#" onClick={() => setActiveStep(4)} className="fw-bold">Edit</a>
                                                        </div>
                                                    </div>
                                                    {/* <!--end::Section: Proposed Endeavor--> */}
                                                </>
                                            )}
                                        </div>
                                        {/* <!--end::Body--> */}
                                    </div>
                                    {/* <!--end::Wrapper--> */}
                                </div>
                                {/* <!--end::Step 5--> */}

                                {/* </form> */}
                                {/* <!--end::Form--> */}
                            </div>
                            {/* <!--end::Stepper--> */}
                        </div>
                        {/*  {/* <!--end::Modal body--> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <span className="btn btn-light-secondary" onClick={closeCreatePetitionNewModal}>
                            Close
                        </span>

                        {activeStep > 1 && (
                            <span className="btn btn-light-primary" onClick={handlePrevStep}>
                                <i className="ki-solid ki-arrow-left fs-4 me-1" />Back
                            </span>
                        )}

                        {activeStep < totalSteps ? (
                            <span className="btn btn-primary" onClick={handleNextStep}>
                                Continue
                                <i className="ki-solid ki-arrow-right fs-4 ms-1 me-0" />
                            </span>
                        ) : (
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        )}

                    </Modal.Footer>
                </form>
            </Modal >

            {/* Set profile form */}
            < Modal show={modalProfileIsOpen} onHide={() => { }
            } backdrop="static" keyboard={false} className="modal-xl" >
                <form onSubmit={handleSubmitProfile}>
                    <Modal.Header>
                        <Modal.Title>Complete your profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/*  {/* <!--begin::Modal body--> */}
                        <div className="scroll-y">
                            {/* <!--begin::Stepper--> */}
                            <div className="d-flex flex-column">
                                {/* <!--begin::Form--> */}
                                {/* <!--begin::Wrapper--> */}
                                <div className="w-100">
                                    {/* <!--begin::Heading--> */}
                                    <div className="pb-10 pb-lg-15">
                                        {/* <!--begin::Title--> */}
                                        <h2 className="fw-bold d-flex align-items-center text-gray-900">Choose Profile Type
                                            {/* <span className="ms-1" data-bs-toggle="tooltip" title="Billing is issued based on your selected account typ"> */}
                                            {/* <i className="ki-outline ki-information-5 text-gray-500 fs-6"></i> */}
                                            {/* </span> */}
                                        </h2>
                                        {/* <!--end::Title--> */}
                                        {/* <!--begin::Notice--> */}
                                        <div className="text-muted fw-semibold fs-6">Let us know your experience level with the US immigration process. This helps us tailor the content to your needs.</div>
                                        {/* <!--end::Notice--> */}
                                    </div>
                                    {/* <!--end::Heading--> */}
                                    {/* <!--begin::Input group--> */}
                                    <div className="fv-row">
                                        {/* <!--begin::Row--> */}
                                        <div className="row">
                                            {/* <!--begin::Col--> */}
                                            <div className="col-lg-4">
                                                {/* <!--begin::Option--> */}
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="profileType"
                                                    value="Beginner"
                                                    id="profile_type_beginner"
                                                    checked={profileType === 'Beginner'}
                                                    onChange={(e) => setProfileType(e.target.value)}
                                                    required />
                                                <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="profile_type_beginner">
                                                    <i className="ki-outline ki-scooter-2 fs-3x me-5"></i>
                                                    {/* <!--begin::Info--> */}
                                                    <span className="d-block fw-semibold text-start">
                                                        <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Beginner</span>
                                                        <span className="text-muted fw-semibold fs-6">You are at the beginning of your journey and ready to learn the process step by step.</span>
                                                    </span>
                                                    {/* <!--end::Info--> */}
                                                </label>
                                                {/* <!--end::Option--> */}
                                            </div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div className="col-lg-4">
                                                {/* <!--begin::Option--> */}
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="profileType"
                                                    value="VeryFamiliar"
                                                    id="profile_type_veryfamiliar"
                                                    checked={profileType === 'VeryFamiliar'}
                                                    onChange={(e) => setProfileType(e.target.value)}
                                                    required />

                                                {/* <input type="radio" className="btn-check" name="profile_type" value="familiar" id="profile_type_veryfamiliar" /> */}
                                                <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-10" htmlFor="profile_type_veryfamiliar">
                                                    <i className="ki-outline ki-car-3 fs-3x me-5"></i>
                                                    {/* <!--begin::Info--> */}
                                                    <span className="d-block fw-semibold text-start">
                                                        <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Very Familiar</span>
                                                        <span className="text-muted fw-semibold fs-6">You are confident about the steps involved and may have experience with self-petitioning.</span>
                                                    </span>
                                                    {/* <!--end::Info--> */}
                                                </label>
                                                {/* <!--end::Option--> */}
                                            </div>
                                            {/* <!--end::Col--> */}
                                            {/* <!--begin::Col--> */}
                                            <div className="col-lg-4">
                                                {/* <!--begin::Option--> */}
                                                <input
                                                    type="radio"
                                                    className="btn-check"
                                                    name="profileType"
                                                    value="Expert"
                                                    id="profile_type_expert"
                                                    checked={profileType === 'Expert'}
                                                    onChange={(e) => setProfileType(e.target.value)}
                                                    required />
                                                {/* <input type="radio" className="btn-check" name="profile_type" value="expert" id="profile_type_expert" /> */}
                                                <label className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center" htmlFor="profile_type_expert">
                                                    <i className="ki-outline ki-ship fs-3x me-5"></i>
                                                    {/* <!--begin::Info--> */}
                                                    <span className="d-block fw-semibold text-start">
                                                        <span className="text-gray-900 fw-bold d-block fs-4 mb-2">Expert</span>
                                                        <span className="text-muted fw-semibold fs-6">You are highly knowledgeable about the petition process and provide guidance to others.</span>
                                                    </span>
                                                    {/* <!--end::Info--> */}
                                                </label>
                                                {/* <!--end::Option--> */}
                                            </div>
                                            {/* <!--end::Col--> */}
                                        </div>
                                        {/* <!--end::Row--> */}
                                    </div>
                                    {/* <!--end::Input group--> */}
                                </div>
                                {/* <!--end::Wrapper--> */}
                                {/* <!--end::Form--> */}
                            </div>
                            {/* <!--end::Stepper--> */}
                        </div>
                        {/*  {/* <!--end::Modal body--> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>

                    </Modal.Footer>
                </form>
            </Modal >

            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
                {/* <!--begin::Theme mode setup on page load--> */}
                {/* <!--end::Theme mode setup on page load--> */}
                {/* <!--begin::App--> */}
                <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                    {/* <!--begin::Page--> */}
                    <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                        {/* <!--begin::Header--> */}
                        <Header />
                        {/* <!--end::Header--> */}
                        {/* <!--begin::Wrapper--> */}
                        <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                            {/* <!--begin::Toolbar--> */}
                            <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                                {/* <!--begin::Toolbar container--> */}
                                <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                    {/* <!--begin::Toolbar container--> */}
                                    <div className="d-flex flex-stack flex-row-fluid">
                                        {/* <!--begin::Toolbar container--> */}
                                        <div className="d-flex flex-column flex-row-fluid">
                                            {/* <!--begin::Toolbar wrapper--> */}
                                            {/* <!--begin::Breadcrumb--> */}
                                            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                                {/* <!--begin::Item--> */}
                                                <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                    <span className="text-white text-hover-primary">
                                                        <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                                    </span>
                                                </li>
                                                {/* <!--end::Item--> */}
                                                <li className="breadcrumb-item">
                                                    <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                                </li>
                                                <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">Petitions</li>

                                            </ul>
                                            {/* <!--end::Breadcrumb--> */}
                                        </div>
                                        {/* <!--end::Toolbar container--> */}
                                        {/* <!--begin::Actions--> */}
                                        {/* <!--end::Actions--> */}
                                    </div>
                                    {/* <!--end::Toolbar container--> */}
                                </div>
                                {/* <!--end::Toolbar container--> */}
                            </div>
                            {/* <!--end::Toolbar--> */}

                            {/* <!--begin::Wrapper container--> */}
                            <div className="app-container container-xxl d-flex">
                                {/* <!--begin::Main--> */}
                                <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                    {/* <!--begin::Content wrapper--> */}
                                    <div className="d-flex flex-column flex-column-fluid">
                                        {/* <!--begin::Content--> */}
                                        <div id="kt_app_content" className="app-content">
                                            {/* <!--begin::Row--> */}
                                            <div className="row g-5 g-xxl-10">
                                                {/* <!--begin::Col--> */}
                                                <div className="col-xxl-12">
                                                    {/* <!--begin::Card Widget 22--> */}
                                                    <div className="card card-reset mb-5 mb-xl-10">
                                                        {/* <!--begin::Body--> */}
                                                        <div className="card-body p-0">
                                                            {/* <!--begin::Row--> */}
                                                            <div className="row g-5 g-lg-9">
                                                                <div className="row">
                                                                    {/* <!--begin::Toolbar--> */}
                                                                    <div className="d-flex flex-wrap flex-stack my-5">
                                                                        {/* <!--begin::Heading--> */}
                                                                        <h2 className="fs-2 fw-semibold my-2">My Petitions
                                                                            <span className="fs-6 text-gray-500 ms-2">Petitions created by me</span>
                                                                        </h2>
                                                                        {/* <!--end::Heading--> */}

                                                                        {/* <!--begin::New Petition Button--> */}
                                                                        <button className="btn btn-primary" onClick={openCreatePetitionNewModal}>
                                                                            <i className="ki-outline ki-plus fs-4 me-2"></i>
                                                                            New Petition
                                                                        </button>
                                                                        {/* <!--end::New Petition Button--> */}
                                                                    </div>
                                                                    {/* <!--end::Toolbar--> */}

                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="row">

                                                                            {/* <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                                                                                <div className="card border border-primary border-dashed rounded bg-light-primary" onClick={openCreatePetitionNewModal} style={{ cursor: 'pointer' }}>
                                                                                    <div className="card-body py-12">
                                                                                        <div className="d-flex">
                                                                                            <span className="me-5">
                                                                                                <i className="ki-outline ki-plus fs-3x text-primary"></i>
                                                                                            </span>
                                                                                            <div className="me-2">
                                                                                                <span href="#" className="text-gray-800 text-hover-primary fs-4 fw-bolder">New Petition!</span>
                                                                                                <span className="text-gray-700 fw-semibold d-block fs-5">An Original Submission or RFE.</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}

                                                                            {petitions.length > 0 ? (
                                                                                petitions.map(petition => (
                                                                                    <div key={petition.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                                                                                        <div className="card h-100 petition-card">
                                                                                            {/* <!--begin::Body--> */}
                                                                                            <Link to={`/petition/${petition.id}`}>
                                                                                                {/* <div className="card-body pt-10 px-0 border border-primary rounded bg-hover-light-dark"> */}
                                                                                                <div className="card-body p-9">
                                                                                                    {/* <!--begin::Member--> */}
                                                                                                    <div className="d-flex flex-column text-center mb-3 px-9">
                                                                                                        {/* <!--begin::Stat--> */}
                                                                                                        {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-6 ms-6">
                                                                                                            <div className="align-items-center">
                                                                                                                <div className="fs-1 fw-bold text-primary">{petition.engagementPercentile}%</div>
                                                                                                            </div>
                                                                                                            <div className="fw-semibold fs-6 text-gray-500">Visa Genius Ranking
                                                                                                                <span className="ms-1" data-bs-toggle="tooltip" title="A higher percentile shows your petition has more frequent and varied activities compared to most others in Visa Genius' database. For example, a percentile of 75% means your petition's engagement is higher than 75% of all petitions, emphasizing your strong participation and areas for further enhancement.">
                                                                                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                                                        <span className="path1"></span>
                                                                                                                        <span className="path2"></span>
                                                                                                                        <span className="path3"></span>
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                        {/* <!--end::Stat--> */}
                                                                                                        {/* <!--begin::Info--> */}
                                                                                                        <div className="text-center">
                                                                                                            {/* <!--begin::Name--> */}
                                                                                                            <span className="fw-bold fs-4 text-gray-800">{petition.petitionerName}</span>
                                                                                                            {/* <!--end::Name--> */}
                                                                                                            {/* <!--begin::Petition--> */}
                                                                                                            <span className="text-muted d-block fw-semibold">{petition.placeOfBirth}</span>
                                                                                                            {petition.receiptNumber && (
                                                                                                                <span className="text-muted d-block fw-semibold text-gray-800">{petition.receiptNumber}</span>
                                                                                                            )}
                                                                                                            {/* <!--end::Position--> */}
                                                                                                        </div>
                                                                                                        {/* <!--end::Info--> */}
                                                                                                    </div>
                                                                                                    {/* <!--end::Member--> */}
                                                                                                    {/* <!--begin::Navbar--> */}
                                                                                                    <div className="m-0">
                                                                                                        <div id="kt_job_1_1" className="collapse show fs-6 ms-1">
                                                                                                            <div className="text-center mb-4">
                                                                                                                <span className={`badge py-2 px-3 fs-7 badge-light border border-info text-info`}>{petition.visaType}</span>
                                                                                                            </div>

                                                                                                            {petition && (!petition.visaType || petition.visaType == "EB2NIW") &&
                                                                                                                <>
                                                                                                                    {/* <!--begin::Item--> */}
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            {petition.subCategory === "AD" && (
                                                                                                                                <>
                                                                                                                                    <i className="ki-outline ki-teacher fs-3 text-muted me-3"></i>Advanced Degree
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                            {petition.subCategory === "EA" && (
                                                                                                                                <>
                                                                                                                                    <i className="ki-outline ki-medal-star fs-3 text-muted me-3"></i>Exceptional Ability
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* <!--end::Item--> */}
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            <i className="ki-outline ki-wrench fs-3 text-muted me-3"></i>{petition.industry || '-'}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.stateOfEndeavor || '-'}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {/* <!--end::Navbar--> */}
                                                                                                </div>
                                                                                            </Link>
                                                                                            {/* <!--end::Body--> */}

                                                                                            {/* <!-- Display shared icon if petition is shared --> */}
                                                                                            {petition.SharedWithArray && petition.SharedWithArray.length > 0 && (
                                                                                                <div className="position-absolute top-0 end-0 m-3">
                                                                                                    <i className="ki-outline ki-share fs-2 text-primary" title="Petition Shared"></i>
                                                                                                </div>
                                                                                            )}

                                                                                            {/* <!-- Delete link positioned at the bottom-right corner of the card --> */}
                                                                                            {!isPetitionOwner(petition, currentUser) ? (
                                                                                                <span className="btn btn-light-danger" style={{
                                                                                                    position: 'absolute',
                                                                                                    bottom: '10px',
                                                                                                    right: '10px',
                                                                                                    padding: '5px 10px',
                                                                                                }} onClick={() => deletePetitionConfirmation(petition.id)}>Delete</span>
                                                                                            ) : (<></>)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            ) : (<span>No petitions</span>)}


                                                                            {(loadingPetitions) ? (
                                                                                <div className="spinner-border text-primary" role="status">
                                                                                    <span className="sr-only">Loading data...</span>
                                                                                </div>
                                                                            ) : (<></>)}

                                                                        </div>
                                                                    </div>

                                                                    {/* <!--begin::Toolbar--> */}
                                                                    <div className="d-flex flex-wrap flex-stack my-5">
                                                                        {/* <!--begin::Heading--> */}
                                                                        <h2 className="fs-2 fw-semibold my-2">Shared With Me
                                                                            <span className="fs-6 text-gray-500 ms-2">Petitions shared with me</span></h2>
                                                                        {/* <!--end::Heading--> */}
                                                                    </div>
                                                                    {/* <!--end::Toolbar--> */}

                                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="row">

                                                                            {petitionsSharedWithMe.length > 0 ? (
                                                                                petitionsSharedWithMe.map(petition => (
                                                                                    <div key={petition.id} className="col-12 col-sm-6 col-md-4 col-lg-3 mb-5">
                                                                                        {/* <!--begin::Petition item--> */}
                                                                                        <div className="card h-100">
                                                                                            {/* <!--begin::Body--> */}
                                                                                            <Link to={`/petition/${petition.id}`}>
                                                                                                {/* <div className="card-body pt-10 px-0 border border-primary rounded bg-hover-light-dark"> */}
                                                                                                <div className="card-body p-9">
                                                                                                    {/* <!--begin::Member--> */}
                                                                                                    <div className="d-flex flex-column text-center mb-3 px-9">
                                                                                                        {/* <!--begin::Stat--> */}
                                                                                                        {/* <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-6 ms-6">
                                                                                                            <div className="align-items-center">
                                                                                                                <div className="fs-1 fw-bold text-primary">{petition.engagementPercentile}%</div>
                                                                                                            </div>
                                                                                                            <div className="fw-semibold fs-6 text-gray-500">Visa Genius Ranking
                                                                                                                <span className="ms-1" data-bs-toggle="tooltip" title="A higher percentile shows your petition has more frequent and varied activities compared to most others in Visa Genius' database. For example, a percentile of 75% means your petition's engagement is higher than 75% of all petitions, emphasizing your strong participation and areas for further enhancement.">
                                                                                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                                                        <span className="path1"></span>
                                                                                                                        <span className="path2"></span>
                                                                                                                        <span className="path3"></span>
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div> */}
                                                                                                        {/* <!--end::Stat--> */}
                                                                                                        {/* <!--begin::Info--> */}
                                                                                                        <div className="text-center">
                                                                                                            {/* <!--begin::Name--> */}
                                                                                                            <span className="fw-bold fs-4 text-gray-800">{petition.petitionerName}</span>
                                                                                                            {/* <!--end::Name--> */}
                                                                                                            {/* <!--begin::Petition--> */}
                                                                                                            <span className="text-muted d-block fw-semibold">{petition.placeOfBirth}</span>
                                                                                                            {petition.receiptNumber && (
                                                                                                                <span className="text-muted d-block fw-semibold text-gray-800">{petition.receiptNumber}</span>
                                                                                                            )}
                                                                                                            {/* <!--end::Position--> */}
                                                                                                        </div>
                                                                                                        {/* <!--end::Info--> */}
                                                                                                    </div>
                                                                                                    {/* <!--end::Member--> */}
                                                                                                    {/* <!--begin::Navbar--> */}
                                                                                                    <div className="m-0">
                                                                                                        <div id="kt_job_1_1" className="collapse show fs-6 ms-1">
                                                                                                            <div className="text-center mb-4">
                                                                                                                <span className={`badge py-2 px-3 fs-7 badge-light border border-info text-info`}>{petition.visaType}</span>
                                                                                                            </div>

                                                                                                            {petition && (!petition.visaType || petition.visaType == "EB2NIW") &&
                                                                                                                <>
                                                                                                                    {/* <!--begin::Item--> */}
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            {petition.subCategory === "AD" && (
                                                                                                                                <>
                                                                                                                                    <i className="ki-outline ki-teacher fs-3 text-muted me-3"></i>Advanced Degree
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                            {petition.subCategory === "EA" && (
                                                                                                                                <>
                                                                                                                                    <i className="ki-outline ki-medal-star fs-3 text-muted me-3"></i>Exceptional Ability
                                                                                                                                </>
                                                                                                                            )}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    {/* <!--end::Item--> */}
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.industry || '-'}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="mb-4 fw-bold">
                                                                                                                        <div className="d-flex align-items-center ps-10 mb-n1 text-gray-700">
                                                                                                                            <i className="ki-outline ki-pin fs-3 text-muted me-3"></i>{petition.stateOfEndeavor || '-'}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <!--end::Navbar--> */}
                                                                                                </div>
                                                                                            </Link>
                                                                                            {/* <!--end::Body--> */}

                                                                                            {/* <!-- Display shared icon if petition is shared --> */}
                                                                                            {petition.SharedWithArray && petition.SharedWithArray.length > 0 && (
                                                                                                <div className="position-absolute top-0 end-0 m-3">
                                                                                                    <i className="ki-outline ki-share fs-2 text-primary" title="Petition Shared"></i>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                        {/* <!--end::Petition item--> */}
                                                                                    </div>
                                                                                ))
                                                                            ) : (<span>No shared petitions</span>)}

                                                                            {(loadingPetitionsSharedWithMe) ? (
                                                                                <div className="spinner-border text-primary" role="status">
                                                                                    <span className="sr-only">Loading data...</span>
                                                                                </div>
                                                                            ) : (<></>)}

                                                                        </div>
                                                                    </div>

                                                                </div >

                                                            </div>
                                                        </div>
                                                        {/* <!--end::Body--> */}
                                                    </div>
                                                    {/* <!--end::Card Widget 22--> */}
                                                </div>
                                                {/* <!--end::Col--> */}
                                            </div>
                                            {/* <!--end::Row--> */}
                                        </div>
                                        {/* <!--end::Content--> */}
                                    </div>
                                    {/* <!--end::Content wrapper--> */}
                                    {/* <!--begin::Footer--> */}
                                    <Footer />
                                    {/* <!--end::Footer--> */}
                                </div>
                                {/* <!--end:::Main--> */}
                            </div>
                            {/* <!--end::Wrapper container--> */}

                        </div>
                        {/* <!--end::Wrapper--> */}
                    </div>
                    {/* <!--end::Page--> */}
                </div>
            </div >
        </>
    );
}

export default Home;
