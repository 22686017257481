import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone';
import Chart from "react-apexcharts";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import GoogleDriveFrame from '../../components/GoogleDocs/GoogleDriveFrame.js';
import Modal from "react-bootstrap/Modal";
import { formatDate, formatBytes } from '../../utils/Utils.js'
// import WordCloud from './WordCloud.js';
import { fetchCoverLetter, createCoverLetter, getCoverLetterAnalysis, addFileCoverLetter } from '../../services/ApiService.js';
import { analyze } from '../../services/AIApiService.js';
import Loading from '../../components/Loading/Loading.js';

function PetitionCoverLetter({ petitionId, petitionFolderId, updatePetition, type, isReadOnly }) {
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [coverLetter, setCoverLetter] = useState(null);
    const [analysisList, setAnalysisList] = useState(null);
    const [highSeverityCount, setHighSeverityCount] = useState(0);
    const [modalPermissionIsOpen, setModalPermissionIsOpen] = React.useState(false);
    const [isAgreed, setIsAgreed] = useState(false); // State to track checkbox
    const [activeTab, setActiveTab] = useState(1);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const navigate = useNavigate();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                if (file.type !== 'application/pdf') {
                    Swal.fire("Error", "Only PDF files are accepted.", "error");
                    return;
                }
                const preview = URL.createObjectURL(file);
                uploadFile(Object.assign(file, { preview }));
            });
        }
    });


    function openModalPermission() {
        setModalPermissionIsOpen(true);
    }

    function closeModalPermission() {
        setModalPermissionIsOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            await fetchCoverLetterCall(petitionId, setCoverLetter);
            // await fetchDocumentText(coverLetter?.googleDocId)
        }

        fetchData();

    }, [petitionId]);

    function getSuggestionsByTitle(chapters, title1, title2) {
        const chapter = chapters?.find(chapter => chapter.title.startsWith(title1) || chapter.title.startsWith(title2));
        return chapter ? chapter.suggestions : null;
    }

    // Function to sort suggestions by severity
    function sortSuggestionsBySeverity(suggestions) {
        const severityOrder = { 'High': 1, 'Medium': 2, 'Low': 3 };
        return suggestions?.sort((a, b) => severityOrder[a.severity] - severityOrder[b.severity]);
    }

    async function fetchCoverLetterCall(petitionId, setCoverLetter) {
        fetchCoverLetter(petitionId, type)
            .then((result) => {
                // console.log(result);
                setCoverLetter(result);


                const suggestions = result?.GeniusResponse?.flatMap(chapter => chapter.suggestions);
                const highSeveritySuggestions = suggestions?.filter(suggestion => suggestion.severity === 'High');
                setHighSeverityCount(highSeveritySuggestions?.length);

                if (result.LastAnalysisRun) {
                    getCoverLetterAnalysis(petitionId, type)
                        .then((analysisList) => {
                            // console.log(analysisList);
                            setAnalysisList(analysisList);
                        })
                        .catch((error) => {
                            console.error('Error fetching cover letter analysis:', error);
                            return null;
                        });

                }

            })
            .catch((error) => {
                console.error('Error fetching cover letter:', error);
                return null;
            });


    }

    const createCoverLetterCall = async () => {
        // console.log('createCoverLetterCall');

        try {
            setLoading(true);

            await createCoverLetter(petitionId, petitionFolderId, type)
                .then((result) => {
                    setLoading(false);
                    setCoverLetter(result);
                })
                .catch((error) => {
                    // console.log('createCoverLetterCall catch')
                    setLoading(false);

                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", "An error occurred while creating cover letter. Please try again.", "error");
                    }

                });

        } catch (error) {
            setLoading(false);

            console.error(error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", "An error occurred while creating cover letter. Please try again.", "error");
            }
        }
    };

    function getSentimentPolarityDescription(polarity) {
        if (polarity < -0.5) {
            return "Very negative";
        } else if (polarity < -0.1) {
            return "Negative";
        } else if (polarity < 0.1) {
            return "Neutral";
        } else if (polarity < 0.5) {
            return "Positive";
        } else {
            return "Very positive";
        }
    }

    function getSentimentSubjectivityDescription(subjectivity) {
        if (subjectivity < 0.3) {
            return "Very objective";
        } else if (subjectivity < 0.6) {
            return "Objective";
        } else if (subjectivity < 0.9) {
            return "Subjective";
        } else {
            return "Very subjective";
        }
    }

    async function runAnalysis() {
        const fileId = coverLetter?.googleDocId;
        const data = {
            fileId,
            petitionId,
            type
        };

        if (coverLetter?.isDocs == 0) {
            Swal.fire("", "This feature is available only for Cover Letters created inside Visa Genius.", "info");

        }
        else {
            try {
                setLoading(true);

                const response = await analyze(fileId, petitionId, type);

                // const response = await fetch(`${process.env.REACT_APP_AI_API_URL}/analyze`, {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json'
                //     },
                //     body: JSON.stringify(data)
                // });

                // if (response.status === 401) {
                //     navigate('/login');
                // }

                // const result = await response.json();

                await fetchCoverLetterCall(petitionId, setCoverLetter);

                setActiveTab(2)

                setLoading(false);
            } catch (error) {
                setLoading(false);
                Swal.fire("Error", 'Error analyzing text: ' + error.message, "error");
            }
        }
    }

    function getBadgeClass(severity) {
        switch (severity) {
            case 'High':
                return 'badge-danger';
            case 'Medium':
                return 'badge-warning';
            case 'Low':
                return 'badge-success';
            default:
                return '';
        }
    }

    function categorizeScore(score, metric) {
        let category = 'Moderate';
        let className = 'text-warning';

        switch (metric) {
            case 'flesch_reading_ease':
                if (score >= 80) {
                    category = 'Easy';
                    className = 'text-success';
                } else if (score >= 60) {
                    category = 'Moderate';
                    className = 'text-warning';
                } else {
                    category = 'Difficult';
                    className = 'text-danger';
                }
                break;
            case 'flesch_kincaid_grade':
            case 'smog_index':
            case 'gunning_fog':
                if (score <= 6) {
                    category = 'Easy';
                    className = 'text-success';
                } else if (score <= 12) {
                    category = 'Moderate';
                    className = 'text-warning';
                } else {
                    category = 'Difficult';
                    className = 'text-danger';
                }
                break;
            default:
                category = 'Moderate';
                className = 'text-warning';
                break;
        }

        return <span className={className}>{category}</span>;
    }

    function getReadabilityMetrics(readabilityScores) {
        return Object.entries(readabilityScores).map(([metric, score]) => {
            if (metric === "flesch_reading_ease" || metric === "smog_index" || metric === "gunning_fog" || metric === "flesch_kincaid_grade") {
                let metricName = '';
                let metricDesc = '';

                switch (metric) {
                    case 'flesch_reading_ease':
                        metricName = 'Flesch Reading Ease';
                        metricDesc = 'Provides an easily understandable score indicating how easy the text is to read. Higher scores indicate easier readability.';
                        break;
                    case 'flesch_kincaid_grade':
                        metricName = 'Flesch-Kincaid Grade';
                        metricDesc = 'Indicates the U.S. school grade level necessary to understand the text. Lower scores indicate easier readability.';
                        break;
                    case 'smog_index':
                        metricName = 'SMOG Index';
                        metricDesc = 'Estimates the years of education needed to understand the text. It\'s particularly reliable for assessing texts with more complex vocabulary.';
                        break;
                    case 'gunning_fog':
                        metricName = 'Gunning Fog';
                        metricDesc = 'Measures the complexity of the text, focusing on sentence length and difficult words. Lower scores indicate easier readability.';
                        break;
                    default:
                        break;
                }

                return (
                    <div key={metric} className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        {/* <!--begin::Number--> */}
                        <div className="d-flex align-items-center">
                            <div className="fs-4 fw-bold">{categorizeScore(score, metric)}</div>
                        </div>
                        {/* <!--end::Number--> */}
                        {/* <!--begin::Label--> */}
                        <div className="fw-semibold fs-6 text-gray-500">{metricName}
                            <span className="ms-1" data-bs-toggle="tooltip" title={metricDesc}>
                                <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                    <span className="path1"></span>
                                    <span className="path2"></span>
                                    <span className="path3"></span>
                                </i>
                            </span>
                        </div>
                        {/* <!--end::Label--> */}
                    </div>
                );
            } else {
                return null;
            }
        }).filter(Boolean); // Filter out null values
    }

    let current_chapter = 1;


    const transformDataForChart = (analysisList) => {
        const wordCountData = [];
        const sentenceCountData = [];
        const sentimentPolarityData = [];
        const sentimentSubjectivityData = [];
        const categories = [];

        analysisList?.sort((a, b) => a.timespan - b.timespan);

        analysisList?.forEach((analysis, index) => {
            wordCountData.push(analysis.metrics.word_count);
            sentenceCountData.push(analysis.metrics.sentence_count);
            sentimentPolarityData.push(analysis.metrics.sentiment_polarity);
            sentimentSubjectivityData.push(analysis.metrics.sentiment_subjectivity);

            const truncatedTitle = analysis.title.length > 10 ? analysis.title.substring(0, 10) + '...' : analysis.title;
            categories.push(truncatedTitle);
        });

        return {
            wordCountData,
            sentenceCountData,
            sentimentPolarityData,
            sentimentSubjectivityData,
            categories
        };
    };

    const { wordCountData, sentenceCountData, sentimentPolarityData, sentimentSubjectivityData, categories } = transformDataForChart(analysisList);

    const sizeChartSeries = [
        {
            name: 'Word Count',
            data: wordCountData
        },
        {
            name: 'Sentence Count',
            data: sentenceCountData
        }
    ];

    const sentimentChartSeries = [
        {
            name: 'Polarity',
            data: sentimentPolarityData
        },
        {
            name: 'Subjectivity',
            data: sentimentSubjectivityData
        }
    ];

    const sizeChartOptions = {
        chart: {
            type: 'area',
            height: '200',
            toolbar: {
                show: false
            }
        },
        plotOptions: {},
        legend: {
            show: true
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px'
                }
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px'
                }
            }
        },
        // yaxis: {
        //     labels: {
        //         show: false
        //         // style: {
        //         //     fontSize: '12px',
        //         // }
        //     }
        // },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                labels: {
                    show: false
                }
            },
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                labels: {
                    show: false
                }
            }
        ],
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val
                    // return val + " tasks"
                }
            }
        },
        grid: {
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            strokeWidth: 3
        }
    };

    const sentimentChartOptions = {
        chart: {
            type: 'area',
            height: '200',
            toolbar: {
                show: false
            }
        },
        plotOptions: {},
        legend: {
            show: true
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            type: 'solid',
            opacity: 1
        },
        stroke: {
            curve: 'smooth',
            show: true,
            width: 3
        },
        xaxis: {
            categories: categories,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false
            },
            labels: {
                style: {
                    fontSize: '12px'
                }
            },
            crosshairs: {
                position: 'front',
                stroke: {
                    width: 1,
                    dashArray: 3
                }
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: '12px'
                }
            }
        },
        // yaxis: {
        //     labels: {
        //         show: false
        //         // style: {
        //         //     fontSize: '12px',
        //         // }
        //     }
        // },
        yaxis: [
            {
                axisTicks: {
                    show: true
                },
                labels: {
                    show: false
                }
            },
            {
                opposite: true,
                axisTicks: {
                    show: true
                },
                labels: {
                    show: false
                }
            }
        ],
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0
                }
            }
        },
        tooltip: {
            style: {
                fontSize: '12px',
            },
            y: {
                formatter: function (val) {
                    return val
                    // return val + " tasks"
                }
            }
        },
        grid: {
            strokeDashArray: 4,
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        markers: {
            strokeWidth: 3
        }
    };

    const uploadFile = async (file) => {
        setLoading(true);
        try {
            const result = await addFileCoverLetter(file, petitionId, petitionFolderId, type);

            if (result.status === 401) {
                navigate('/login');
            }

            const fileId = result.googleDriveFileId;
            const data = { fileId };

            if (result.status === 401) {
                navigate('/login');
            }

            await fetchCoverLetterCall(petitionId, setCoverLetter);


            // console.log('Fetching RFE after upload...');
            // await fetchFileCoverLetterCall(petitionId, setRFE);

            // const responseData = await response.json();

            // if (responseData.error && responseData.error.includes('The PDF content is empty')) {
            //     Swal.fire("Error", responseData.error, "error");
            // } else {

            //     // console.log('Fetching sentences after upload...');
            //     await fetchRFESentencesCall(fileId, setSentences);
            // }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            // console.log(error);
            if (error.message === 'Unauthorized') {
                logout();
            }
            if (error.message === 'Too Large') {
                Swal.fire("Info", "Your file exceeds the maximum upload size limit. Please ensure that your file is smaller than 50MB and try again.", "info");
            } else {
                Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
            }
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <Modal show={modalPermissionIsOpen} onHide={closeModalPermission} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms of Use and Data Manipulation Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y">
                        <div className="d-flex flex-column">
                            <div className="w-100">
                                <div className="mb-10 mb-lg-15">
                                    <div className="fs-6">
                                        <p>At Visa Genius, your privacy and trust are paramount. We are committed to safeguarding your personal information and ensuring that your data is used responsibly. This policy outlines how we handle your data and the measures we take to protect your privacy.</p>
                                        <h5>Data Usage</h5>
                                        <p>By using Visa Genius, you agree to allow us to use the data you provide to enhance your experience with our services. Specifically:</p>
                                        <ul>
                                            <li><strong>Improving User Experience:</strong> We use the data you provide to tailor and improve the features and functionality of our platform. This helps us understand how you use our services and make necessary enhancements for a better user experience.</li>
                                            <li><strong>Personalization:</strong> Your data enables us to personalize your interactions with Visa Genius, making our platform more intuitive and user-friendly.</li>
                                        </ul>
                                        <h5>Data Protection</h5>
                                        <p>We understand the importance of protecting your data and ensuring your privacy. Therefore, we adhere to the following principles:</p>
                                        <ul>
                                            <li><strong>Confidentiality:</strong> Your data will never be shared with any third-party companies. We do not sell, trade, or otherwise transfer your information to external parties.</li>
                                            <li><strong>Employee Access:</strong> No Visa Genius employee will have access to your personal data. Our systems are designed to ensure that your information is kept private and secure.</li>
                                            <li><strong>Security Measures:</strong> We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes using encryption and secure servers to store your information.</li>
                                        </ul>
                                        <h5>Your Rights</h5>
                                        <p>You have the right to access, modify, and delete your data at any time. Should you have any questions or concerns about how your data is being used, please contact our support team, and we will be happy to assist you.</p>
                                        <h5>Conclusion</h5>
                                        <p>We value your trust and are dedicated to maintaining the highest standards of privacy and data protection. By agreeing to this policy, you can be confident that your data is used solely to enhance your experience with Visa Genius, and your privacy is always our priority.</p>
                                        <p>Thank you for choosing Visa Genius. We are committed to providing you with a secure and personalized experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPermission}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal>
            {coverLetter && Object.keys(coverLetter).length > 0 ? (
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12'>
                        {/* {/* <!--begin::Navbar--> */}
                        <div className="card mb-5 mb-xl-10">
                            <div className="card-body pt-9 pb-0">
                                {/* {/* <!--begin::Details--> */}
                                <div className="d-flex flex-wrap flex-sm-nowrap">
                                    {/* {/* <!--begin::Info--> */}
                                    <div className="flex-grow-1">
                                        {/* {/* <!--begin::Title--> */}
                                        <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                            {/* <!--begin::Info--> */}
                                            <div className="d-flex flex-wrap justify-content-start">
                                                {/* <!--begin::Stats--> */}
                                                <div className="d-flex flex-wrap">
                                                    {/* <!--begin::Stat--> */}
                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                        {/* <!--begin::Number--> */}
                                                        <div className="d-flex align-items-center">
                                                            {coverLetter.Metrics ? (
                                                                <div className="fs-4 fw-bold">{coverLetter.Metrics?.chapter_count}</div>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                        {/* <!--end::Number--> */}
                                                        {/* <!--begin::Label--> */}
                                                        <div className="fw-semibold fs-6 text-gray-500">Chapters</div>
                                                        {/* <!--end::Label--> */}
                                                    </div>
                                                    {/* <!--end::Stat--> */}
                                                    {/* <!--begin::Stat--> */}
                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                        {/* <!--begin::Number--> */}
                                                        <div className="d-flex align-items-center">
                                                            {coverLetter.Metrics ? (
                                                                <div className="fs-4 fw-bold">{coverLetter.Metrics?.total_word_count}</div>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                        {/* <!--end::Number--> */}
                                                        {/* <!--begin::Label--> */}
                                                        <div className="fw-semibold fs-6 text-gray-500">Words</div>
                                                        {/* <!--end::Label--> */}
                                                    </div>
                                                    {/* <!--end::Stat--> */}
                                                    {/* <!--begin::Stat--> */}
                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                        {/* <!--begin::Number--> */}
                                                        <div className="d-flex align-items-center">
                                                            {coverLetter.Metrics ? (
                                                                <div className="fs-4 fw-bold">{coverLetter.Metrics?.total_sentence_count}</div>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                        {/* <!--end::Number--> */}
                                                        {/* <!--begin::Label--> */}
                                                        <div className="fw-semibold fs-6 text-gray-500">Sentences</div>
                                                        {/* <!--end::Label--> */}
                                                    </div>
                                                    {/* <!--end::Stat--> */}
                                                    {/* <!--begin::Stat--> */}
                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                        {/* <!--begin::Number--> */}
                                                        <div className="d-flex align-items-center">
                                                            {coverLetter.Metrics ? (
                                                                <>
                                                                    <i className={`ki-outline me-2 fs-3 ${coverLetter.Metrics?.avg_sentiment_polarity < -0.1 ? "ki-dislike text-danger" : "ki-like text-success"}`}></i>
                                                                    <div className={`fs-4 fw-bold ${coverLetter.Metrics?.avg_sentiment_polarity < -0.1 ? "text-danger" : "text-success"}`}>{getSentimentPolarityDescription(coverLetter.Metrics?.avg_sentiment_polarity)}</div>
                                                                </>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                        {/* <!--end::Number--> */}
                                                        {/* <!--begin::Label--> */}
                                                        <div className="fw-semibold fs-6 text-gray-500">Sentiment</div>
                                                        {/* <!--end::Label--> */}
                                                    </div>
                                                    {/* <!--end::Stat--> */}
                                                    {/* <!--begin::Stat--> */}
                                                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                        {/* <!--begin::Number--> */}
                                                        <div className="d-flex align-items-center">
                                                            {coverLetter.Metrics ? (
                                                                <>
                                                                    <i className={`ki-outline me-2 fs-3 ${coverLetter.Metrics?.avg_sentiment_subjectivity < 0.6 ? "ki-book-open text-success" : "ki-heart text-danger"}`}></i>
                                                                    <div className={`fs-4 fw-bold ${coverLetter.Metrics?.avg_sentiment_subjectivity < 0.6 ? "text-success" : "text-danger"}`}>{getSentimentSubjectivityDescription(coverLetter.Metrics?.avg_sentiment_subjectivity)}</div>
                                                                </>
                                                            ) : (
                                                                <span>-</span>
                                                            )}
                                                        </div>
                                                        {/* <!--end::Number--> */}
                                                        {/* <!--begin::Label--> */}
                                                        <div className="fw-semibold fs-6 text-gray-500">Objectivity</div>
                                                        {/* <!--end::Label--> */}
                                                    </div>
                                                    {/* <!--end::Stat--> */}
                                                </div>
                                                {/* <!--end::Stats--> */}
                                            </div>

                                            {/* <!--end::Info--> */}
                                            {/* {/* <!--begin::Actions--> */}
                                            <div className="d-flex my-4">
                                                {coverLetter.LastAnalysisRun ? (
                                                    <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                                        <i className="ki-outline ki-time-5 fs-4 me-1"></i>
                                                        Last analysis {formatDate(coverLetter.LastAnalysisRun)}
                                                    </span>
                                                ) : (
                                                    <span className="d-flex align-items-center text-danger text-hover-primary me-5 mb-2">
                                                        <i className="ki-outline ki-information-5 fs-4 me-1 text-danger "></i>
                                                        Never analyzed
                                                    </span>

                                                )}
                                                <span className="btn btn-sm btn-primary me-3" onClick={runAnalysis}>Run Analysis!</span>
                                            </div>
                                            {/* {/* <!--end::Actions--> */}
                                        </div>

                                        {((!analysisList || analysisList.length < 2) && (coverLetter?.isDocs != 0) && (coverLetter?.isFirestore != 1)) &&
                                            (
                                                <div className="alert alert-danger d-flex align-items-center p-5">
                                                    {/* <!--begin::Icon--> */}
                                                    <i className="ki-duotone ki-shield-tick fs-2hx text-danger me-4"><span className="path1"></span><span className="path2"></span></i>
                                                    {/* <!--end::Icon--> */}

                                                    {/* <!--begin::Wrapper--> */}
                                                    <div className="d-flex flex-column">
                                                        {/* <!--begin::Title--> */}
                                                        {/* <h4 className="mb-1 text-danger">This is an alert</h4> */}
                                                        {/* <!--end::Title--> */}

                                                        {/* <!--begin::Content--> */}
                                                        {/* <span>If Visa Genius was unable to find chapters in your cover letter, it may be because the text isn't properly formatted. Please ensure that each chapter title is marked with a <span className='fw-bold'>Heading 1</span> tag for accurate analysis.</span> */}
                                                        <span className='fs-5'>If Visa Genius was unable to find chapters in your cover letter, it might be because the text isn't properly formatted. Please ensure that each chapter title is marked with a <span className='fw-bold'>Heading 1</span> tag for accurate analysis. For detailed instructions on how to apply heading styles, please visit <a href="https://it.umn.edu/services-technologies/how-tos/google-docs-apply-modify-heading-styles" target="_blank">this guide</a>.</span>

                                                        {/* <!--end::Content--> */}
                                                    </div>
                                                    {/* <!--end::Wrapper--> */}
                                                </div>
                                            )}
                                        {/* {/* <!--end::Title--> */}
                                    </div>
                                    {/* {/* <!--end::Info--> */}
                                </div>
                                {/* {/* <!--end::Details--> */}
                                {/* {/* <!--begin::Navs--> */}
                                <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                    {/* {/* <!--begin::Nav item--> */}
                                    <li className="nav-item mt-2" style={{ cursor: 'pointer' }}>
                                        <span onClick={() => setActiveTab(1)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 1 ? "active" : ""}`}>Content
                                        </span>
                                    </li>
                                    {/* {/* <!--end::Nav item--> */}
                                    {/* {/* <!--begin::Nav item--> */}
                                    <li className="nav-item mt-2" style={{ cursor: 'pointer' }}>
                                        <span onClick={() => setActiveTab(2)} className={`nav-link text-active-primary ms-0 me-10 py-5 ${activeTab === 2 ? "active" : ""}`}>Genius' Analysis  <span className="badge badge-success ms-2">BETA</span>
                                            {highSeverityCount > 0 && (
                                                <span className="badge badge-danger ms-2">{highSeverityCount}</span>
                                            )}
                                        </span>
                                    </li>
                                    {/* {/* <!--end::Nav item--> */}
                                </ul>
                                {/* {/* <!--begin::Navs--> */}
                            </div>
                        </div>
                        {/* {/* <!--end::Navbar--> */}

                        <div style={{ display: activeTab === 1 ? "block" : "none" }}>
                            <GoogleDriveFrame documentId={coverLetter?.googleDocId} title={coverLetter.fileName} isDocs={coverLetter.isDocs == 0 ? 0 : 1} isFirestore={coverLetter.isFirestore == 0 ? 0 : 1} storageUrl={coverLetter.storageUrl} mimeType={coverLetter.mimeType} divTitle="Cover Letter" divSubtitle="Work on your cover letter inside Visa Genius and make the most of it!" />
                        </div>

                        <div style={{ display: activeTab === 2 ? "block" : "none" }}>
                            {(!coverLetter.LastAnalysisRun || !analysisList || analysisList.length == 0) ?
                                (
                                    <div className='row'>
                                        <div className='col-12 col-md-12 col-lg-12'>
                                            {/* {/* <!--begin::Card--> */}
                                            <div className="card">
                                                {/* {/* <!--begin::Card body--> */}
                                                <div className="card-body">
                                                    {/* {/* <!--begin::Heading--> */}
                                                    <div className="card-px text-center pt-15 pb-15">
                                                        {/* {/* <!--begin::Title--> */}
                                                        <h2 className="fs-2x fw-bold mb-0 text-primary">Request a Genius' Analysis of your Petition!</h2>
                                                        {/* {/* <!--end::Title--> */}
                                                        {/* {/* <!--begin::Description--> */}
                                                        <p className="text-gray-500 fs-4 fw-semibold py-7">Click the button below to let Visa Genius
                                                            <br />suggest improvements to your petition's Cover Letter!</p>
                                                        {/* {/* <!--end::Description--> */}
                                                        {/* {/* <!--begin::Action--> */}
                                                        <span onClick={runAnalysis} className="btn btn-primary er fs-6 px-8 py-4">Run Analysis!</span>
                                                        {/* {/* <!--end::Action--> */}
                                                    </div>
                                                    {/* {/* <!--end::Heading--> */}
                                                    {/* {/* <!--begin::Illustration--> */}
                                                    <div className="text-center pb-15 px-5">
                                                        <img src="/assets/media/illustrations/4.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                                    </div>
                                                    {/* {/* <!--end::Illustration--> */}
                                                </div>
                                                {/* {/* <!--end::Card body--> */}
                                            </div>
                                            {/* {/* <!--end::Card--> */}
                                        </div>
                                    </div>

                                )
                                :
                                (<div className="row">
                                    <div className='col-12 col-md-6 col-lg-6'>
                                        <div className="card mb-5 mb-xl-10">
                                            {/*  {/* {/* <!--begin::Header--> */}
                                            <div className="card-header">
                                                {/*  {/* {/* <!--begin::Title--> */}
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fs-3 fw-bold text-gray-800">Size per Chapter</span>
                                                </h3>
                                                {/*  {/* {/* <!--end::Title--> */}
                                            </div>
                                            {/*  {/* {/* <!--end::Header--> */}
                                            <div className="card-body pt-0 pb-0" >
                                                <Chart
                                                    options={sizeChartOptions}
                                                    height="250"
                                                    series={sizeChartSeries}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-6 col-lg-6'>
                                        <div className="card mb-5 mb-xl-10">
                                            {/*  {/* {/* <!--begin::Header--> */}
                                            <div className="card-header">
                                                {/*  {/* {/* <!--begin::Title--> */}
                                                <h3 className="card-title align-items-start flex-column">
                                                    <span className="card-label fs-3 fw-bold text-gray-800">Sentiment per Chapter</span>
                                                </h3>
                                                {/*  {/* {/* <!--end::Title--> */}
                                            </div>
                                            {/*  {/* {/* <!--end::Header--> */}
                                            <div className="card-body pt-0 pb-0" >
                                                <Chart
                                                    options={sentimentChartOptions}
                                                    height="250"
                                                    series={sentimentChartSeries}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}

                            {analysisList?.map(analysis => {
                                // console.log(analysis);
                                const chapterTitle1 = `## Chapter ${current_chapter} ##`;
                                const chapterTitle2 = `##Chapter ${current_chapter}##`;
                                const suggestions = getSuggestionsByTitle(coverLetter.GeniusResponse, chapterTitle1, chapterTitle2);
                                // console.log(suggestions);
                                const sortedSuggestions = sortSuggestionsBySeverity(suggestions);
                                // console.log(sortedSuggestions);
                                current_chapter++;

                                return (
                                    <div key={analysis.id} className="card mb-5 mb-xl-10">
                                        {/*  {/* {/* <!--begin::Header--> */}
                                        <div className="card-header pt-7">
                                            {/*  {/* {/* <!--begin::Title--> */}
                                            <h3 className="card-title align-items-start flex-column">
                                                <span className="badge badge-light-info me-2 py-2 px-2 fs-7 mb-2">Chapter {current_chapter - 1}</span>
                                                <span className="card-label fs-3 fw-bold text-gray-800">{analysis.title}</span>
                                                <span className="text-gray-500 mt-1 fw-semibold fs-6">{analysis.preview + '...'}</span>
                                            </h3>
                                            {/*  {/* {/* <!--end::Title--> */}
                                        </div>
                                        {/*  {/* {/* <!--end::Header--> */}

                                        <div className="card-body pt-9 pb-0" >
                                            {/* <!--begin::key words--> */}
                                            <div className='d-flex flex-wrap'>
                                                <div className="mb-5">
                                                    {Object.entries(analysis.metrics.keyword_density)?.map(([keyword, density]) => (
                                                        <span key={keyword} className="badge badge-light-primary me-2 py-3 px-4 fs-7">{keyword}</span>
                                                    ))}
                                                </div>
                                            </div>
                                            {/* <!--end::key words--> */}
                                            {/* <!--begin::Stats--> */}
                                            <div className="d-flex flex-wrap mb-5">
                                                {/* <!--begin::Stat--> */}
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    {/* <!--begin::Number--> */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fs-4 fw-bold">{analysis.metrics.word_count}</div>
                                                    </div>
                                                    {/* <!--end::Number--> */}
                                                    {/* <!--begin::Label--> */}
                                                    <div className="fw-semibold fs-6 text-gray-500">Words</div>
                                                    {/* <!--end::Label--> */}
                                                </div>
                                                {/* <!--end::Stat--> */}
                                                {/* <!--begin::Stat--> */}
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    {/* <!--begin::Number--> */}
                                                    <div className="d-flex align-items-center">
                                                        <div className="fs-4 fw-bold">{analysis.metrics.sentence_count}</div>
                                                    </div>
                                                    {/* <!--end::Number--> */}
                                                    {/* <!--begin::Label--> */}
                                                    <div className="fw-semibold fs-6 text-gray-500">Sentences</div>
                                                    {/* <!--end::Label--> */}
                                                </div>
                                                {/* <!--end::Stat--> */}
                                                {/* <!--begin::Stat--> */}
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    {/* <!--begin::Number--> */}
                                                    <div className="d-flex align-items-center">
                                                        <i className={`ki-outline me-2 fs-3 ${analysis.metrics.sentiment_polarity < -0.1 ? "ki-dislike text-danger" : "ki-like text-success"}`}></i>
                                                        <div className={`fs-4 fw-bold ${analysis.metrics.sentiment_polarity < -0.1 ? "text-danger" : "text-success"}`}>{getSentimentPolarityDescription(analysis.metrics.sentiment_polarity)}</div>
                                                    </div>
                                                    {/* <!--end::Number--> */}
                                                    {/* <!--begin::Label--> */}
                                                    <div className="fw-semibold fs-6 text-gray-500">Sentiment</div>
                                                    {/* <!--end::Label--> */}
                                                </div>
                                                {/* <!--end::Stat--> */}
                                                {/* <!--begin::Stat--> */}
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    {/* <!--begin::Number--> */}
                                                    <div className="d-flex align-items-center">
                                                        <i className={`ki-outline me-2 fs-3 ${analysis.metrics.sentiment_subjectivity < 0.6 ? "ki-book-open text-success" : "ki-heart text-danger"}`}></i>
                                                        <div className={`fs-4 fw-bold ${analysis.metrics.sentiment_subjectivity < 0.6 ? "text-success" : "text-danger"}`}>{getSentimentSubjectivityDescription(analysis.metrics.sentiment_subjectivity)}</div>
                                                    </div>
                                                    {/* <!--end::Number--> */}
                                                    {/* <!--begin::Label--> */}
                                                    <div className="fw-semibold fs-6 text-gray-500">Objectivity</div>
                                                    {/* <!--end::Label--> */}
                                                </div>
                                                {/* <!--end::Stat--> */}
                                            </div>
                                            {/* <!--end::Stats--> */}
                                            <div className='mb-3'>
                                                <span className="text-muted fs-5 fw-bold">Readability Metrics</span>
                                            </div>
                                            <div className="d-flex flex-wrap mb-5">
                                                {getReadabilityMetrics(analysis.metrics.readability_scores)}
                                            </div>
                                            <div>
                                                <div>
                                                    {(sortedSuggestions && sortedSuggestions.length > 0) && (
                                                        <span className="text-muted fs-5 fw-bold">Suggestions</span>
                                                    )}
                                                    {sortedSuggestions?.map((suggestion, index) => (
                                                        <div key={index} className="mb-3">
                                                            {/* <!--begin::Menu separator--> */}
                                                            <div className="separator my-2"></div>
                                                            {/* <!--end::Menu separator--> */}
                                                            <p><span></span> <span className={`badge ${getBadgeClass(suggestion.severity)} me-2 py-3 px-4 fs-7`}>{suggestion.severity} severity</span></p>
                                                            <p><span className="fs-5">{suggestion.reason_for_modification}</span></p>
                                                            <p><i className="ki-outline me-2 fs-3 ki-dislike text-danger"></i> <span className="text-gray-500">{suggestion.original_sentence}</span></p>
                                                            {suggestion.modified_sentence && (
                                                                <p><i className="ki-outline me-2 fs-3 ki-like text-success"></i> <span>{suggestion.modified_sentence}</span></p>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div >
                </div >
            ) : (
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-12'>
                        {/* {/* <!--begin::Card--> */}
                        <div className="card">
                            {/* {/* <!--begin::Card body--> */}
                            <div className="card-body">

                                {!isReadOnly ? (
                                    <div className="card-px text-center pt-15 pb-15">
                                        {/* {/* <!--begin::Title--> */}
                                        <h2 className="fs-2x fw-bold mb-0 text-primary">Create Cover Letter</h2>
                                        {/* {/* <!--end::Title--> */}
                                        {/* {/* <!--begin::Description--> */}
                                        <p className="text-gray-500 fs-4 fw-semibold py-7">Click on the button below to start
                                            <br />writing your petition's Cover Letter!</p>
                                        {/* {/* <!--end::Description--> */}
                                        {/* {/* <!--begin::Agreed--> */}
                                        <div className="mb-3">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="agreeCheck"
                                                checked={isAgreed}
                                                onChange={(e) => setIsAgreed(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="agreeCheck">
                                                I agree to the <a href="#" onClick={openModalPermission}>terms of use</a> and the data manipulation policy.
                                            </label>
                                        </div>
                                        {/* {/* <!--end::Agreed--> */}
                                        {/* {/* <!--begin::Action--> */}
                                        {isAgreed ? (
                                            <span onClick={() => createCoverLetterCall()} className="btn btn-primary er fs-6 px-8 py-4"><img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "30px" }} />Create Cover Letter</span>
                                        ) : (
                                            <span className="btn btn-light-primary bg-hover-light-primary er fs-6 px-8 py-4" style={{ cursor: "default" }}><img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "30px" }} />Create Cover Letter</span>
                                        )}

                                        <div className='mt-5'>or</div>

                                        <div className='row'>
                                            <div className='col col-3'></div>
                                            <div className='col col-sm-12 col-md-6 col-lg-6'>
                                                <div {...getRootProps()} className="dropzone mt-5">
                                                    <input {...getInputProps()} name="files" />
                                                    {isDragActive ?
                                                        <p>Drop the file here ...</p> :
                                                        <p>Use this area to upload your Cover Letter. <br />Simply drag and drop the PDF file, or click to select the file from your device.</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col col-3'></div>
                                        </div>
                                        {/* {/* <!--end::Action--> */}
                                    </div>
                                ) : (
                                    <div className="card-px text-center pt-15 pb-15">
                                        {/* {/* <!--begin::Title--> */}
                                        <h2 className="fs-2x fw-bold mb-0 text-primary">The cover letter hasn't been created yet.</h2>
                                        {/* {/* <!--end::Title--> */}
                                        {/* {/* <!--begin::Description--> */}
                                        <p className="text-gray-500 fs-4 fw-semibold py-7">Please ask the petition owner to create it.</p>
                                        {/* {/* <!--end::Description--> */}
                                    </div>
                                )}

                                {/* {/* <!--begin::Illustration--> */}
                                <div className="text-center pb-15 px-5">
                                    <img src="/assets/media/illustrations/4.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                </div>
                                {/* {/* <!--end::Illustration--> */}
                            </div>
                            {/* {/* <!--end::Card body--> */}
                        </div>
                        {/* {/* <!--end::Card--> */}
                    </div>
                </div>
            )
            }

        </>
    );
}

export default PetitionCoverLetter;
