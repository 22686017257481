import { useLocation } from 'react-router-dom';
import { Timestamp } from "firebase/firestore";

function formatDate(timestamp, notime = false) {
    if (timestamp) {
        // Check if timestamp is already a Firestore Timestamp
        let date;
        if (timestamp instanceof Timestamp) {
            date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (timestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            date = new Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
        } else {
            console.error("Invalid timestamp data");
            return "";
        }

        let formattedDate = [];

        if (notime) {
            formattedDate = [
                padTo2Digits(date.getMonth() + 1), // Months are 0-based in JavaScript
                padTo2Digits(date.getDate()),
                date.getFullYear()
            ].join('/');
        }
        else {
            formattedDate = [
                padTo2Digits(date.getMonth() + 1), // Months are 0-based in JavaScript
                padTo2Digits(date.getDate()),
                date.getFullYear()
            ].join('/') + ' ' +
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes())
                ].join(':');
        }

        return formattedDate;
    }
    return ""; // Return empty string if no timestamp
}

function isOverdue(timestamp) {
    if (timestamp) {
        // Check if timestamp is already a Firestore Timestamp
        let date;
        if (timestamp instanceof Timestamp) {
            date = timestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (timestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            date = new Timestamp(timestamp._seconds, timestamp._nanoseconds).toDate();
        } else {
            console.error("Invalid timestamp data");
            return false; // Return false for invalid timestamps
        }

        // Get today's date without time (00:00:00)
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Compare the dates (ignoring time)
        return date < today; // If the date is before today, it is overdue
    }

    return false; // Return false if no timestamp
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

/**
 * Converts bytes to a more readable format (KB or MB).
 * @param {number} bytes - The number of bytes.
 * @return {string} The size in KB or MB rounded to the nearest integer.
 */
function formatBytes(bytes) {
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    if (megabytes >= 1) {
        return `${Math.round(megabytes)} mb`;
    } else {
        return `${Math.round(kilobytes)} kb`;
    }
}

function daysFromTimestamp(pastTimestamp) {
    // Check if the passed timestamp is a Firebase Timestamp and convert it
    let pastDate;

    if (pastTimestamp) {
        if (pastTimestamp instanceof Timestamp) {
            pastDate = pastTimestamp.toDate(); // Convert Firestore Timestamp to JavaScript Date
        } else if (pastTimestamp._seconds !== undefined) {
            // Reconstruct Firestore Timestamp from raw data
            pastDate = new Timestamp(pastTimestamp._seconds, pastTimestamp._nanoseconds).toDate();

            const currentDate = new Date();
            const differenceInTime = currentDate.getTime() - pastDate.getTime();
            const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
            return differenceInDays;
        }
    }
    else {
        return 0;
    }
}

function daysFromDateString(dateStr) {
    const date = new Date(dateStr);
    const today = new Date();
    const differenceInTime = today - date;
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
}

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function getFormBadgeClass(formType) {
    switch (formType) {
        case "g1450": return (<span>G-1450</span>);
        case "g1145": return (<span>G-1145</span>);
        case "i140": return (<span>I-140</span>);
        case "i907": return (<span>I-907</span>);
        case "i485": return (<span>I-485</span>);
        case "i765": return (<span>I-765</span>);
        case "i131": return (<span>I-131</span>);
        case "eta9089": return (<span>ETA-9089</span>);
        case "eta9089appendixa": return (<span>ETA-9089 Appendix A</span>);
        case "eta9089finaldetermination": return (<span>ETA-9089 Final Determination</span>);
        case "other": return (<span>other</span>);
        default: return (<span>{formType}</span>);
    }
};


// const { google } = require('googleapis');

// async function getThumbnailUrl(googleDocId, oauthToken) {
//     const auth = new google.auth.OAuth2();
//     auth.setCredentials({ access_token: oauthToken });
//     const drive = google.drive({ version: 'v3', auth });

//     try {
//         const response = await drive.files.get({
//             fileId: googleDocId,
//             fields: 'thumbnailLink',
//         });
//         return response.data.thumbnailLink;
//     } catch (error) {
//         console.error('Error fetching thumbnail URL:', error);
//         throw new Error('Failed to fetch thumbnail URL');
//     }
// }

function getFirstName(fullName) {
    if (typeof fullName !== 'string' || !fullName.trim()) {
        throw new Error('Invalid input: Name must be a non-empty string.');
    }

    const names = fullName.trim().split(/\s+/);
    return names[0];
}

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

const getUserIp = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
    }
};

/**
 * Check if the logged-in user is the owner of the petition
 * @param {Object} petition - The petition object
 * @param {Object} currentUser - The logged-in user
 * @returns {boolean} - True if the logged-in user is the owner, false otherwise
 */
const isPetitionOwner = (petition, currentUser) => {
    return petition?.userId === currentUser?.uid;
};

// const isUserReadOnly = (petition, userId) => {
//     if (!petition || !petition.sharedWithArray) {
//         console.log("No petition or SharedWithArray found.");
//         return true; // Default to true if petition or array isn't loaded
//     }

//     const sharedUser = petition.sharedWithArray.find(user => user.userId === userId);
//     return sharedUser ? sharedUser.readOnly : true;
// };

const generateColorFromName = (name) => {
    const colors = ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688', '#4CAF50'];
    if (name) {
        const charCode = name.charCodeAt(0); // Get ASCII code of first character
        return colors[charCode % colors.length]; // Pick a color based on the char code
    }
    else {
        return colors[4];
    }
};

const formatFileSize = (sizeInBytes) => {
    const sizeInKB = sizeInBytes / 1024;
    if (sizeInKB >= 1024) {
        const sizeInMB = sizeInKB / 1024;
        return `${sizeInMB.toFixed(2)} MB`;
    }
    return `${sizeInKB.toFixed(2)} KB`;
};

export {
    formatDate,
    isOverdue,
    formatBytes,
    daysFromTimestamp,
    useQuery,
    getFormBadgeClass,
    getFirstName,
    formatTimestamp,
    getUserIp,
    isPetitionOwner,
    generateColorFromName,
    formatFileSize
};