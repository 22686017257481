import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../pages/Login/Login';
import Home from '../pages/Home/Home';
import PetitionDetail from '../pages/Petition/PetitionDetail';
import { useAuth } from '../contexts/AuthContext';
import ExhibitDetail from '../pages/Exhibit/ExhibitDetail';
import RequestDetail from '../pages/Request/RequestDetail';
import Analytics from '../pages/Analytics/Analytics';
import Community from '../pages/Community/Community';
import CommunityPostPage from '../pages/Community/CommunityPostPage';
import Settings from '../pages/Settings/Settings';
import AdminPage from '../pages/Admin/AdminPage';
import MagicFormPage from '../pages/MagicForm/MagicFormPage';

const AppRoutes = () => {
    const { currentUser, loading, loginInProgress } = useAuth();

    if (loading || loginInProgress) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    const isAdmin = currentUser && currentUser.uid === "QFBK9I6akDOmOSzsPqQwuR49UsI2"; // Check if the current user is the admin

    return (
        <Routes>
            <Route path="/" element={!currentUser ? <Login /> : <Navigate to="/home" replace />} />
            <Route path="/home" element={currentUser ? <Home /> : <Navigate to="/" replace />} />
            <Route path="/login" element={<Login />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/community" element={<Community />} />
            <Route path="/community/post/:id" element={<CommunityPostPage />} />
            <Route path="/admin" element={isAdmin ? <AdminPage /> : <Navigate to="/home" replace />} />
            <Route path="/petition/:petitionId/settings" element={currentUser ? <Settings /> : <Navigate to="/" replace />} />

            {/* Original Submission */}
            <Route path="/petition/:petitionId/" element={currentUser ? <PetitionDetail type="OriginalSubmission" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/exhibit/:exhibitId" element={currentUser ? <ExhibitDetail type="OriginalSubmission" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/request/:requestId" element={currentUser ? <RequestDetail type="OriginalSubmission" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/magicform/" element={currentUser ? <MagicFormPage type="OriginalSubmission" /> : <Navigate to="/" replace />} />

            {/* RFE */}
            <Route path="/petition/:petitionId/rfe" element={currentUser ? <PetitionDetail type="RFEResponse" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/rfe/exhibit/:exhibitId" element={currentUser ? <ExhibitDetail type="RFEResponse" /> : <Navigate to="/" replace />} />
            <Route path="/petition/:petitionId/rfe/request/:requestId" element={currentUser ? <RequestDetail type="RFEResponse" /> : <Navigate to="/" replace />} />
        </Routes>
    );
};

export default AppRoutes;
