import React, { useState } from 'react';
import Swal from 'sweetalert2'
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { updateExhibitPosition } from '../../services/ApiService.js';
import { formatDate } from '../../utils/Utils.js';

const PetitionExhibits = ({ exhibits, deleteExhibitCall, renameExhibitCall, getBadgeClass, petitionId, type, isReadOnly, visaType }) => {
    // const [sortedExhibits, setSortedExhibits] = useState(exhibits);
    // console.log(exhibits);

    const deleteExhibitConfirmation = async (exhibitId) => {
        Swal.fire({
            customClass: {
                confirmButton: "btn btn-danger",
                cancelButton: "btn btn-light-info"
            },
            buttonsStyling: false,
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                deleteExhibitCall(exhibitId, type);
            }
        });
    }

    const renameExhibitConfirmation = async (exhibitId, currentname) => {
        const inputValue = currentname;
        const { value: newname } = await Swal.fire({
            title: "Enter Exhibit new name",
            input: "text",
            // inputLabel: "Current name",
            inputValue,
            showCancelButton: true,
            inputValidator: (value) => {
                if (!value) {
                    return "You need to write something!";
                }
            }
        });
        if (newname && inputValue != newname) {
            renameExhibitCall(exhibitId, newname, type);
        }
    }

    function countCheckedItems(exhibit) {
        let count = 0;

        if (exhibit.checkedItems) {
            // Count true values in advancedDegree
            for (let key in exhibit.checkedItems.advancedDegree) {
                if (exhibit.checkedItems.advancedDegree[key] === true) {
                    count++;
                }
            }

            // Count true values in exceptionalAbility
            for (let key in exhibit.checkedItems.exceptionalAbility) {
                if (exhibit.checkedItems.exceptionalAbility[key] === true) {
                    count++;
                }
            }

            // Count items in array attributes
            const arrayAttributes = [
                'benefitToUSDespiteWorkers',
                'descriptionEndeavor',
                'documentaryEvidence',
                'futureActivityPlans',
                'interestOfStakeholders',
                'laborCertificationImpracticality',
                'progressTowardsEndeavor',
                'qualifications',
                'urgencyAndJobCreation'
            ];

            arrayAttributes.forEach(attr => {
                count += exhibit.checkedItems[attr].length;
            });
        }

        return count;
    }

    const handleOnDragEnd = async (result) => {
        console.log('handleOnDragEnd')
        const { source, destination } = result;

        // If dropped outside the list or the positions are the same, do nothing
        if (!destination || (source.index === destination.index)) return;

        const items = Array.from(exhibits);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        // Update sequenceNumber for all items
        exhibits = items.map((item, index) => ({
            ...item,
            sequenceNumber: index + 1
        }));

        console.log({
            petitionId: petitionId,
            exhibitId: reorderedItem.id,
            type: type,
            currentPosition: source.index + 1,
            newPosition: destination.index + 1,
        });

        // setSortedExhibits(exhibits);

        updateExhibitPosition(petitionId, reorderedItem.id, type, source.index + 1, destination.index + 1)
            .then(() => console.log('Exhibit position updated successfully'))
            .catch(error => console.error('Error:', error));

    };

    return (
        <>
            {(exhibits.length > 0 ?
                <div className="table-responsive">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="exhibits">
                            {(provided) => (
                                <table className="table table-row-dashed align-middle gs-0 gy-6 my-0" {...provided.droppableProps} ref={provided.innerRef}>
                                    <thead>
                                        <tr className="fs-7 fw-bold text-gray-500">
                                            <th className="p-0 pb-3 text-start mw-25px"></th>
                                            <th className="p-0 pb-3 text-start mw-25px">#</th>
                                            <th className="p-0 pb-3 min-w-200px text-start">NAME</th>
                                            <th className="p-0 pb-3 w-150px text-start">TYPE</th>
                                            {visaType == "EB2NIW" &&
                                                <th className="p-0 pb-3 text-start">ITEMS</th>
                                            }
                                            <th className="p-0 pb-3 text-start">FILES</th>
                                            <th className="p-0 pb-3 min-w-50px text-start">CREATED AT</th>
                                            <th className="p-0 pb-3 min-w-100px text-center">ACTION</th>
                                        </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600" style={{ whiteSpace: 'nowrap' }}>
                                        {exhibits.map((exhibit, index) => (
                                            <Draggable key={exhibit.id} draggableId={exhibit.id} index={index}>
                                                {(provided) => (
                                                    <tr ref={provided.innerRef} {...provided.draggableProps}>
                                                        <td className="ps-0" {...provided.dragHandleProps}>
                                                            <i className="ki-solid ki-abstract-30 text-gray-700"></i>
                                                        </td>
                                                        <td className="ps-0">
                                                            {exhibit.sequenceNumber}
                                                        </td>

                                                        <td className="ps-0 text-start" data-bs-toggle="tooltip" title={exhibit.exhibitName}>
                                                            <div className="d-flex align-items-center">
                                                                <Link to={`/petition/${petitionId}/${type === "RFEResponse" ? "rfe/" : ""}exhibit/${exhibit.id}`} className="ellipsis">
                                                                    <span className="d-flex align-items-center">
                                                                        {((visaType == "EB2NIW" && countCheckedItems(exhibit) === 0) || exhibit.fileCount === 0) ? (
                                                                            <i className="ki-outline ki-information-5 fs-2x text-danger me-4" data-bs-toggle="tooltip" title="Exhibits must address at least one eligibility criterion or one of the three prongs of the Dhanasar framework for your EB-2 NIW petition and must have at least one attached file." style={{ cursor: "pointer" }}></i>
                                                                        ) : (
                                                                            <i className="ki-outline ki-folder fs-2x text-primary me-4"></i>
                                                                        )}
                                                                        <span className={`${((visaType == "EB2NIW" && countCheckedItems(exhibit) === 0) || exhibit.fileCount === 0) ? 'text-danger' : 'text-primary'} fs-6 d-block ellipsis`}>{exhibit.exhibitName}</span>
                                                                    </span>
                                                                </Link>
                                                            </div>
                                                        </td>
                                                        <td className="ps-0">{getBadgeClass(exhibit.exhibitType)}</td>
                                                        {visaType == "EB2NIW" &&
                                                            <td className={`ps-0 pe-20 ${countCheckedItems(exhibit) === 0 ? "text-danger" : ""} `}><span>{countCheckedItems(exhibit)}</span></td>
                                                        }
                                                        <td className={`ps-0 pe-20 ${exhibit.fileCount === 0 ? "text-danger" : ""} `}><span>{exhibit.fileCount}</span></td>
                                                        <td className="ps-0"><span>{formatDate(exhibit.createdAt, true)}</span></td>
                                                        <td className="text-center">
                                                            {!isReadOnly ? (
                                                                <>
                                                                    <span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px me-1" data-bs-toggle="tooltip" title="Rename Exhibit">
                                                                        <i className="ki-outline ki-notepad-edit fs-2 text-primary" onClick={() => renameExhibitConfirmation(exhibit.id, exhibit.exhibitName)}></i>
                                                                    </span>
                                                                    <span className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary w-30px h-30px" data-bs-toggle="tooltip" title="Delete Exhibit">
                                                                        <i className="ki-outline ki-delete-folder fs-2 text-danger" onClick={() => deleteExhibitConfirmation(exhibit.id)}></i>
                                                                    </span>
                                                                </>
                                                            ) : (<></>)}

                                                        </td>
                                                    </tr>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </tbody>
                                </table>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                :
                <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                    <img height="300px" src="/assets/media/illustrations/21.png" alt="No exhibits found"></img>
                </div>
            )}

        </>
    );
};

export default PetitionExhibits;
