import React, { useRef } from 'react';
import Swal from 'sweetalert2';

const GoogleDriveFrame = ({ documentId, title, isDocs, isFirestore, storageUrl, mimeType, divTitle, divSubtitle }) => {
    const iframeRef = useRef(null);

    const toggleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            iframeRef.current.requestFullscreen().catch(err => {
                Swal.fire("Error", `Error attempting to enable full-screen mode: ${err.message} (${err.name})`, "error");
            });
        }
    };

    let docUrl = '';

    // Check if file is stored in Firebase Storage
    if (isFirestore) {
        docUrl = storageUrl;
    } else {
        // File is stored on Google Drive
        if (isDocs || !isFirestore) {
            docUrl = `https://docs.google.com/document/d/${documentId}/edit`;
        } else {
            switch (mimeType) {
                case 'application/vnd.google-apps.spreadsheet':
                    docUrl = `https://docs.google.com/spreadsheets/d/${documentId}/edit`;
                    break;
                case 'application/vnd.google-apps.presentation':
                    docUrl = `https://docs.google.com/presentation/d/${documentId}/edit`;
                    break;
                case 'application/pdf':
                case 'image/png':
                case 'image/jpeg':
                case 'image/gif':
                case 'video/mp4':
                case 'video/webm':
                    docUrl = `https://drive.google.com/file/d/${documentId}/preview`;
                    break;
                default:
                    docUrl = `https://drive.google.com/file/d/${documentId}/view`;
                    break;
            }
        }
    }

    return (
        <>
            {/* <!--begin::Card--> */}
            <div className="card">
                {/* <!--begin::Header--> */}
                <div className="card-header pt-7">
                    {/* <!--begin::Title--> */}
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fs-3 fw-bold text-gray-800">{divTitle}</span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">{divSubtitle}</span>
                    </h3>
                    {/* <!--end::Title--> */}
                    {/* <!--begin::Actions--> */}
                    <div className="d-flex align-items-center gap-3">
                        {/* <!--begin::Fullscreen button--> */}
                        <div className="m-0">
                            <span className="text-muted cursor-pointer" onClick={toggleFullscreen}>
                                <i className="ki-outline ki-arrow-two-diagonals fs-1"></i>
                            </span>
                        </div>
                        {/* <!--end::Fullscreen button--> */}
                    </div>
                    {/* <!--end::Actions--> */}
                </div>
                {/* <!--end::Header--> */}
                {/* <!--begin::Card body--> */}
                <div className="card-body">
                    <div style={{ width: '100%', height: '100vh' }}>
                        <iframe
                            ref={iframeRef}
                            src={docUrl}
                            style={{ width: '100%', height: '100%', border: 'none' }}
                            allowFullScreen
                            title={title}
                        />
                    </div>
                </div>
                {/* <!--end::Card body--> */}
            </div>
            {/* <!--end::Card--> */}
        </>
    );
};

export default GoogleDriveFrame;
