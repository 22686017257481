import React, { useEffect, useState } from 'react';
import { formatDate } from '../../utils/Utils.js'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import { fetchRecentPetitions, fetchRecentRFEs, fetchRecentUsers, fetchRecentMovements, fetchRecentMovementsPerPetition, fetchTopEngagementPetitions, fetchRecentExhibits } from '../../services/ApiService.js';

function AdminPage() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [recentPetitions, setRecentPetitions] = useState([]);
    const [topEngagementPetitions, setTopEngagementPetitions] = useState([]);
    const [recentRFEs, setRecentRFEs] = useState([]);
    const [recentUsers, setRecentUsers] = useState([]);
    const [recentMovements, setRecentMovements] = useState([]);
    const [recentMovementsPerPetition, setRecentMovementsPerPetition] = useState([]);
    const [recentExhibits, setRecentExhibits] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // setLoading(true);
            try {
                const petitions = await fetchRecentPetitions();
                setRecentPetitions(petitions);

                const topEngagementPetitions = await fetchTopEngagementPetitions();
                setTopEngagementPetitions(topEngagementPetitions);

                const rfes = await fetchRecentRFEs();
                setRecentRFEs(rfes);

                const users = await fetchRecentUsers();
                setRecentUsers(users);

                const movementsPerPetition = await fetchRecentMovementsPerPetition();
                setRecentMovementsPerPetition(movementsPerPetition);

                const movements = await fetchRecentMovements();
                setRecentMovements(movements);

                // const exhibits = await fetchRecentExhibits();
                // setRecentExhibits(exhibits);
            } catch (err) {
                setError(err.message);
            } finally {
                // setLoading(false);
            }
        }
        fetchData();
    }, []);

    if (loading) return <div>Loading data...</div>;
    if (error) return <div>Error loading data: {error}</div>;

    return (
        <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">
            <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                    <Header />
                    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                        <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                <div className="d-flex flex-stack flex-row-fluid">
                                    <div className="d-flex flex-column flex-row-fluid">
                                        <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                            <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                                <span href="#" className="text-white text-hover-primary">
                                                    <i className="ki-outline ki-graph-3 text-gray-700 fs-6"></i>
                                                </span>
                                            </li>
                                        </ul>
                                        <div className="page-title d-flex align-items-center me-3">
                                            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">Admin Analytics</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-container container-xxl d-flex">
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                <div className="d-flex flex-column flex-column-fluid">
                                    <div id="kt_app_content" className="app-content">
                                        <div className="row g-5 g-xxl-10">

                                            {/* Table for Last Created Petitions */}
                                            <div className="col-12">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Last Created Petitions</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-20px"></th>
                                                                        <th className="min-w-150px">User</th>
                                                                        <th className="min-w-150px">Petitioner</th>
                                                                        <th className="min-w-100px">State</th>
                                                                        <th className="min-w-150px">Industry</th>
                                                                        <th className="min-w-150px">Subcategory</th>
                                                                        <th className="min-w-150px">Engagement</th>
                                                                        <th className="min-w-150px">Created At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentPetitions.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-50px me-3">
                                                                                        <img src={item.user?.photo} className="" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="apps/projects/users.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.user?.name}</a>
                                                                                    <span className="text-gray-500 fw-semibold d-block fs-7">{item.user?.email}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.petitionerName}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.stateOfEndeavor}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.industry}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.subCategory}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.engagementPercentile}%
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.createdAt)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Recent Movements */}
                                            <div className="col-12">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Recent Movements</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-20px"></th>
                                                                        <th className="min-w-150px">User</th>
                                                                        <th className="min-w-150px">PetitionerName</th>
                                                                        <th className="min-w-150px">CreatedAt</th>
                                                                        <th className="min-w-150px">EngagementPercentile</th>
                                                                        <th className="min-w-150px">Action</th>
                                                                        <th className="min-w-150px">Timestamp</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentMovements.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-50px me-3">
                                                                                        <img src={item.user?.photo} className="" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="apps/projects/users.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.user?.name}</a>
                                                                                    <span className="text-gray-500 fw-semibold d-block fs-7">{item.user?.email}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.petition.petitionerName}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.petition.createdAt)}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.petition.engagementPercentile}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.log.action}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.log.timestamp)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Recent Movements per Petition */}
                                            <div className="col-12">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Recent Movements Per Petition</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-20px"></th>
                                                                        <th className="min-w-150px">User</th>
                                                                        <th className="min-w-150px">PetitionerName</th>
                                                                        <th className="min-w-150px">CreatedAt</th>
                                                                        <th className="min-w-150px">EngagementPercentile</th>
                                                                        <th className="min-w-150px">Action</th>
                                                                        <th className="min-w-150px">Timestamp</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentMovementsPerPetition.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-50px me-3">
                                                                                        <img src={item.user?.photo} className="" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="apps/projects/users.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.user?.name}</a>
                                                                                    <span className="text-gray-500 fw-semibold d-block fs-7">{item.user?.email}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.petition.petitionerName}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.petition.createdAt)}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.petition.engagementPercentile}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {item.log.action}
                                                                            </td>
                                                                            <td className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.log.timestamp)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Top Engagement Petitions */}
                                            <div className="col-12">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Top Engagement Petitions</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-20px"></th>
                                                                        <th className="min-w-150px">User</th>
                                                                        <th className="min-w-150px">Petitioner</th>
                                                                        <th className="min-w-100px">Petition</th>
                                                                        <th className="min-w-100px">Engagement</th>
                                                                        <th className="min-w-150px">Created At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {topEngagementPetitions.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-50px me-3">
                                                                                        <img src={item.user.photo} className="" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="apps/projects/users.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.user.name}</a>
                                                                                    <span className="text-gray-500 fw-semibold d-block fs-7">{item.user.email}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.petition.petitionerName}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.petition.id}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.petition.engagementPercentile}%
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.petition.createdAt)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Recent RFEs Sent */}
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Recent RFEs Sent</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-150px">File Name</th>
                                                                        <th className="min-w-150px">Created At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentRFEs.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.fileName}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.createdAt)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Recent Users */}
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Recent Users</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-20px"></th>
                                                                        <th className="min-w-150px">User</th>
                                                                        <th className="min-w-150px">Created At</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentUsers.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="symbol symbol-50px me-3">
                                                                                        <img src={item.Photo} className="" alt="" />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                <div className="d-flex justify-content-start flex-column">
                                                                                    <a href="apps/projects/users.html" className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{item.Name}</a>
                                                                                    <span className="text-gray-500 fw-semibold d-block fs-7">{item.Email}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {formatDate(item.createdAt)}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Table for Recent Exhibits */}
                                            <div className="col-sm-12 col-md-12 col-lg-6">
                                                <div className="card card-flush h-xl-100">
                                                    <div className="card-header pt-5">
                                                        <h3 className="card-title align-items-start flex-column">
                                                            <span className="card-label fw-bold text-gray-800">Recent Exhibits</span>
                                                        </h3>
                                                    </div>
                                                    <div className="card-body py-3">
                                                        <div className="table-responsive">
                                                            <table className="table table-row-dashed align-middle gs-0 gy-4">
                                                                <thead>
                                                                    <tr className="fs-7 fw-bold border-0 text-gray-500">
                                                                        <th className="min-w-150px">ExhibitId</th>
                                                                        <th className="min-w-150px">CreatedAt</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {recentExhibits.map((item, idx) => (
                                                                        <tr key={idx}>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.exhibitid}
                                                                            </td>
                                                                            <td className="text-gray-800 text-hover-primary mb-1 fs-6">
                                                                                {item.createdat}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Footer />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminPage;
