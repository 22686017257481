import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

function CommunityCreatePost({ show, handleClose, handleSave }) {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [hashtags, setHashtags] = useState('');

    const categories = [
        "Eligibility & Requirements",
        "Documentation & Evidence",
        "Writing the Petition",
        "RFE Responses",
        "Case Processing & Timelines",
        "Success Stories & Experiences"
    ];

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevent the default form submission behavior
        const hashtagArray = hashtags.trim().length == 0 ? [] : hashtags.split(' ');
        handleSave(selectedCategory, subject, content, hashtagArray);
        setSelectedCategory('');
        setSubject('');
        setContent('');
        setHashtags('');
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a Post</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group controlId="formCategory">
                        <Form.Label className="required">Category</Form.Label>
                        <select required value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className='form-select form-select-lg form-select-solid'>
                            <option value="" disabled>Select a category...</option>
                            {categories.map((category, index) => (
                                <option key={index} value={category}>{category}</option>
                            ))}
                        </select>
                    </Form.Group>
                    <Form.Group controlId="formSubject" className="mt-3">
                        <Form.Label className="required">Subject</Form.Label>
                        <input type="text" required className="form-control form-control form-control-solid" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Type the subject..." />
                    </Form.Group>
                    <Form.Group controlId="formContent" className="mt-3">
                        <Form.Label className="required">Content</Form.Label>
                        <textarea rows={3} required className="form-control form-control form-control-solid" value={content} onChange={(e) => setContent(e.target.value)} placeholder="Type your message..." />
                    </Form.Group>
                    <Form.Group controlId="formHashtags" className="mt-3">
                        <Form.Label>Hashtags</Form.Label>
                        <input type="text" className="form-control form-control-lg form-control-solid" value={hashtags} onChange={(e) => setHashtags(e.target.value)} placeholder="Add hashtags separated by space..." />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={handleClose}>
                        Close
                    </span>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default CommunityCreatePost;
