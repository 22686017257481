import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { signInWithGoogle } from '../../services/authFunctions';
import Modal from "react-bootstrap/Modal";

const Login = () => {
    const navigate = useNavigate();
    const { login, startLogin, endLogin, logout } = useAuth(); // Use the login, startLogin, endLogin, and logout methods from context

    // const [modalPermissionIsOpen, setModalPermissionIsOpen] = React.useState(false);
    const [modalTermsIsOpen, setModalTermsIsOpen] = React.useState(false);
    const [modalPrivacyIsOpen, setModalPrivacyIsOpen] = React.useState(false);

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, []);

    // function openModalPermission() {
    //     setModalPermissionIsOpen(true);
    // }

    // function closeModalPermission() {
    //     setModalPermissionIsOpen(false);
    // }

    function openModalPrivacy() {
        setModalPrivacyIsOpen(true);
    }

    function closeModalPrivacy() {
        setModalPrivacyIsOpen(false);
    }

    function openModalTerms() {
        setModalTermsIsOpen(true);
    }

    function closeModalTerms() {
        setModalTermsIsOpen(false);
    }

    const handleGoogleSignIn = async () => {
        startLogin(); // Indicate login process started
        try {
            const { success, user } = await signInWithGoogle(logout);
            if (success) {
                login(user); // Use the login method from context
                navigate('/home');
            } else {
                throw new Error('Login failed');
            }
        } catch (error) {
            // openModalPermission();
            console.error("Failed to sign in with Google", error);
        } finally {
            endLogin(); // Indicate login process ended
        }
    };

    const termsOfUseHTML = `
    <p>Welcome to Visa Genius! By accessing or using our application and services (the "Service"), you agree to be bound by these Terms of Use ("Terms"). Please read them carefully before using the Service. If you do not agree with these Terms, please do not use our Service.</p>
    <h3>1. Introduction</h3>
    <p>Visa Genius ("we," "us," or "our") offers a platform that helps users create and manage their US immigration petitions, providing tools for document generation, organization, and case management. These Terms apply to all users of the Service.</p>
    <h3>2. Acceptance of Terms</h3>
    <p>By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree, you may not use the Service.</p>
    <h3>3. User Accounts</h3>
    <p>You must create an account to use certain features of the Service. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. Please notify us immediately of any unauthorized use of your account.</p>
    <h3>4. User Responsibilities</h3>
    <p>You agree not to use the Service for any illegal or unauthorized purposes. You agree to comply with all applicable laws and regulations. You agree not to upload or share content through the Service that is false, misleading, defamatory, or infringing on others' rights.</p>
    <h3>5. Intellectual Property</h3>
    <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Visa Genius and its licensors. You may not reproduce, distribute, modify, create derivative works of, publicly display, or otherwise exploit any of our content without prior written permission.</p>
    <h3>6. Termination</h3>
    <p>We reserve the right to suspend or terminate your account and access to the Service at our discretion, without notice, if you violate these Terms or engage in activities that we deem harmful to the Service or other users. Upon termination, your right to use the Service will cease immediately. If you wish to terminate your account, you may simply discontinue using the Service.</p>
    <h3>7. Limitation of Liability</h3>
    <p>To the maximum extent permitted by applicable law, Visa Genius shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p>
    <ul>
        <li>Your use or inability to use the Service;</li>
        <li>Any unauthorized access or use of our servers and/or any personal information stored therein;</li>
        <li>Any errors, mistakes, or inaccuracies of content.</li>
    </ul>
    <h3>8. Changes to the Terms</h3>
    <p>We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Service following the posting of changes will constitute your acceptance of those changes.</p>
    <h3>9. Contact Us</h3>
    <p>If you have any questions about these Terms, please contact us using the Need Help? button inside the platform.</p>
`;

    const privacyPolicyHTML = `
    <p>Visa Genius ("we," "us," or "our") respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our platform and services (the "Service"). By using the Service, you agree to the terms of this Privacy Policy.</p>
    <h3>1. Information We Collect</h3>
    <p>We collect some types of information, including personal information, Google Account Information, and more. The data we collect helps us improve our Service and provide better support to users.</p>
    <ul>
        <li><strong>Personal Information:</strong> When you create an account or use our Service, we collect personal information such as your name, email address and any other public information you provide.</li>
        <li><strong>Usage Data:</strong> We may collect data about how you access and use the Service, including your interactions with the platform, device information, IP address, and browser type.</li>
    </ul>
    <h3>2. How We Use Your Information</h3>
    <p>We use your information to create, manage, and improve your experience with the Service, including generating and organizing documents related to your petition. We may also use your email address to send you updates, notifications, and information about your account or our Service.</p>
    <h3>3. How We Protect Your Information</h3>
    <p>We implement appropriate security measures to protect your data against unauthorized access, alteration, disclosure, or destruction. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee absolute security.</p>
    <h3>4. Data Sharing and Disclosure</h3>
    <ul>
        <li><strong>Third-Party Services:</strong> We do not share your personal information with third parties except when it is necessary to provide our Service, comply with the law, or protect our rights.</li>
        <li><strong>Service Providers:</strong> We may use third-party services (e.g., cloud storage providers, analytics tools) that have limited access to your information strictly for the purpose of performing tasks on our behalf.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law, such as in response to a subpoena or other legal process, or if we believe such action is necessary to comply with applicable laws.</li>
    </ul>
    <h3>5. Cookies and Tracking Technologies</h3>
    <p>Our platform may use cookies and similar tracking technologies to improve user experience, analyze usage, and customize content. You can control the use of cookies through your browser settings, but some features of the Service may not function properly if cookies are disabled.</p>
    <h3>6. Retention of Data</h3>
    <p>We retain your personal information only for as long as is necessary to fulfill the purposes outlined in this Privacy Policy, comply with our legal obligations, and resolve any disputes. If you terminate your account, we will delete your personal data, except where retention is required by law.</p>
    <h3>7. Children’s Privacy</h3>
    <p>Our Service is not intended for use by children under the age of 13. We do not knowingly collect personal information from children. If we learn that we have collected data from a child under 13, we will take steps to delete the information as quickly as possible.</p>
    <h3>8. Changes to this Privacy Policy</h3>
    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is your responsibility to review this Privacy Policy periodically for any changes. Your continued use of the Service after changes are posted will constitute your acknowledgment of the modified Privacy Policy.</p>
    <h3>9. Contact Us</h3>
    <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us using the Need Help? button inside the platform.</p>
`;

    return (
        <>
            {/* Modal for Permission Explanation */}
            {/* <Modal show={modalPermissionIsOpen} onHide={closeModalPermission} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Why We Need Access to Your Google Drive</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y">
                        <div className="d-flex flex-column">
                            <div className="w-100">
                                <div className="mb-10 mb-lg-15">
                                    <div className="fs-6">
                                        <p>To provide you with our full range of features, Visa Genius requires permission to create file in your Google Drive. Here's what we use this access for and how it benefits you:</p>
                                        <ul>
                                            <li><strong>File Management:</strong> We create folders in your Google Drive to organize exhibits and files related to your petitions. This helps keep your data structured and easy to navigate.</li>
                                            <li><strong>Document Creation and Storage:</strong> We generate documents and store them securely in your Drive. These could include cover letter, recommendation letters and any other document you ask Visa Genius to create.</li>
                                            <li><strong>Direct Uploads:</strong> To save you time, Visa Genius allows you to upload files directly to these folders. This feature is designed to streamline how you manage and interact with your petition's exhibits through our platform.</li>
                                        </ul>
                                        <p>We request these permissions only to enhance the functionality and efficiency of the services we provide you. Your data remains under your control, and we ensure it is handled with the highest standards of security and confidentiality.</p>
                                        <p>You don't need to worry, as the permission required won't allow Visa Genius to access any files other than those uploaded or created directly by our app.</p>
                                        <p><strong>Please grant us permission to ensure you get the most out of our app. Thank you for your trust and cooperation!</strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPermission}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal> */}

            {/* Modal for Terms of Use */}
            <Modal show={modalTermsIsOpen} onHide={closeModalTerms} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms of Use</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y" dangerouslySetInnerHTML={{ __html: termsOfUseHTML }} />
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalTerms}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal>

            {/* Modal for Privacy Policy */}
            <Modal show={modalPrivacyIsOpen} onHide={closeModalPrivacy} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y" dangerouslySetInnerHTML={{ __html: privacyPolicyHTML }} />
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPrivacy}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal>

            <div className="d-flex flex-column flex-root">
                <style>{`body { background-image: url('/assets/media/auth/bg10.jpeg'); }`}</style>
                <div className="d-flex flex-column flex-lg-row flex-column-fluid">
                    <div className="d-flex flex-column flex-center pb-0 pb-lg-10 p-10 w-100">
                        <img src="/assets/media/auth/agency.png" alt="" className="mx-auto mw-100 w-150px w-lg-300px mb-10 mb-lg-20" />
                        <h1 className="text-primary fs-2qx fw-light text-center mb-7">Easily create powerful US immigration petitions!</h1>
                        <div className="text-gray-600 fs-base text-center fw-semibold">
                            {/* Some descriptive text here */}
                        </div>
                    </div>
                    <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12">
                        <div className="bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10">
                            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px">
                                <div className="form w-100">
                                    <div className="text-center mb-11 text-primary">
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <span style={{
                                                fontWeight: 500,
                                                fontSize: '2.5em',
                                                letterSpacing: '0.05em'
                                            }}>Visa</span><span style={{
                                                fontWeight: 100,
                                                fontSize: '2.5em',
                                                letterSpacing: '0.05em'
                                            }}>Genius</span>
                                        </div>
                                        <br />
                                        <h1 className="text-gray-700 fw-bolder mb-3">Sign In</h1>
                                        <div className="text-gray-500 fw-semibold fs-6">Boost Your Petition Success</div>
                                    </div>
                                    <div className="row g-3 mb-9">
                                        <div className="col-md-12">
                                            <button onClick={handleGoogleSignIn} className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
                                                <img alt="Google Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</button>
                                        </div>
                                    </div>
                                    <div className="row g-3 mb-9">
                                        <div className="col-md-12">
                                            <div className="d-flex justify-content-center">
                                                <span className="text-gray-600" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openModalTerms}>
                                                    Terms of Use
                                                </span>
                                                &nbsp;|&nbsp;
                                                <span className="text-gray-600" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openModalPrivacy}>
                                                    Privacy Policy
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
