import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../config/firebase-config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true); // Added loading state
    const [loginInProgress, setLoginInProgress] = useState(false); // Track login process
    const db = getFirestore();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser && !loginInProgress) { // Only fetch user if not in login process
                const userRef = doc(db, "Users", firebaseUser.uid);
                const docSnap = await getDoc(userRef);

                const userForApp = {
                    Name: firebaseUser.displayName,
                    Email: firebaseUser.email,
                    Photo: firebaseUser.photoURL,
                    ProviderId: firebaseUser.providerData[0].providerId,
                    uid: firebaseUser.uid,
                    BaseFolderId: docSnap.exists() ? docSnap.data().BaseFolderId : null,
                    ProfileType: docSnap.exists() ? docSnap.data().profileType : null
                };
                setCurrentUser(userForApp);
            } else {
                setCurrentUser(null);
            }
            setLoading(false); // Set loading to false once auth state is determined
        });

        return () => unsubscribe();
    }, [loginInProgress]);

    const logout = async () => {
        try {
            await signOut(auth);
            setCurrentUser(null);
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    const login = (user) => {
        setCurrentUser(user);
    };

    const startLogin = () => {
        setLoginInProgress(true); // Set login in progress to true
    };

    const endLogin = () => {
        setLoginInProgress(false); // Set login in progress to false
    };

    const value = {
        currentUser,
        setCurrentUser,
        login,
        logout,
        startLogin,
        endLogin,
        loading, // Expose loading state
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
