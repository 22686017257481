// axiosConfig.js
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const aiapi = axios.create({
    baseURL: process.env.REACT_APP_AI_API_URL
});

const auth = getAuth();

const setAuthHeaders = async () => {
    const user = auth.currentUser;
    if (user) {
        const token = await user.getIdToken();
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        aiapi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        api.defaults.headers.common['Authorization'] = null;
        aiapi.defaults.headers.common['Authorization'] = null;
    }
};

// Set headers initially on authentication state change
onAuthStateChanged(auth, (user) => {
    if (user) {
        setAuthHeaders();
    } else {
        api.defaults.headers.common['Authorization'] = null;
        aiapi.defaults.headers.common['Authorization'] = null;
    }
});

// Handle expired token in response interceptor
api.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401) {
            try {
                // Attempt to refresh the token
                const user = auth.currentUser;
                if (user) {
                    const token = await user.getIdToken(true); // `true` forces refresh
                    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    error.config.headers['Authorization'] = `Bearer ${token}`;
                    // Retry the request with the refreshed token
                    return axios(error.config);
                }
            } catch (tokenError) {
                console.error('Token refresh error:', tokenError);
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

aiapi.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401) {
            try {
                const user = auth.currentUser;
                if (user) {
                    const token = await user.getIdToken(true); // `true` forces refresh
                    aiapi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    error.config.headers['Authorization'] = `Bearer ${token}`;
                    // Retry the request with the refreshed token
                    return axios(error.config);
                }
            } catch (tokenError) {
                console.error('Token refresh error:', tokenError);
                window.location.href = '/login';
            }
        }
        return Promise.reject(error);
    }
);

export { api, aiapi };
