import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dropdown, Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone';
import { useParams, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import { formatDate, generateColorFromName, isOverdue, formatFileSize } from '../../utils/Utils.js'
import { fetchPetition, fetchRequest, changeRequestStatus, deleteRequest, createReply, fetchReplies, fetchTimeline, addRequestFile, fetchRequestFiles, deleteRequestFile } from '../../services/ApiService.js';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import Loading from '../../components/Loading/Loading.js';

function RequestDetail({ type }) {

    const { currentUser } = useAuth();

    const { petitionId, requestId } = useParams(); // Get the file ID from URL parameters
    const [request, setRequest] = useState(null);
    const [petition, setPetition] = useState(null);
    const [loading, setLoading] = useState(true);
    const [replies, setReplies] = useState([]);
    const [replyContent, setReplyContent] = useState(""); // Store the reply content
    const [timeline, setTimeline] = useState([]);  // State to store the timeline entries
    const [files, setFiles] = useState([]); // Store the uploaded files
    const [uploading, setUploading] = useState(false); // Track the loading state
    const [error, setError] = useState(null); // Track any errors

    useEffect(() => {
        async function fetchData() {
            await fetchPetitionCall(petitionId);
            await fetchRequestCall(petitionId, requestId);
            await fetchRequestFilesCall(petitionId, requestId); // Fetch files
            setLoading(false);
        }

        fetchData();
    }, [petitionId, requestId, type]);

    useEffect(() => {
        async function fetchData() {
            const replies = await fetchReplies(petitionId, requestId, type);
            setReplies(replies);
        }

        fetchData();
    }, [petitionId, requestId, type]);

    useEffect(() => {
        if (requestId && petitionId) {
            loadTimeline();
        }
    }, [requestId, petitionId]);  // Reload timeline when requestId or petitionId changes

    const loadTimeline = async () => {
        try {
            const fetchedTimeline = await fetchTimeline(petitionId, requestId, type);
            setTimeline(fetchedTimeline);
        } catch (error) {
            console.error('Failed to load timeline:', error);
        }
    };

    // Fetch files function
    const fetchRequestFilesCall = async (petitionId, requestId) => {
        try {
            const fetchedFiles = await fetchRequestFiles(petitionId, requestId, type); // Call the API function to get files
            console.log(fetchedFiles);
            setFiles(fetchedFiles); // Update files state with the fetched files
        } catch (error) {
            console.error('Failed to fetch files:', error);
        }
    };

    // Function to fetch the replies
    async function fetchRepliesCall(petitionId, requestId) {
        try {
            const fetchedReplies = await fetchReplies(petitionId, requestId, type);
            setReplies(fetchedReplies);
        } catch (error) {
            console.error('Error fetching replies:', error);
        }
    }

    async function fetchPetitionCall(petitionId) {
        fetchPetition(petitionId)
            .then((result) => {
                setPetition(result);
                return result.id;
            })
            .catch((error) => {
                console.error('Error fetching petition:', error);
                return null;
            });
    }

    async function fetchRequestCall(petitionId, requestId) {
        fetchRequest(petitionId, requestId, type)
            .then((result) => {
                setRequest(result);
                return result.id;
            })
            .catch((error) => {
                console.error('Error fetching request:', error);
                return null;
            });
    }

    // Change request status
    const handleRequestStatusChange = async (id, newStatus) => {
        // Save the current status to revert in case of an error
        const previousStatus = request.status;

        // Optimistically update the status in local state
        setRequest((prevRequest) => ({ ...prevRequest, status: newStatus }));

        try {
            setLoading(true);
            await changeRequestStatus(petitionId, id, newStatus, type);
            setLoading(false);

            // After successfully changing the status, fetch the updated timeline
            await loadTimeline();

        } catch (error) {
            setLoading(false);
            console.error('Failed to update request status:', error);

            // If there's an error, revert the status back to its previous state
            setRequest((prevRequest) => ({ ...prevRequest, status: previousStatus }));
        }
    };

    // Delete request
    const handleDeleteRequest = async (id) => {
        try {
            setLoading(true);
            await deleteRequest(petitionId, id, type);
            // await loadRequests(); // Re-fetch requests to ensure correct sequence numbers
            setLoading(false);

        } catch (error) {
            setLoading(false);
            console.error('Failed to delete request:', error);
        }
    };

    const handleSendReply = async () => {
        if (!replyContent.trim()) {
            alert('Reply content cannot be empty.');
            return;
        }

        const optimisticReply = {
            id: Math.random().toString(36).substr(2, 9), // Temporary ID for optimistic reply
            author: {
                name: currentUser.displayName,
                email: currentUser.email,
                photo: currentUser.photoURL,
                userId: currentUser.uid
            },
            content: replyContent,
        };

        // Optimistically update the UI with the new reply
        setReplies([...replies, optimisticReply]);

        // Clear the textarea
        setReplyContent('');

        try {
            setLoading(true);
            // Call the API to create the reply
            const createdReply = await createReply(petitionId, requestId, optimisticReply, type);

            // Replace optimistic reply with the actual one returned by the server
            setReplies((prevReplies) =>
                prevReplies.map(reply => reply.id === optimisticReply.id ? createdReply : reply)
            );

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Failed to send reply:', error);

            // Remove the optimistic reply in case of an error
            setReplies((prevReplies) =>
                prevReplies.filter(reply => reply.id !== optimisticReply.id)
            );
        }
    };

    // Handle file drop/upload
    const onDrop = useCallback(async (acceptedFiles) => {
        setUploading(true);
        setError(null);

        try {
            const uploadedFiles = [];

            for (const file of acceptedFiles) {
                console.log(file);
                const response = await addRequestFile(file, petitionId, requestId, petition?.folderId, type);
                uploadedFiles.push({ fileName: file.name, size: (file.size / 1024).toFixed(2), ...response });
                console.log(uploadedFiles);
            }

            // Update the state with uploaded files
            setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);

        } catch (error) {
            setError('Failed to upload file(s). Please try again.');
            console.error(error);
        } finally {
            setUploading(false);
        }
    }, [petitionId, requestId, petition, type]);

    // Use Dropzone for file drag & drop
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: true,
        // accept: '*' // Adjust file types as needed
    });

    // Render the list of uploaded files with their names, sizes, and a delete button
    const renderUploadedFiles = () => {
        if (files.length === 0) {
            return <p className="text-muted">No files uploaded yet.</p>;
        }

        return (
            <ul className="list-group">
                {files.map((file, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                        <div
                            className="file-name text-truncate cursor-pointer"
                            style={{ maxWidth: '50%' }}
                            title={file.fileName} // Tooltip to show full file name on hover
                        >
                            <a
                                href={file.isFirestore
                                    ? file.storageUrl // Use Firebase Storage URL if isFirestore is true
                                    : `https://drive.google.com/file/d/${file.googleDocId}/view`} // Otherwise, use Google Drive URL
                                target="_blank"
                                rel="noopener noreferrer">
                                <span className="text-primary fs-6 d-block">{file.fileName}</span>
                            </a>
                            {/* <a
                                href={file.googleDocId ? `https://drive.google.com/file/d/${file.googleDocId}/view` : '#'}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-primary"
                            >
                                {file.fileName}
                            </a> */}
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-muted me-3">{formatFileSize(file.size)}</span>
                            <i
                                className="text-danger ki-outline ki-trash fs-6 cursor-pointer"
                                onClick={() => handleDeleteFile(index)}
                                title="Delete file" // Tooltip for the delete icon
                            ></i>
                        </div>
                    </li>
                ))
                }
            </ul >
        );
    };

    const handleDeleteFile = async (index) => {
        const fileToDelete = files[index]; // Get the file to be deleted
        try {
            await deleteRequestFile(fileToDelete.documentId, petitionId, requestId, type);

            // After successful deletion, update the file state
            const updatedFiles = files.filter((_, fileIndex) => fileIndex !== index);
            setFiles(updatedFiles); // Update the file state
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Failed to delete the file. Please try again.');
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <div id="kt_app_body" data-kt-app-header-fixed-mobile="true" data-kt-app-toolbar-enabled="true" className="app-default">

                {/* {/* <!--begin::Page--> */}
                <div className="app-page flex-column flex-column-fluid" id="kt_app_page">
                    {/* {/* <!--begin::Header--> */}
                    <Header />
                    {/* {/* <!--end::Header--> */}
                    {/* {/* <!--begin::Wrapper--> */}
                    <div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
                        {/* {/* <!--begin::Toolbar--> */}
                        <div id="kt_app_toolbar" className="app-toolbar pt-4 pt-lg-7 mb-n2 mb-lg-n3">
                            {/* {/* <!--begin::Toolbar container--> */}
                            <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack flex-row-fluid">
                                {/* {/* <!--begin::Toolbar container--> */}
                                {/* {/* <!--begin::Breadcrumb--> */}
                                <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-1 mb-lg-3 me-2 fs-7">
                                    {/* {/* <!--begin::Item--> */}
                                    <li className="breadcrumb-item text-gray-700 fw-bold lh-1">
                                        <Link to={`/`}>
                                            <span className="text-white text-hover-primary">
                                                <i className="ki-outline ki-home text-gray-700 fs-6"></i>
                                            </span>
                                        </Link>
                                    </li>
                                    {/* {/* <!--end::Item--> */}
                                    {/* {/* <!--begin::Item--> */}
                                    <li className="breadcrumb-item">
                                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                    </li>
                                    {/* {/* <!--end::Item--> */}
                                    {/* {/* <!--begin::Item--> */}
                                    <li className="breadcrumb-item text-gray-700 fw-bold lh-1"><Link to={`/petition/${petitionId}`}>{petition?.petitionerName}</Link></li>
                                    {/* {/* <!--end::Item--> */}
                                    {/* {/* <!--begin::Item--> */}
                                    <li className="breadcrumb-item">
                                        <i className="ki-outline ki-right fs-7 text-gray-700 mx-n1"></i>
                                    </li>
                                    {/* {/* <!--end::Item--> */}
                                    {/* {/* <!--begin::Item--> */}
                                    <li className="breadcrumb-item text-gray-900 fs-4 fw-bold lh-1">{request?.subject}</li>
                                    {/* {/* <!--end::Item--> */}
                                </ul>
                                {/* {/* <!--end::Breadcrumb--> */}
                                {/* {/* <!--begin::Actions--> */}
                                {/* {/* <!--end::Actions--> */}
                            </div>
                            {/* {/* <!--end::Toolbar container--> */}
                        </div>
                        {/* {/* <!--end::Toolbar--> */}

                        {/* {/* <!--begin::Wrapper container--> */}
                        <div className="app-container container-xxl d-flex">
                            {/* {/* <!--begin::Main--> */}
                            {/* {/* <!--begin::Main--> */}
                            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                                {/* {/* <!--begin::Content wrapper--> */}
                                <div className="d-flex flex-column flex-column-fluid">
                                    {/* {/* <!--begin::Content--> */}
                                    <div id="kt_app_content" className="app-content">
                                        {/* <!--begin::Content--> */}
                                        <div className="flex-lg-row-fluid">
                                            <div className="row">
                                                <div className='col-12 col-md-6 col-lg-3'>
                                                    {/* Request Timeline Card */}
                                                    <div className="card mb-5">
                                                        {/* <div className="card-header pt-7">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="card-label fw-bold text-primary">Request Timeline</span>
                                                            </h3>
                                                        </div> */}
                                                        <div className="card-body">
                                                            {/* <!--begin::Button--> */}
                                                            <div {...getRootProps({ className: 'dropzone border p-3 mb-3 text-center' })}>
                                                                <input {...getInputProps()} />
                                                                {uploading ? (
                                                                    <Button variant="primary" disabled>
                                                                        <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                                                        Uploading...
                                                                    </Button>
                                                                ) : (
                                                                    <Button variant="primary" className="w-100">Upload File</Button>
                                                                )}
                                                            </div>

                                                            {error && <p className="text-danger">{error}</p>}

                                                            {renderUploadedFiles()}

                                                            {/* <!--end::Button--> */}
                                                            <div className="separator my-5"></div>

                                                            <h3 className="card-title align-items-start flex-column mb-5">
                                                                <span className="card-label fw-bold text-primary">Request Timeline</span>
                                                            </h3>

                                                            {timeline.length > 0 ? (
                                                                <div className="timeline">
                                                                    {timeline.map((entry, index) => (
                                                                        <div key={entry.id} className="timeline-item mb-7">
                                                                            {/* Timeline Icon */}
                                                                            <div className="timeline-icon">
                                                                                <i className="ki-outline ki-calendar fs-2 text-primary"></i>
                                                                            </div>
                                                                            {/* Timeline Content */}
                                                                            <div className="timeline-content">
                                                                                <span className="fw-bold text-gray-900">{entry.description}</span>
                                                                                <div className="text-muted fw-semibold">{entry.author.name}</div>
                                                                                <div className="fw-semibold text-muted">{formatDate(entry.date, false)}</div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            ) : (
                                                                <div className="text-center text-muted">
                                                                    No timeline entries available.
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md-6 col-lg-9'>
                                                    {/* <!--begin::Card--> */}
                                                    <div className="card">
                                                        <div className="card-header align-items-center py-5 gap-5">
                                                            {/* <!--begin::Actions--> */}
                                                            <div className="d-flex">
                                                                {/* <!--begin::Back--> */}
                                                                {/* <a href="apps/inbox/listing.html" className="btn btn-sm btn-icon btn-clear btn-active-light-primary me-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Back"> */}
                                                                {/* <i className="ki-outline ki-arrow-left fs-1 m-0"></i> */}
                                                                {/* </a> */}

                                                                {/* <!--begin::User--> */}
                                                                <div className="d-flex flex-column">
                                                                    {/* <!--begin::Subject--> */}
                                                                    <div className="d-flex align-items-center mb-2">
                                                                        <a href="#" className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{request?.subject}</a><br />
                                                                    </div>
                                                                    {/* <!--end::Subject--> */}
                                                                    {/* <!--begin::Tags--> */}
                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                                        {request?.hashtags?.map((hashtag, hashtagindex) => (
                                                                            <span key={hashtag} className="badge badge-light-primary my-1 me-2">{hashtag}</span>
                                                                        ))}
                                                                    </div>
                                                                    {/* <!--end::Tags--> */}
                                                                    {/* <!--begin::Info--> */}
                                                                    <div className="d-flex flex-wrap fw-semibold fs-6 pe-2  ">

                                                                        <span className={`badge py-3 fs-7 badge-light-info me-2`}>
                                                                            {request?.requestType}
                                                                        </span>

                                                                        <span className={`badge me-3 fs-7 badge-light-${request?.priority === 'High' ? 'danger' : request?.priority === 'Medium' ? 'warning' : 'success'}`}>
                                                                            {request?.priority}
                                                                        </span>

                                                                        {request?.duedate && (
                                                                            <a href="#" className={`d-flex align-items-center text-hover-primary me-5 mb-2 ${isOverdue(request?.duedate) ? 'text-danger' : 'text-gray-500'}`}>
                                                                                <i className={`ki-outline ki-calendar fs-4 me-1 ${isOverdue(request?.duedate) ? 'text-danger' : 'text-gray-500'}`}></i>
                                                                                {formatDate(request?.duedate, true)}
                                                                            </a>
                                                                        )}
                                                                    </div>

                                                                    {/* <!--end::Info--> */}
                                                                </div>
                                                                {/* <!--end::User--> */}



                                                                {/* <!--end::Back--> */}
                                                            </div>
                                                            {/* <!--end::Actions--> */}
                                                            {/* <!--begin::Pagination--> */}
                                                            <div className="d-flex align-items-center">
                                                                {/* <!--begin::Status--> */}
                                                                {/* <a href="#" className="btn btn-sm btn-icon btn-light btn-active-light-primary me-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Next message"> */}
                                                                <span className={`badge me-3 py-3 px-4 fs-7 badge-light-${request?.status === 'Done' ? 'success' : request?.status === 'In Progress' ? 'primary' : 'danger'}`}>
                                                                    {request?.status}
                                                                </span>
                                                                {/* <!--end::Status--> */}
                                                                {/* <!--begin::Toggle--> */}
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" size="sm" id="dropdown-basic"></Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        {request?.status !== 'Pending' && (
                                                                            <Dropdown.Item onClick={() => handleRequestStatusChange(request?.id, 'Pending')}>
                                                                                Mark as Pending
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        {request?.status !== 'In Progress' && (
                                                                            <Dropdown.Item onClick={() => handleRequestStatusChange(request?.id, 'In Progress')}>
                                                                                Mark as In Progress
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        {request?.status !== 'Done' && (
                                                                            <Dropdown.Item onClick={() => handleRequestStatusChange(request?.id, 'Done')}>
                                                                                Mark as Done
                                                                            </Dropdown.Item>
                                                                        )}
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item onClick={() => handleDeleteRequest(request?.id)} className="text-danger">
                                                                            Delete
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>

                                                                <a href="#" className="btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" title="Toggle inbox menu" id="kt_inbox_aside_toggle">
                                                                    <i className="ki-outline ki-burger-menu-2 fs-3 m-0"></i>
                                                                </a>
                                                                {/* <!--end::Toggle--> */}
                                                            </div>
                                                            {/* <!--end::Pagination--> */}
                                                        </div>
                                                        <div className="card-body">
                                                            {/* <!--begin::Message accordion--> */}
                                                            <div data-kt-inbox-message="message_wrapper">
                                                                {/* <!--begin::Message header--> */}
                                                                <div className="d-flex flex-wrap gap-2 flex-stack cursor-pointer" data-kt-inbox-message="header">
                                                                    {/* <!--begin::Author--> */}
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <!--begin::Avatar--> */}
                                                                        <div className="symbol symbol-50 me-4">
                                                                            {request?.author.photo ? (
                                                                                <img
                                                                                    src={request?.author.photo}
                                                                                    alt={request?.author.name}
                                                                                    className="symbol-label"
                                                                                />
                                                                            ) : (
                                                                                <div className="symbol-label" style={{ backgroundColor: generateColorFromName(request?.author.name) }}>
                                                                                    {request?.author.name.charAt(0).toUpperCase()}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {/* <div className="symbol symbol-50 me-4"> */}
                                                                        {/* <span className="symbol-label" style={{ backgroundImage: "url(${})" }}></span> */}
                                                                        {/* </div> */}
                                                                        {/* <!--end::Avatar--> */}
                                                                        <div className="pe-5">
                                                                            {/* <!--begin::Author details--> */}
                                                                            <div className="d-flex align-items-center flex-wrap gap-1">
                                                                                <a href="#" className="fw-bold text-gray-900 text-hover-primary">{request?.author.name}</a> <span className="text-muted fw-semibold">to {request?.assignee.name}</span>
                                                                                {/* <i className="ki-outline ki-abstract-8 fs-7 text-success mx-3"></i> */}
                                                                                {/* <span className="text-muted fw-bold">1 day ago</span> */}
                                                                            </div>
                                                                            {/* <!--begin::Request Date--> */}
                                                                            <span className="fw-semibold text-muted text-end">{formatDate(request?.createdAt, false)}</span>
                                                                            {/* <!--end::Request Date--> */}

                                                                            {/* <!--end::Author details--> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end::Author--> */}
                                                                </div>
                                                                {/* <!--end::Message header--> */}
                                                                {/* <!--begin::Message content--> */}
                                                                <div className="collapse fade show" data-kt-inbox-message="message">
                                                                    <div className="mt-5">
                                                                        <p>{request?.content || "-"}</p>
                                                                    </div>
                                                                </div>
                                                                {/* <!--end::Message content--> */}
                                                            </div>
                                                            {/* <!--end::Message accordion--> */}
                                                        </div>
                                                    </div>
                                                    {/* <!--end::Card--> */}

                                                    {/* <!--begin::Replies Section--> */}
                                                    {replies && replies.length > 0 && (
                                                        replies.map((reply, index) => (
                                                            <div key={reply.id} className="card mt-4">
                                                                <div className="card-body">
                                                                    <div className="d-flex align-items-center">
                                                                        {/* <!--begin::Avatar--> */}
                                                                        <div className="symbol symbol-50 me-4">
                                                                            {reply?.author.photo ? (
                                                                                <img
                                                                                    src={reply?.author.photo}
                                                                                    alt={reply?.author.name}
                                                                                    className="symbol-label"
                                                                                />
                                                                            ) : (
                                                                                <div className="symbol-label" style={{ backgroundColor: generateColorFromName(reply?.author.name) }}>
                                                                                    {reply?.author?.name?.charAt(0).toUpperCase()}
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        {/* <!--end::Avatar--> */}
                                                                        <div className="pe-5">
                                                                            {/* <!--begin::Author details--> */}
                                                                            <div className="d-flex align-items-center flex-wrap gap-1">
                                                                                <a href="#" className="fw-bold text-gray-900 text-hover-primary">{reply?.author.name}</a>
                                                                            </div>
                                                                            {/* <!--begin::Reply Date--> */}
                                                                            <span className="fw-semibold text-muted text-end">{formatDate(reply.createdAt, false) || 'just now'}</span>
                                                                            {/* <!--end::Reply Date--> */}
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--begin::Reply Content--> */}
                                                                    <div className="mt-5">
                                                                        <div className="reply-content prewrap">
                                                                            {reply?.content || "-"}
                                                                        </div>
                                                                    </div>
                                                                    {/* <!--end::Reply Content--> */}
                                                                </div>
                                                            </div>
                                                        ))
                                                    )}
                                                    {/* <!--end::Replies Section--> */}

                                                    <div className="card card-flush mb-10 mt-5">
                                                        <div className="card-body">
                                                            <div className="d-flex align-items-start mt-3 position-relative w-100">
                                                                <div className="symbol symbol-40px me-3">
                                                                    <img src={currentUser?.Photo} alt="" />
                                                                </div>
                                                                <div className="position-relative w-100">
                                                                    <textarea
                                                                        className="form-control form-control-solid border ps-5"
                                                                        rows="3"
                                                                        placeholder="Write some content..."
                                                                        value={replyContent} // Bind to reply content state
                                                                        onChange={(e) => setReplyContent(e.target.value)} // Update state on change
                                                                    ></textarea>

                                                                    <div className="d-flex flex-stack flex-wrap gap-2 py-5 pe-5 border-top">
                                                                        <div className="d-flex align-items-center me-3">
                                                                            <div className="btn-group me-4">
                                                                                <Button className="btn btn-sm btn-primary fs-bold px-6" onClick={handleSendReply}>
                                                                                    <span className="indicator-label">Send</span>
                                                                                </Button>
                                                                            </div>
                                                                            {/* <span className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2" id="kt_inbox_reply_attachments_select" data-kt-inbox-form="dropzone_upload"> */}
                                                                            {/* <i className="ki-outline ki-paper-clip fs-2 m-0"></i> */}
                                                                            {/* </span> */}

                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="d-flex flex-stack flex-wrap gap-2 py-5 pe-5 border-top">
                                                                        <div className="d-flex align-items-center me-3">
                                                                            <div className="btn-group me-4">
                                                                                <span className="btn btn-sm btn-primary fs-bold px-6" data-kt-inbox-form="send">
                                                                                    <span className="indicator-label">Send</span>
                                                                                    <span className="indicator-progress">Please wait...
                                                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                                </span>
                                                                            </div>
                                                                            <span className="btn btn-icon btn-sm btn-clean btn-active-light-primary me-2" id="kt_inbox_reply_attachments_select" data-kt-inbox-form="dropzone_upload">
                                                                                <i className="ki-outline ki-paper-clip fs-2 m-0"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div> */}

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <!--end::Content--> */}

                                    </div>
                                    {/* {/* <!--end::Content--> */}
                                </div>
                                {/* {/* <!--end::Content wrapper--> */}
                                {/* {/* <!--begin::Footer--> */}
                                <Footer />
                                {/* {/* <!--end::Footer--> */}
                            </div>
                            {/* {/* <!--end:::Main--> */}
                        </div>
                        {/* {/* <!--end::Wrapper container--> */}

                    </div>
                    {/* {/* <!--end::Wrapper--> */}
                </div>
                {/* {/* <!--end::Page--> */}
            </div >
        </>
    );
}

export default RequestDetail;