import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import Loading from '../Loading/Loading.js';
import { auth } from '../../config/firebase-config.js';
import { signOut } from 'firebase/auth';
import { useAuth } from '../../contexts/AuthContext.js';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal'; // Import Modal from react-bootstrap
import Button from 'react-bootstrap/Button'; // Import Button from react-bootstrap
import { sendContactForm } from '../../services/ApiService.js';
import './Header.css'; // Import for custom styles
import { getUserIp } from '../../utils/Utils.js';

function Header() {
    const [isHovered, setIsHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false); // State for side menu toggle
    const [modalIsOpen, setModalIsOpen] = useState(false); // For controlling the contact form modal
    const { currentUser } = useAuth();
    const { setCurrentUser } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [formStateContactForm, setFormStateContactForm] = useState({
        type: '',
        message: ''
    });

    // Check for user authentication and redirect if not logged in
    useEffect(() => {
        if (!currentUser) {
            navigate('/login', { state: { from: location }, replace: true });
        }
    }, [currentUser, navigate, location]);

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleLogout = () => {
        signOut(auth).then(() => {
            setCurrentUser(null);
            console.log("Logged out successfully!");
            navigate('/');
        }).catch((error) => {
            console.error("Error logging out:", error);
        });
    };

    const toggleSideMenu = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const closeSideMenu = () => {
        setIsSideMenuOpen(false);
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const clearContactForm = () => {
        setFormStateContactForm({
            type: '',
            message: ''
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormStateContactForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmitContactForm = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const ipAddress = await getUserIp(); // Fetch user's IP address
            const currentUrl = window.location.href; // Get the current URL

            await sendContactForm(formStateContactForm, currentUser.uid, currentUser.Name, currentUser.Email, currentUser.Photo, ipAddress, currentUrl)
                .then((result) => {
                    setLoading(false);
                    Swal.fire("Success", 'Message sent! Thank you for reaching out!', "success");
                    clearContactForm();
                    closeModal();
                })
                .catch((error) => {
                    setLoading(false);
                    Swal.fire("Error", 'An error occurred while sending message. Please try again.', "error");
                });

        } catch (error) {
            setLoading(false);
            console.error(error);
            Swal.fire("Error", 'An error occurred while sending message. Please try again.', "error");
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <div id="kt_app_header" className="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: false, lg: true}" data-kt-sticky-name="app-header-sticky" data-kt-sticky-offset="{default: false, lg: '300px'}">
                <div className="app-container container-xxl d-flex align-items-stretch justify-content-between" id="kt_app_header_container">
                    {/* Hamburger Icon for Mobile */}
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-2" title="Show sidebar menu">
                        <button className="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_header_menu_toggle" onClick={toggleSideMenu}>
                            <i className="ki-outline ki-abstract-14 fs-2"></i>
                        </button>
                    </div>
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-1 me-lg-13 text-danger">
                        <a href="/">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontWeight: 500, fontSize: '2.5em', letterSpacing: '0.05em' }}>Visa</span>
                                <span style={{ fontWeight: 100, fontSize: '2.5em', letterSpacing: '0.05em' }}>Genius</span>
                            </div>
                        </a>
                    </div>
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
                        <div className="d-flex align-items-stretch" id="kt_app_header_menu_wrapper">
                            <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                                <div className="menu menu-rounded menu-column menu-lg-row menu-active-bg menu-title-gray-600 menu-state-gray-900 menu-arrow-gray-500 fw-semibold fs-6 align-items-stretch my-5 my-lg-0 px-2 px-lg-0" data-kt-menu="true">
                                    <div className={` menu-item menu-lg-down-accordion me-0 me-lg-2 ${!location.pathname.startsWith('/analytics') && !location.pathname.startsWith('/community') ? 'here' : ''}`} onClick={() => handleNavigation('/')}>
                                        <span className="menu-link">
                                            <span className="menu-icon"><i className="ki-outline ki-home"></i></span>
                                            <span className="menu-title">Home</span>
                                        </span>
                                    </div>
                                    <div className={`menu-item menu-lg-down-accordion me-0 me-lg-2 ${location.pathname.startsWith('/analytics') ? 'here' : ''}`} onClick={() => handleNavigation('/analytics')}>
                                        <span className="menu-link">
                                            <span className="menu-icon"><i className="ki-outline ki-graph-3"></i></span>
                                            <span className="menu-title">Analytics</span>
                                        </span>
                                    </div>
                                    <div className={`menu-item menu-lg-down-accordion me-0 me-lg-2 ${location.pathname.startsWith('/community') ? 'here' : ''}`} onClick={() => handleNavigation('/community')}>
                                        <span className="menu-link">
                                            <span className="menu-icon"><i className="ki-outline ki-people"></i></span>
                                            <span className="menu-title">Community</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="app-navbar flex-shrink-0">
                            <div
                                className="app-navbar-item"
                                id="kt_header_user_menu_toggle"
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                style={{ position: 'relative' }} // This makes the dropdown relative to the user photo button
                            >
                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-2" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                                    <div className="cursor-pointer symbol me-3 symbol-35px symbol-lg-45px">
                                        <img src={currentUser?.Photo} alt="user" />
                                    </div>
                                    <div className="me-4">
                                        <span className="text-gray-900 text-hover-primary fs-6 fw-bold">{currentUser?.Name?.split(' ')[0]}</span>
                                    </div>
                                    <i className="ki-outline ki-down fs-2 text-gray-500 pt-1"></i>
                                </div>
                                {/* The dropdown menu */}
                                <div className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px ${isHovered ? 'show' : ''}`} data-kt-menu="true"
                                    style={{
                                        position: 'absolute',
                                        top: '100%',
                                        right: 0,
                                        visibility: isHovered ? 'visible' : 'hidden',
                                        transform: isHovered ? 'translateY(0)' : 'translateY(-10px)',
                                        opacity: isHovered ? 1 : 0,
                                        transition: 'visibility 0.3s, transform 0.3s, opacity 0.3s',
                                    }}>
                                    <div className="menu-item px-3">
                                        <div className="menu-content d-flex align-items-center px-3">
                                            <div className="symbol symbol-50px me-5">
                                                <img alt="Logo" src={currentUser?.Photo} />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <div className="fw-bold d-flex align-items-center fs-5">{currentUser?.Name?.split(' ')[0]}
                                                    <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Free</span></div>
                                                <span className="fw-semibold text-muted text-hover-primary fs-7">{currentUser?.Email}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator my-2"></div>
                                    <div className="menu-item px-5">
                                        <span href="#" onClick={handleLogout} className="menu-link px-5">Sign Out</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Side Menu for Mobile */}
            <div className={`side-menu ${isSideMenuOpen ? 'open' : ''}`}>
                <div className="side-menu-content">
                    <button className="close-btn" onClick={closeSideMenu}>&times;</button>
                    <div className="mt-10 menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary">
                        <div className="menu-item">
                            <span className={`menu-link ${!location.pathname.startsWith('/analytics') && !location.pathname.startsWith('/community') ? 'active' : ''}`} onClick={() => handleNavigation('/')}>
                                <span className="menu-icon">
                                    <i className={`ki-outline ki-home fs-2 me-3`}></i>
                                </span>
                                <span className="menu-title fw-bold fs-3">Home</span>
                            </span>
                        </div>
                        <div className="menu-item">
                            <span className={`menu-link ${location.pathname.startsWith('/analytics') ? 'active' : ''}`} onClick={() => handleNavigation('/analytics')}>
                                <span className="menu-icon">
                                    <i className={`ki-outline ki-graph-3 fs-2 me-3`}></i>
                                </span>
                                <span className="menu-title fw-bold fs-3">Analytics</span>
                            </span>
                        </div>
                        <div className="menu-item">
                            <span className={`menu-link ${location.pathname.startsWith('/community') ? 'active' : ''}`} onClick={() => handleNavigation('/community')}>
                                <span className="menu-icon">
                                    <i className={`ki-outline ki-people fs-2 me-3`}></i>
                                </span>
                                <span className="menu-title fw-bold fs-3">Community</span>
                            </span>
                        </div>
                    </div>

                </div>
            </div>

            {/* Contact Us Floating Button */}
            <button
                onClick={openModal}
                className="contact-floating-button btn-primary bg-primary" // Reference the CSS class for styling
            >
                Need help?
            </button>

            {/* Bootstrap Modal for Contact Form */}
            <Modal show={modalIsOpen} onHide={closeModal}>
                <form onSubmit={handleSubmitContactForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3">
                            <label htmlFor="nameInput" className="form-label required">Type</label>
                            <select
                                name="type"
                                className="form-select form-select-lg form-select-solid"
                                value={formStateContactForm.type}
                                onChange={handleInputChange}
                                data-control="select2"
                                data-placeholder="Select..."
                                data-allow-clear="true"
                                data-hide-search="true"
                                required
                            >
                                <option value="">Select Type...</option>
                                <option value="doubt">Doubt/Question</option>
                                <option value="suggestion">Suggestion</option>
                                <option value="bug">Bug Report</option>
                                <option value="feature">Feature Request</option>
                                <option value="complaint">Complaint</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="messageInput" className="form-label required">Message</label>
                            <textarea className="form-control form-control-lg form-control-solid" id="message" placeholder="Your message" value={formStateContactForm.message} name="message" onChange={handleInputChange} required></textarea>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" className="btn-light-secondary" onClick={closeModal}>
                            Close
                        </Button>
                        <button type="submit" className="btn btn-primary">
                            Submit
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    );
}

export default Header;
