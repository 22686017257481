import { aiapi } from './axiosConfig';

export const analyze = async (fileId, petitionId, type) => {
    try {
        const response = await aiapi.post('/analyze', { fileId, petitionId, type });
        return response.data;
    } catch (error) {
        // Handle errors that occur during the API call
        console.error('Error fetching petitions:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const createPrintVersion = async (petitionId, type) => {
    try {
        const response = await aiapi.post('/createPrintVersion', { petitionId, type });
        return response.data;
    } catch (error) {
        // Handle errors that occur during the API call
        console.error('Error fetching petitions:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

export const askGenius = async (petitionId, input) => {
    try {
        const response = await aiapi.post('/askgenius', { petitionId, input });
        return response.data;
    } catch (error) {
        // Handle errors that occur during the API call
        console.error('Error fetching petitions:', error.response ? error.response.data : error.message);
        throw new Error(error.response ? error.response.data.error : 'Network response was not ok');
    }
};

