import axios from 'axios';
import { signInWithPopup } from 'firebase/auth';
import { auth, provider } from '../config/firebase-config';

const signInWithGoogle = async (logout) => {
    try {
        const result = await signInWithPopup(auth, provider);
        const user = result.user;
        const oauthRefreshToken = result._tokenResponse.refreshToken;

        const userData = {
            Name: user.displayName || 'No Name Provided',
            Email: user.email,
            Photo: user.photoURL || 'No Photo Provided',
            ProviderId: user.providerData[0].providerId,
            uid: user.uid
        };

        // Send data to your backend
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/createUser`, {
            uid: user.uid,
            userData
        });

        if (response.data.success) {
            userData.BaseFolderId = response.data.BaseFolderId;
            return { success: true, user: userData };
        } else {
            throw new Error('Failed to create or update user in the backend.');
        }
    } catch (error) {
        console.error("Error during Google sign in:", error);
        await logout();
        throw error;  // Rethrow to handle it in the calling context
    }
};

export { signInWithGoogle };
