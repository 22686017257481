import React, { useEffect, useState } from 'react';
import { formatDate } from '../../utils/Utils.js'
import { fetchLog } from '../../services/ApiService.js';

function PetitionLog({ petitionId }) {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        async function fetchData() {
            // console.log('fetchData');
            await fetchLogCall(petitionId, setLogs);
        }

        fetchData();

    }, [petitionId]);

    async function fetchLogCall(petitionId, setLogs) {
        const logList = await fetchLog(petitionId)

        setLogs(logList);
    }

    const getLogBadgeClass = (action) => {
        if (action.includes("Petition")) {
            return (<span>Petition</span>);
        }

        if (action.includes("Exhibit")) {
            return (<span>Exhibit</span>);
        }

        if (action.includes("File")) {
            return (<span>File</span>);
        }

        if (action.includes("Form")) {
            return (<span>Form</span>);
        }

        if (action.includes("CoverLetter")) {
            return (<span>Cover Letter</span>);
        }

        if (action.includes("Plan")) {
            return (<span>Plan</span>);
        }

        return (<span>{action}</span>);
    };

    return (
        <>
            <div className="flex-lg-row-fluid">
                {/*  {/* {/* <!--begin::Card Widget 22--> */}
                <div className="card card-flush border-0">
                    {/*  {/* {/* <!--begin::Header--> */}
                    <div className="card-header pt-7">
                        {/*  {/* {/* <!--begin::Title--> */}
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fs-3 fw-bold text-primary">Recent Activities</span>
                            <span className="text-gray-500 mt-1 fw-semibold fs-6">The most recent actions and modifications on this petition.</span>
                        </h3>
                        {/*  {/* {/* <!--end::Title--> */}
                    </div>
                    {/*  {/* {/* <!--end::Header--> */}
                    {/*  {/* {/* <!--begin::Body--> */}
                    <div className="card-body pt-6">
                        {/*  {/* {/* <!--begin::Table container--> */}
                        {(logs.length > 0 ?
                            <div className="table-responsive">
                                {/*  {/* {/* <!--begin::Table--> */}
                                <table className="table table-row-dashed align-middle gs-0 gy-6 my-0">
                                    {/*  {/* {/* <!--begin::Table head--> */}
                                    <thead>
                                        <tr className="fs-7 fw-bold text-gray-500">
                                            <th className="p-0 pb-3 min-w-150px text-start">ACTION</th>
                                            <th className="p-0 pb-3 w-200px text-start">TYPE</th>
                                            <th className="p-0 pb-3 min-w-100px text-start">CREATED AT</th>
                                        </tr>
                                    </thead>
                                    {/*  {/* {/* <!--end::Table head--> */}
                                    {/*  {/* {/* <!--begin::Table body--> */}
                                    <tbody className="fw-semibold text-gray-600" style={{ whiteSpace: 'nowrap' }}>
                                        {logs.map(log => (
                                            <tr key={log.id}>
                                                <td className="ps-0">
                                                    {log.action.includes("delete") ? (
                                                        <span className={`badge py-3 px-4 fs-7 badge-light-danger`}>DELETE</span>
                                                    ) : (
                                                        <span className={`badge py-3 px-4 fs-7 badge-light-info`}>ADD</span>
                                                    )}
                                                </td>
                                                <td className="ps-0">
                                                    <span className={`badge py-3 px-4 fs-7 badge-light-primary`}>{getLogBadgeClass(log.action)}</span>
                                                </td>
                                                <td className="ps-0">
                                                    <span>{formatDate(log.timestamp)}</span>
                                                    {/* <span>{form.createdAt}</span> */}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    {/*  {/* {/* <!--end::Table body--> */}
                                </table>
                            </div>
                            :
                            <div style={{ display: 'grid', placeItems: 'center', height: '60' }}>
                                <img height="300px" src="/assets/media/illustrations/21.png" alt="No recent activities"></img>
                            </div>
                        )}
                        {/*  {/* {/* <!--end::Table--> */}
                        {/*  {/* {/* <!--begin::Separator--> */}
                        {/* <div className="separator separator-dashed border-gray-200 mb-n4"></div> */}
                        {/*  {/* {/* <!--end::Separator--> */}
                    </div>
                    {/*  {/* {/* <!--end: Card Body--> */}
                </div>
                {/*  {/* {/* <!--end::Card Widget 22--> */}
            </div>
        </>
    );
}

export default PetitionLog;
