import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';
import GoogleDriveFrame from '../../components/GoogleDocs/GoogleDriveFrame.js';
import Modal from "react-bootstrap/Modal";
import { fetchPlan, createPlan, addFilePlan } from '../../services/ApiService.js';
import Loading from '../../components/Loading/Loading.js';

function PetitionPlan({ petitionId, petitionFolderId, updatePetition, type, isReadOnly }) {
    const { logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const [plan, setPlan] = useState(null);
    const [modalPermissionIsOpen, setModalPermissionIsOpen] = React.useState(false);
    const [isAgreed, setIsAgreed] = useState(false); // State to track checkbox
    const [filesToUpload, setFilesToUpload] = useState([]);
    const navigate = useNavigate();

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                if (file.type !== 'application/pdf') {
                    Swal.fire("Error", "Only PDF files are accepted.", "error");
                    return;
                }
                const preview = URL.createObjectURL(file);
                uploadFile(Object.assign(file, { preview }));
            });
        }
    });

    function openModalPermission() {
        setModalPermissionIsOpen(true);
    }

    function closeModalPermission() {
        setModalPermissionIsOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            await fetchPlanCall(petitionId, setPlan);
            // await fetchDocumentText(plan?.googleDocId)
        }

        fetchData();

    }, [petitionId]);

    async function fetchPlanCall(petitionId, setPlan) {
        fetchPlan(petitionId, type)
            .then((result) => {
                // console.log(result);
                setPlan(result);
            })
            .catch((error) => {
                console.error('Error fetching plan:', error);
                return null;
            });
    }

    const createPlanCall = async () => {
        console.log('createPlanCall');
        setLoading(true);

        try {
            await createPlan(petitionId, petitionFolderId, type)
                .then((result) => {
                    setLoading(false);
                    setPlan(result);

                    // var updatedPetition = { hasPlan: 1 };

                    // if (type == "RFEResponse") {
                    //     updatedPetition = { hasPlanRFE: 1 };
                    // }

                    // updatePetition(updatedPetition);
                })
                .catch((error) => {
                    setLoading(false);

                    console.error(error);
                    if (error.message === 'Unauthorized') {
                        logout();
                    } else {
                        Swal.fire("Error", 'An error occurred while creating plan. Please try again.', "error");
                    }

                });

        } catch (error) {
            setLoading(false);

            console.error(error);
            if (error.message === 'Unauthorized') {
                logout();
            } else {
                Swal.fire("Error", 'An error occurred while creating plan. Please try again.', "error");
            }
        }
    };

    const uploadFile = async (file) => {
        setLoading(true);
        try {
            const result = await addFilePlan(file, petitionId, petitionFolderId, type);

            if (result.status === 401) {
                navigate('/login');
            }

            const fileId = result.googleDriveFileId;

            if (result.status === 401) {
                navigate('/login');
            }

            await fetchPlanCall(petitionId, setPlan);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            // console.log(error);
            if (error.message === 'Unauthorized') {
                logout();
            }
            if (error.message === 'Too Large') {
                Swal.fire("Info", "Your file exceeds the maximum upload size limit. Please ensure that your file is smaller than 50MB and try again.", "info");
            } else {
                Swal.fire("Error", "An error occurred while creating the file. Please try again.", "error");
            }
        }
    };

    return (
        <>
            <Loading isLoading={loading} />
            <Modal show={modalPermissionIsOpen} onHide={closeModalPermission} className="modal-lg">
                <Modal.Header closeButton>
                    <Modal.Title>Terms of Use and Data Manipulation Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y">
                        <div className="d-flex flex-column">
                            <div className="w-100">
                                <div className="mb-10 mb-lg-15">
                                    <div className="fs-6">
                                        <p>At Visa Genius, your privacy and trust are paramount. We are committed to safeguarding your personal information and ensuring that your data is used responsibly. This policy outlines how we handle your data and the measures we take to protect your privacy.</p>
                                        <h5>Data Usage</h5>
                                        <p>By using Visa Genius, you agree to allow us to use the data you provide to enhance your experience with our services. Specifically:</p>
                                        <ul>
                                            <li><strong>Improving User Experience:</strong> We use the data you provide to tailor and improve the features and functionality of our platform. This helps us understand how you use our services and make necessary enhancements for a better user experience.</li>
                                            <li><strong>Personalization:</strong> Your data enables us to personalize your interactions with Visa Genius, making our platform more intuitive and user-friendly.</li>
                                        </ul>
                                        <h5>Data Protection</h5>
                                        <p>We understand the importance of protecting your data and ensuring your privacy. Therefore, we adhere to the following principles:</p>
                                        <ul>
                                            <li><strong>Confidentiality:</strong> Your data will never be shared with any third-party companies. We do not sell, trade, or otherwise transfer your information to external parties.</li>
                                            <li><strong>Employee Access:</strong> No Visa Genius employee will have access to your personal data. Our systems are designed to ensure that your information is kept private and secure.</li>
                                            <li><strong>Security Measures:</strong> We implement robust security measures to protect your data from unauthorized access, alteration, disclosure, or destruction. This includes using encryption and secure servers to store your information.</li>
                                        </ul>
                                        <h5>Your Rights</h5>
                                        <p>You have the right to access, modify, and delete your data at any time. Should you have any questions or concerns about how your data is being used, please contact our support team, and we will be happy to assist you.</p>
                                        <h5>Conclusion</h5>
                                        <p>We value your trust and are dedicated to maintaining the highest standards of privacy and data protection. By agreeing to this policy, you can be confident that your data is used solely to enhance your experience with Visa Genius, and your privacy is always our priority.</p>
                                        <p>Thank you for choosing Visa Genius. We are committed to providing you with a secure and personalized experience.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <span className="btn btn-light-secondary" onClick={closeModalPermission}>
                        Close
                    </span>
                </Modal.Footer>
            </Modal >
            {plan && Object.keys(plan).length > 0 ? (
                <>
                    <div className='row'>
                        <div className='col-12 col-md-12 col-lg-12'>
                            {/* <!--begin::Google Docs Editor--> */}
                            <GoogleDriveFrame documentId={plan?.googleDocId} title={plan.fileName} isDocs={plan.isDocs == 0 ? 0 : 1} isFirestore={plan.isFirestore == 0 ? 0 : 1} storageUrl={plan.storageUrl} mimeType={plan.mimeType} divTitle="Plan" divSubtitle="Work on your business plan, professional plan or any other plan inside Visa Genius and make the most of it!" />
                            {/* <!--end::Google Docs Editor--> */}
                        </div>
                    </div>
                </>
            ) : (
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12">
                        {/* <!--begin::Card--> */}
                        <div className="card">
                            {/* <!--begin::Card body--> */}
                            <div className="card-body">

                                {!isReadOnly ? (
                                    <div className="card-px text-center pt-15 pb-15">
                                        {/* <!--begin::Title--> */}
                                        <h2 className="fs-2x fw-bold mb-0 text-primary">Create Plan</h2>
                                        {/* <!--end::Title--> */}
                                        {/* <!--begin::Description--> */}
                                        <p className="text-gray-500 fs-4 fw-semibold py-7">Click on the button below to start
                                            <br />writing your business plan, professional plan or any other plan that fits best your petition!</p>
                                        {/* <!--end::Description--> */}
                                        {/* <!--begin::Agreed--> */}
                                        <div className="mb-3">
                                            <input
                                                className="form-check-input me-3"
                                                type="checkbox"
                                                id="agreeCheck"
                                                checked={isAgreed}
                                                onChange={(e) => setIsAgreed(e.target.checked)}
                                            />
                                            <label className="form-check-label" htmlFor="agreeCheck">
                                                I agree to the <a href="#" onClick={openModalPermission}>terms of use</a> and the data manipulation policy.
                                            </label>
                                        </div>
                                        {/* <!--end::Agreed--> */}
                                        {/* <!--begin::Action--> */}
                                        {isAgreed ? (
                                            <span onClick={() => createPlanCall()} className="btn btn-primary er fs-6 px-8 py-4"><img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "30px" }} />Create Plan</span>
                                        ) : (
                                            <span className="btn btn-light-primary bg-hover-light-primary er fs-6 px-8 py-4" style={{ cursor: "default" }}><img className=" fs-3x me-5" src="/assets/images/GoogleDriveLogo.png" alt="Google Docs File" style={{ width: "30px" }} />Create Plan</span>
                                        )}

                                        <div className='mt-5'>or</div>

                                        <div className='row'>
                                            <div className='col col-3'></div>
                                            <div className='col col-sm-12 col-md-6 col-lg-6'>
                                                <div {...getRootProps()} className="dropzone mt-5">
                                                    <input {...getInputProps()} name="files" />
                                                    {isDragActive ?
                                                        <p>Drop the file here ...</p> :
                                                        <p>Use this area to upload your Plan. <br />Simply drag and drop the PDF file, or click to select the file from your device.</p>
                                                    }
                                                </div>
                                            </div>
                                            <div className='col col-3'></div>
                                        </div>
                                        {/* <!--end::Action--> */}
                                    </div>
                                ) : (
                                    <div className="card-px text-center pt-15 pb-15">
                                        {/* {/* <!--begin::Title--> */}
                                        <h2 className="fs-2x fw-bold mb-0 text-primary">The plan hasn't been created yet.</h2>
                                        {/* {/* <!--end::Title--> */}
                                        {/* {/* <!--begin::Description--> */}
                                        <p className="text-gray-500 fs-4 fw-semibold py-7">Please ask the petition owner to create it.</p>
                                        {/* {/* <!--end::Description--> */}
                                    </div>
                                )}

                                {/* <!--begin::Illustration--> */}
                                <div className="text-center pb-15 px-5">
                                    <img src="/assets/media/illustrations/4.png" alt="" className="mw-100 h-200px h-sm-325px" />
                                </div>
                                {/* <!--end::Illustration--> */}
                            </div>
                            {/* <!--end::Card body--> */}
                        </div>
                        {/* <!--end::Card--> */}
                    </div>
                </div>
            )
            }

        </>
    );
}

export default PetitionPlan;
